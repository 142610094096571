import images from '../assets/images';

export const IndustriesData = {
    industriesHealthcare: [
        {
            type: 'heroBanner',
            title: 'Boost Accuracy, Streamline Healthcare Document Processing Workflows, and Deliver Exceptional Care ',
            body: "Today's healthcare landscape demands efficiency. Docscience empowers your team with Generative AI powered intelligent document processing (IDP) solution, transforming the way you handle paperwork.",
            chips: ['Handwritten Document Reading', 'Streamlined Workflows', 'Enhanced Productivity'],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'cardGroup3xV',
            title: 'Healthcare Document Processing Challenges ',
            list: [
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Patient record overload',
                    body: 'Struggling to manage vast volumes of diverse medical documents and histories ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Claim form complexity ',
                    body: 'Risking delayed reimbursements due to errors in insurance claim documentation ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Regulatory compliance',
                    body: 'Navigating the intricate maze of HIPAA and other healthcare data regulations',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Interoperability issues',
                    body: 'Battling to integrate patient information across various healthcare systems and departments ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Clinical documentation inefficiency',
                    body: 'Losing valuable patient care time to extensive paperwork and data entry ',
                },
            ],
        },
        {
            type: 'featureSection',
            title: 'Turn Obstacles into Opportunities with Powerful Automation ',
            text: [
                'Get Rid of Data Entry Errors and Focus on Patient Care',
                'Seamless Integration for a Smooth Workflow',
                'Prioritize Patient Data Security and Anonymity',
                'No Training, No Annotation Required',
            ],
            list: [
                {
                    icon: images.industries.DataEntry,
                    subTitle: 'Get Rid of Data Entry Errors and Focus on Patient Care ',
                    body: "Docscience's Generative AI (GenAI) technology goes beyond simple data extraction. It understands the context of healthcare documents, minimizing errors and ensuring exceptional accuracy in extracted information. ",
                },
                {
                    icon: images.industries.SeamlessIntegration,
                    subTitle: 'Seamless Integration for a Smooth Workflow',
                    body: 'Seamlessly connect Docscience to your existing EHR, practice management software, and other healthcare IT systems. No disruptions, just a smooth transition to a more efficient workflow. ',
                },
                {
                    icon: images.industries.DataSecurity,
                    subTitle: 'Prioritize Patient Data Security and Anonymity',
                    body: 'Protecting patient data is paramount. Docscience prioritizes data security with industry-leading safeguards and strict adherence to HIPAA regulations. Rest assured, your data is always secure.',
                },
                {
                    icon: images.industries.NoTraining,
                    subTitle: 'No Training, No Annotation Required ',
                    body: 'Get started quickly! Docscience is intuitive and requires no complex training or ongoing data annotation. Our user-friendly platform empowers your team to leverage the power of IDP immediately. ',
                },
            ],
        },
        {
            type: 'textCarousel',
            title: 'Process Healthcare Documents with 100% Accuracy',
            client1: [
                'Patient Records',
                'Medical Invoices',
                'Prescriptions',
                'Insurance Policies',
                'Patient Records',
                'Medical Invoices',
                'Prescriptions',
                'Insurance Policies',
            ],
            client2: [
                'Regulatory Forms',
                'Annual Wellness Visit Forms',
                'Patient Intake Forms',
                'Insurance Claims',
                'Regulatory Forms',
                'Annual Wellness Visit Forms',
                'Patient Intake Forms',
                'Insurance Claims',
            ],
            client3: [
                'Discharge Instructions',
                'Referral Forms',
                'Progress Notes',
                'Discharge Instructions',
                'Referral Forms',
                'Progress Notes',
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Why Choose Docscience as Your Document Processing Partner ',
            list: [
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Built for Healthcare ',
                    body: 'Designed specifically to address the unique needs of healthcare organizations, Docscience understands your workflows and challenges. ',
                },
                {
                    icon: images.industries.ScalableAndAdaptable,
                    title: 'Scalable and Adaptable ',
                    body: 'Docscience grows with you. Our solutions are scalable to accommodate your evolving document processing needs, ensuring a future-proof investment. ',
                },
                {
                    icon: images.industries.CostEffectiveEfficiency,
                    title: 'Cost-Effective Efficiency ',
                    body: 'Docscience delivers a high return on investment (ROI) by reducing administrative costs, minimizing errors, and improving overall efficiency. ',
                },
                {
                    icon: images.industries.EndToEndSupport,
                    title: 'End-to-End Support ',
                    body: "We don't just extract data – we empower your success. Docscience provides comprehensive implementation support, ongoing training, and a dedicated team to answer your questions every step of the way. ",
                },
                {
                    icon: images.industries.ThinkBuindTraditionalIDP,
                    title: 'Think Beyond Traditional IDP ',
                    body: 'See Generative-AI and machine learning in action. Docscience utilizes GenAI to deliver unmatched accuracy and intelligent automation for your healthcare operations. ',
                },
            ],
        },
        {
            type: 'contactForm',
            title: 'Transform your healthcare practice with Docscience. Contact us today!',
        },
    ],

    industriesBanking: [
        {
            type: 'heroBanner',
            title: 'Automate Your Banking Workflows with Intelligent Data Capture ',
            body: 'Docscience empowers your operations team with intelligent document processing (IDP) powered by Generative AI (GenAI), transforming the way you handle financial documents. ',
            chips: ['Streamlined Workflow ', 'Handwritten Document Reading ', 'Enhanced Customer Experience '],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'cardGroup3xV',
            title: 'Banking Document Processing Challenges',
            list: [
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Application Avalanche',
                    body: 'Struggling to manage the constant influx of loan and account opening applications ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'KYC Complexity',
                    body: 'Risking regulatory penalties due to incomplete or inaccurate customer verification processes',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Regulatory Reporting Burden',
                    body: 'Grappling with time-consuming and error-prone regulatory reporting requirements ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Legacy System Integration ',
                    body: 'Battling to connect data across various banking platforms and core systems ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Fraud Detection Difficulties ',
                    body: 'Losing time and resources to manual fraud screening processes ',
                },
            ],
        },
        {
            type: 'featureSection',
            title: 'Docscience: Taming Your Banking Processing Challenges ',
            text: [
                'Eliminate Manual Data Entry Errors',
                'Faster Loan Processing ',
                'Enhance Fraud Detection',
                'Streamline Account Management',
            ],
            list: [
                {
                    icon: images.industries.EliminateDataEntry,
                    subTitle: 'Eliminate Manual Data Entry Errors ',
                    body: 'Docscience handles both digital and paper documents. Upload files, integrate with your banking core system, or receive documents online for automated processing.',
                },
                {
                    icon: images.industries.FasterLoanProcess,
                    subTitle: 'Faster Loan Processing ',
                    body: 'Docscience automates data extraction from loan applications, bank statements, bank checks, and supporting documents, accelerating the loan approval process and improving customer satisfaction. ',
                },
                {
                    icon: images.industries.EnhanceFraudDetection,
                    subTitle: 'Enhance Fraud Detection ',
                    body: 'Docscience’s Generative AI technology can analyze documents for suspicious patterns, helping you identify potential fraud attempts and protect your customers.',
                },
                {
                    icon: images.industries.StreamlineAccount,
                    subTitle: 'Streamline Account Management ',
                    body: 'Automate data extraction from account statements and other documents, streamlining account management tasks and improving customer service. ',
                },
            ],
        },
        {
            type: 'textCarousel',
            title: 'Process all your complex bank documents with Docscience ',
            client1: [
                'Loan Application Form',
                'Credit Report',
                'Income Tax Returns',
                'Bank Statements',
                'Loan Application Form',
                'Credit Report',
                'Income Tax Returns',
                'Bank Statements',
            ],
            client2: [
                'Account Statements',
                'Identification Proof',
                'Address Proof',
                'Income Proof',
                'Account Statements',
                'Identification Proof',
                'Address Proof',
                'Income Proof',
            ],
            client3: [
                'Financial Statements',
                'Checks',
                'Legal Notices',
                'Audit Reports',
                'Financial Statements',
                'Checks',
                'Legal Notices',
                'Audit Reports',
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Why Choose Docscience for Your Banking Operations ',
            list: [
                {
                    icon: images.industries.IncreasedEfficiency,
                    title: 'Increased Efficiency ',
                    body: 'Reduce manual data entry by 80% and free up your team for higher-value activities like loan processing and customer service. ',
                },
                {
                    icon: images.industries.ImprovdAccuracy,
                    title: 'Improved Accuracy ',
                    body: 'Minimize errors in extracted data with 99% accuracy, ensuring faster processing and fewer loan application denials. ',
                },
                {
                    icon: images.industries.FasterLoanProcessing,
                    title: 'Faster Loan Processing ',
                    body: 'Accelerate the loan approval process with faster data extraction and streamlined workflows, leading to happier customers. ',
                },
                {
                    icon: images.industries.EnhancedFraudDetection,
                    title: 'Enhanced Fraud Detection ',
                    body: 'Identify potential fraud attempts, and protect your bank and your customers with Docscience’s Generative AI powered Intelligent Document Processing. ',
                },
                {
                    icon: images.industries.SimplifiedRegulatoryCompliance,
                    title: 'Simplified Regulatory Compliance ',
                    body: 'Docscience ensures your bank adheres to KYC/AML regulations by securely storing and managing verification documents. ',
                },
            ],
        },
        {
            type: 'contactForm',
            title: '',
            subTitle: '',
        },
    ],

    industriesSupplyChain: [
        {
            type: 'heroBanner',
            title: 'Unleash Efficiency, Gain Real-Time Visibility, and Dominate Delivery ',
            body: 'A constant flow of goods, documents, and data - the modern supply chain is complex. And, outdated data capture processes don’t cut it anymore. Docscience empowers your logistics team with next-generation intelligent document processing (IDP) powered by Generative AI (GenAI) and puts your business in the front seat.',
            chips: ['Handwritten Document Reading', 'Automate Workflows', 'Gain Real-Time Insights'],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'cardGroup3xV',
            title: 'Supply Chain Document Processing Challenges ',
            list: [
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Too many documents',
                    body: 'Struggling to manage an endless stream of orders, invoices, and shipping documents ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Data Discrepancies ',
                    body: 'Risking costly errors in inventory management and order fulfillment ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Visibility gaps ',
                    body: 'Losing track of shipments and inventory due to disconnected documentation ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Compliance complexity ',
                    body: 'Navigating a maze of international trade regulations and documentation requirements ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Integration with existing system ',
                    body: 'Battling to connect data across various ERP, WMS, and TMS systems',
                },
            ],
        },
        {
            type: 'featureSection',
            title: 'Stop Drowning in Paperwork. Start Thriving with Intelligent Automation ',
            text: [
                'Eliminate Costly Errors ',
                'Deliver Faster, Every Time ',
                'Optimize Inventory Management ',
                'Streamline Customs Clearance ',
            ],
            list: [
                {
                    icon: images.industries.EliminateCostlyErrors,
                    subTitle: 'Eliminate Costly Errors ',
                    body: 'Docscience’s Generative AI technology minimizes errors in extracted data, ensuring accurate information for all logistics processes. Say goodbye to delays and wasted resources caused by typos and manual mistakes! ',
                },
                {
                    icon: images.industries.DeliverFasterEveryTime,
                    subTitle: 'Deliver Faster, Every Time ',
                    body: 'Docscience automates data extraction, accelerating order processing and improving delivery times. Keep your customers happy and boost your bottom line.',
                },
                {
                    icon: images.industries.OptimizeInventryManagement,
                    subTitle: 'Optimize Inventory Management ',
                    body: 'Gain real-time visibility into your inventory levels with accurate data extraction from shipping documents. Make better inventory control and forecasting decisions to avoid stockouts and overstocking. ',
                },
                {
                    icon: images.industries.streamlineCustomsClearance,
                    subTitle: 'Streamline Customs Clearance ',
                    body: 'Docscience automates data extraction for customs documentation, ensuring faster and more efficient clearance processes to avoid delays at the border and keep your goods moving. ',
                },
            ],
        },
        {
            type: 'textCarousel',
            title: 'Capture information from complex supply-chain documents',
            client1: [
                'Purchase Order',
                'Sales Order',
                'Commercial Invoice',
                'Packing List',
                'Purchase Order',
                'Sales Order',
                'Commercial Invoice',
                'Packing List',
            ],
            client2: [
                'Bill of Lading',
                'Air Waybill',
                'Customs Declaration',
                'Certificate of Origin',
                'Bill of Lading',
                'Air Waybill',
                'Customs Declaration',
                'Certificate of Origin',
            ],
            client3: [
                'Inventory List',
                'Stock Transfer Order',
                'Proof of Delivery',
                'Inspection Certificate',
                'Inventory List',
                'Stock Transfer Order',
                'Proof of Delivery',
                'Inspection Certificate',
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Choose Docscience and Unlock Real-World Benefits for Your Logistics Team ',
            list: [
                {
                    icon: images.industries.UnleshEfficiency,
                    title: 'Unleash Efficiency ',
                    body: 'Reduce manual data entry by 80%, freeing up your team for higher-value activities. Focus on strategic planning, proactive problem-solving, and optimizing your entire supply chain. ',
                },
                {
                    icon: images.industries.EnhanceAccuracy,
                    title: 'Enhanced Accuracy ',
                    body: 'Minimize errors in extracted data with 99% accuracy. Ensure faster processing, fewer delays, and a smoother supply chain operation. ',
                },
                {
                    icon: images.industries.RealTimeVisibility,
                    title: 'Real-Time Visibility ',
                    body: 'Gain real-time insights into your supply chain data, enabling proactive decision-making and improved inventory management. Respond to disruptions quickly and keep your deliveries on track. ',
                },
                {
                    icon: images.industries.FasterOrderProcessing,
                    title: 'Faster Order Processing ',
                    body: 'Accelerate order processing times through automated data extraction, leading to happier customers and increased revenue. ',
                },
                {
                    icon: images.industries.SimplifiedCompliance,
                    title: 'Simplified Compliance ',
                    body: 'Docscience streamlines document management for customs clearance, ensuring compliance with trade regulations and avoiding costly delays. ',
                },
            ],
        },
        {
            type: 'contactForm',
            title: '',
            subTitle: '',
        },
    ],

    industriesInsurance: [
        {
            type: 'heroBanner',
            title: 'Boost Efficiency, Eliminate Errors, and Delight Your Policyholders ',
            body: 'The insurance industry faces a constant challenge: balancing speed with accuracy. Docscience empowers your team  in streamlining insurance document processing with intelligent document processing (IDP) powered by Generative AI (GenAI). ',
            chips: ['Handwritten Document Reading', 'Frictionless  Workflows', 'Empowered Customer Interactions'],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'cardGroup3xV',
            title: 'Insurance Document Processing Challenges ',
            list: [
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Claim processing complexities ',
                    body: 'Struggling to manage the constant influx of claims documents across multiple',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Policy chaos ',
                    body: 'Risking errors in policy issuance and updates due to manual data entry and review ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Underwriting bottleneck',
                    body: 'Losing time and opportunities due to slow, document-heavy risk assessment processes ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Regulatory issues ',
                    body: 'Navigating complex compliance requirements with ever-changing insurance regulations ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Data silos ',
                    body: 'Battling to integrate information across claims, policy management, and customer service systems ',
                },
            ],
        },
        {
            type: 'featureSection',
            title: 'Turn Frustration into Efficiency with Powerful Automation ',
            text: [
                'Eliminate Manual Entry Errors',
                'Faster Underwriting Decisions ',
                'Streamline Claims Processing',
                'Cultivate Customer Loyalty',
            ],
            list: [
                {
                    icon: images.industries.EliminateDataEntry,
                    subTitle: 'Eliminate Manual Entry Errors ',
                    body: 'Docscience handles both digital and paper documents seamlessly. Upload files, integrate with your insurance core system, or receive documents via email for automated processing. ',
                },
                {
                    icon: images.industries.FasterUnderwritingDecision,
                    subTitle: 'Faster Underwriting Decisions ',
                    body: 'Docscience automates data extraction from insurance applications, tax forms, and supporting documents, accelerating the underwriting process and improving turnaround times.',
                },
                {
                    icon: images.industries.StreamlineClaimsProcessing,
                    subTitle: 'Streamline Claims Processing',
                    body: 'Automate data extraction from claims forms and supporting documentation, enabling faster and more efficient claims processing. ',
                },
                {
                    icon: images.industries.CultivateCustomerLoyalty,
                    subTitle: 'Cultivate Customer Loyalty',
                    body: 'Faster processing times and fewer errors lead to a positive customer experience, boosting satisfaction and retention rates.',
                },
            ],
        },
        {
            type: 'textCarousel',
            title: 'Process Complex Insurance Documents with 100% Accuracy ',
            client1: [
                'Application Form',
                'Medical Questionnaire',
                'Driving Record',
                'Property Information',
                'Application Form',
                'Medical Questionnaire',
                'Driving Record',
                'Property Information',
            ],
            client2: [
                'Financial Information',
                'Medical History',
                'Prescription Records',
                'Policy Contract',
                'Financial Information',
                'Medical History',
                'Prescription Records',
                'Policy Contract',
            ],
            client3: [
                'Certificate of Insurance',
                'Claim Notification Form',
                'Proof of Loss Form',
                'Police Reports',
                'Certificate of Insurance',
                'Claim Notification Form',
                'Proof of Loss Form',
                'Police Reports',
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Choose Docscience for Real-World Benefits for Your Insurance Team ',
            list: [
                {
                    icon: images.industries.UnleshEfficiency,
                    title: 'Unleash Efficiency',
                    body: 'Reduce manual data entry by 80% and free up your team for higher-value activities like underwriting and customer service. ',
                },
                {
                    icon: images.industries.EnhanceAccuracy,
                    title: 'Enhanced Accuracy ',
                    body: 'Minimize errors in extracted data with 99% accuracy, ensuring faster processing and fewer claim denials. ',
                },
                {
                    icon: images.industries.AcceleratedUnderwriting,
                    title: 'Accelerated Underwriting ',
                    body: 'Shorten underwriting timelines with faster data extraction and streamlined workflows. ',
                },
                {
                    icon: images.industries.EmpoweredCustomerService,
                    title: 'Empowered Customer Service ',
                    body: 'Reduce processing times and improve claim resolution times, leading to happier and more satisfied policyholders. ',
                },
                {
                    icon: images.industries.SimplifiedAuditsAssurance,
                    title: 'Simplified Audits Assurance ',
                    body: 'Docscience provides a secure audit trail of all documents, ensuring compliance with industry regulations. ',
                },
            ],
        },
        {
            type: 'contactForm',
            title: '',
            subTitle: '',
        },
    ],

    industriesFinanceAndAccounting: [
        {
            type: 'heroBanner',
            title: 'Automate Your Finance & Accounting Workflows with Intelligent Data Capture',
            body: 'The finance and accounting world cannot compromise on accuracy and efficiency. Docscience empowers your team with intelligent document processing (IDP) powered by Generative AI (GenAI), transforming the way you handle financial documents. ',
            chips: ['Streamlined Workflow ', 'Handwritten Document Reading', 'Deeper Financial Insights '],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'cardGroup3xV',
            title: 'Finance & Accounting Document Processing Challenges ',
            list: [
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Invoice overwhelm ',
                    body: 'Struggling to manage a constant influx of invoices and receipts from multiple sources',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Financial statement complexity',
                    body: 'Risking errors in financial reporting due to manual data entry and consolidation',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Tax Document Disarray',
                    body: 'Losing time and risking compliance issues due to scattered and poorly organized tax documentation ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Audit trail gaps ',
                    body: 'Battling to maintain comprehensive and easily accessible audit trails across all financial processes',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Multi-entity mayhem ',
                    body: 'Grappling with financial document management across multiple entities or subsidiaries ',
                },
            ],
        },
        {
            type: 'featureSection',
            title: 'Taming Your Finance & Accounting Challenges ',
            text: [
                'Eliminate Manual Data Entry Errors ',
                'Faster Reconciliation and Improved Cash Flow ',
                'Gain Control Over Your Data ',
                'Simplify Audits and Compliance ',
            ],
            list: [
                {
                    icon: images.documents.DataGraph,
                    subTitle: 'Eliminate Manual Data Entry Errors  ',
                    body: 'Docscience’s smart data extraction technology minimizes errors and ensures accurate and timely data extraction for financial reporting and analysis. Say goodbye to typos and wasted time! ',
                },
                {
                    icon: images.documents.CashFlow,
                    subTitle: 'Faster Reconciliation and Improved Cash Flow ',
                    body: 'Docscience automates invoice processing and streamlines workflows, accelerating reconciliation by up to 70% and optimizing cash flow management. ',
                },
                {
                    icon: images.documents.IncreaseData,
                    subTitle: 'Gain Control Over Your Data ',
                    body: "Docscience empowers you with clean, accurate data that's easily accessible for analysis and reporting. Reduce report generation time by 50% and make informed decisions with confidence. ",
                },
                {
                    icon: images.documents.MultipleDocument,
                    subTitle: 'Simplify Audits and Compliance  ',
                    body: 'Docscience provides a secure audit trail and ensures your financial data adheres to industry regulations. ',
                },
            ],
        },
        {
            type: 'textCarousel',
            title: 'Process complex financial documents with 100% accuracy ',
            client1: [
                'Invoices',
                'Receipts',
                'Bank Statements',
                'Purchase Orders ',
                'Invoices',
                'Receipts',
                'Bank Statements',
                'Purchase Orders ',
            ],
            client2: [
                'Expense Reports',
                'Accounts Payable Documents',
                'Accounts Receivable Documents',
                'Expense Reports',
                'Accounts Payable Documents',
                'Accounts Receivable Documents',
            ],
            client3: [
                'Balance Sheets',
                'Profit and Loss Statements',
                'Tax Forms',
                'Balance Sheets',
                'Profit and Loss Statements',
                'Tax Forms',
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Why Automate Finance & Accounting with Docscience? ',
            list: [
                {
                    icon: images.documents.Setting,
                    title: 'Increased Efficiency ',
                    body: 'Reduce manual data entry by 80% and free up your team for higher-value activities like financial planning and analysis. ',
                },
                {
                    icon: images.documents.GraphLine,
                    title: 'Improved Accuracy ',
                    body: 'Minimize errors in financial data with 99% accurate data extraction, ensuring reliable reporting and compliance. ',
                },
                {
                    icon: images.documents.Refresh,
                    title: 'Faster Close Cycles ',
                    body: 'Accelerate month-end and year-end closing processes by automating data collection and reconciliation. ',
                },
                {
                    icon: images.documents.ImproveVisiblity,
                    title: 'Enhanced Visibility and Control',
                    body: 'Gain real-time insights into your financial data, allowing for better decision-making and improved cash flow management. ',
                },
                {
                    icon: images.documents.DocumentAdd1,
                    title: 'Simplified Audits ',
                    body: 'Ensure your financial data is readily available and compliant with regulations, streamlining the audit process. ',
                },
            ],
        },
        {
            type: 'contactForm',
            title: 'Transform your healthcare practice with Docscience. Contact us today!',
        },
    ],

    industriesECommerce: [
        {
            type: 'heroBanner',
            title: 'Supercharge your E-commerce operations with Docscience ',
            body: 'Overwhelmed by order forms, shipping labels, and customer data? Docscience’s Generative AI-powered platform transforms your e-commerce document chaos into a well-oiled digital machine. ',
            chips: [' Seamless Order Processing ', 'Flawless Data Management ', 'Customer Satisfaction '],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'cardGroup3xV',
            title: ' E-commerce Document Processing Challenges ',
            list: [
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Order overload ',
                    body: 'Struggling to manage a constant influx of orders across multiple platforms  ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Data entry disasters ',
                    body: 'Risking costly errors in product details, customer information, and shipping addresses ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Fulfillment friction ',
                    body: 'Losing customers due to slow order processing and shipping delays',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Data management ',
                    body: 'Fighting to synchronize data across various marketplaces, your website, and backend systems ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Compliance Complexity ',
                    body: 'Balancing data protection laws with the need for swift, global transactions',
                },
            ],
        },
        {
            type: 'featureSection',
            title: 'How Docscience elevates Your E-commerce Operations ',
            text: [
                'Handle massive order volumes effortlessly ',
                'Eliminate data entry errors ',
                'Accelerate order processing ',
                'Integrate smoothly across platforms ',
            ],
            list: [
                {
                    icon: images.industries.EliminateDataEntry,
                    subTitle: 'Handle massive order volumes effortlessly  ',
                    body: 'Process millions of orders daily, scaling seamlessly during peak shopping seasons ',
                },
                {
                    icon: images.industries.EliminateCostlyErrors,
                    subTitle: 'Eliminate data entry errors ',
                    body: 'Achieve 99.9% accuracy in order details, ensuring correct fulfillment and happy customers ',
                },
                {
                    icon: images.industries.EliminateCostlyErrors,
                    subTitle: 'Accelerate order processing ',
                    body: 'Handle orders 8x faster than manual methods, significantly reducing time from click to delivery ',
                },
                {
                    icon: images.industries.EliminateCostlyErrors,
                    subTitle: 'Integrate smoothly across platforms ',
                    body: 'Connect seamlessly with all your e-commerce platforms for a unified data ecosystem',
                },
            ],
        },
        {
            type: 'textCarousel',
            title: 'Process all your complex bank documents with Docscience ',
            client1: [
                'Order Forms',
                'Invoices',
                'Shipping Labels',
                'Return/Exchange Requests ',
                'Order Forms',
                'Invoices',
                'Shipping Labels',
                'Return/Exchange Requests ',
            ],
            client2: [
                'Customer Reviews ',
                'Product Listings',
                'Inventory Reports',
                'Supplier Agreements',
                'Customer Reviews ',
                'Product Listings',
                'Inventory Reports',
                'Supplier Agreements',
            ],
            client3: [
                'Marketplace Agreements',
                'Customer Support Tickets',
                'Sales Reports',
                'Tax Documents',
                'Marketplace Agreements',
                'Customer Support Tickets',
                'Sales Reports',
                'Tax Documents',
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Why Choose Docscience for Your Banking Operations ',
            list: [
                {
                    icon: images.industries.IncreasedEfficiency,
                    title: 'Increased Efficiency ',
                    body: 'Reduce manual data entry by 80% and free up your team for higher-value activities like loan processing and customer service. ',
                },
                {
                    icon: images.industries.IncreasedEfficiency,
                    title: 'Improved Accuracy ',
                    body: 'Minimize errors in extracted data with 99% accuracy, ensuring faster processing and fewer loan application denials. ',
                },
                {
                    icon: images.industries.IncreasedEfficiency,
                    title: 'Faster Loan Processing ',
                    body: 'Accelerate the loan approval process with faster data extraction and streamlined workflows, leading to happier customers. ',
                },
                {
                    icon: images.industries.IncreasedEfficiency,
                    title: 'Enhanced Fraud Detection ',
                    body: 'Identify potential fraud attempts, and protect your bank and your customers with Docscience’s Generative AI powered Intelligent Document Processing. ',
                },
                {
                    icon: images.industries.IncreasedEfficiency,
                    title: 'Simplified Regulatory Compliance ',
                    body: 'Docscience ensures your bank adheres to KYC/AML regulations by securely storing and managing verification documents. ',
                },
            ],
        },
        {
            type: 'contactForm',
            title: '',
            subTitle: '',
        },
    ],

    industriesMortgage: [
        {
            type: 'heroBanner',
            title: 'Unleash Efficiency, Gain Real-Time Visibility, and Dominate Delivery ',
            body: 'A constant flow of goods, documents, and data - the modern supply chain is complex. And, outdated data capture processes don’t cut it anymore. Docscience empowers your logistics team with next-generation intelligent document processing (IDP) powered by Generative AI (GenAI) and puts your business in the front seat.',
            chips: ['Handwritten Document Reading', 'Automate Workflows', 'Gain Real-Time Insights'],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'cardGroup3xV',
            title: 'Supply Chain Document Processing Challenges ',
            list: [
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Too many documents',
                    body: 'Struggling to manage an endless stream of orders, invoices, and shipping documents ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Data Discrepancies ',
                    body: 'Risking costly errors in inventory management and order fulfillment ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Visibility gaps ',
                    body: 'Losing track of shipments and inventory due to disconnected documentation ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Compliance complexity ',
                    body: 'Navigating a maze of international trade regulations and documentation requirements ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Integration with existing system ',
                    body: 'Battling to connect data across various ERP, WMS, and TMS systems',
                },
            ],
        },
        {
            type: 'featureSection',
            title: 'Stop Drowning in Paperwork. Start Thriving with Intelligent Automation ',
            text: [
                'Eliminate Costly Errors ',
                'Deliver Faster, Every Time ',
                'Optimize Inventory Management ',
                'Streamline Customs Clearance ',
            ],
            list: [
                {
                    icon: images.industries.EliminateCostlyErrors,
                    subTitle: 'Eliminate Costly Errors ',
                    body: 'Docscience’s Generative AI technology minimizes errors in extracted data, ensuring accurate information for all logistics processes. Say goodbye to delays and wasted resources caused by typos and manual mistakes! ',
                },
                {
                    icon: images.industries.DeliverFasterEveryTime,
                    subTitle: 'Deliver Faster, Every Time ',
                    body: 'Docscience automates data extraction, accelerating order processing and improving delivery times. Keep your customers happy and boost your bottom line.',
                },
                {
                    icon: images.industries.OptimizeInventryManagement,
                    subTitle: 'Optimize Inventory Management ',
                    body: 'Gain real-time visibility into your inventory levels with accurate data extraction from shipping documents. Make better inventory control and forecasting decisions to avoid stockouts and overstocking. ',
                },
                {
                    icon: images.industries.streamlineCustomsClearance,
                    subTitle: 'Streamline Customs Clearance ',
                    body: 'Docscience automates data extraction for customs documentation, ensuring faster and more efficient clearance processes to avoid delays at the border and keep your goods moving. ',
                },
            ],
        },
        {
            type: 'textCarousel',
            title: 'Capture information from complex supply-chain documents',
            client1: [
                'Purchase Order',
                'Sales Order',
                'Commercial Invoice',
                'Packing List',
                'Purchase Order',
                'Sales Order',
                'Commercial Invoice',
                'Packing List',
            ],
            client2: [
                'Bill of Lading',
                'Air Waybill',
                'Customs Declaration',
                'Certificate of Origin',
                'Bill of Lading',
                'Air Waybill',
                'Customs Declaration',
                'Certificate of Origin',
            ],
            client3: [
                'Inventory List',
                'Stock Transfer Order',
                'Proof of Delivery',
                'Inspection Certificate',
                'Inventory List',
                'Stock Transfer Order',
                'Proof of Delivery',
                'Inspection Certificate',
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Choose Docscience and Unlock Real-World Benefits for Your Logistics Team ',
            list: [
                {
                    icon: images.industries.UnleshEfficiency,
                    title: 'Unleash Efficiency ',
                    body: 'Reduce manual data entry by 80%, freeing up your team for higher-value activities. Focus on strategic planning, proactive problem-solving, and optimizing your entire supply chain. ',
                },
                {
                    icon: images.industries.EnhanceAccuracy,
                    title: 'Enhanced Accuracy ',
                    body: 'Minimize errors in extracted data with 99% accuracy. Ensure faster processing, fewer delays, and a smoother supply chain operation. ',
                },
                {
                    icon: images.industries.RealTimeVisibility,
                    title: 'Real-Time Visibility ',
                    body: 'Gain real-time insights into your supply chain data, enabling proactive decision-making and improved inventory management. Respond to disruptions quickly and keep your deliveries on track. ',
                },
                {
                    icon: images.industries.FasterOrderProcessing,
                    title: 'Faster Order Processing ',
                    body: 'Accelerate order processing times through automated data extraction, leading to happier customers and increased revenue. ',
                },
                {
                    icon: images.industries.SimplifiedCompliance,
                    title: 'Simplified Compliance ',
                    body: 'Docscience streamlines document management for customs clearance, ensuring compliance with trade regulations and avoiding costly delays. ',
                },
            ],
        },
        {
            type: 'contactForm',
            title: '',
            subTitle: '',
        },
    ],

    industriesNBFC: [
        {
            type: 'heroBanner',
            title: 'Boost Efficiency, Eliminate Errors, and Delight Your Policyholders ',
            body: 'The insurance industry faces a constant challenge: balancing speed with accuracy. Docscience empowers your team  in streamlining insurance document processing with intelligent document processing (IDP) powered by Generative AI (GenAI). ',
            chips: ['Handwritten Document Reading', 'Frictionless  Workflows', 'Empowered Customer Interactions'],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'cardGroup3xV',
            title: 'Insurance Document Processing Challenges ',
            list: [
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Claim processing complexities ',
                    body: 'Struggling to manage the constant influx of claims documents across multiple',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Policy chaos ',
                    body: 'Risking errors in policy issuance and updates due to manual data entry and review ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Underwriting bottleneck',
                    body: 'Losing time and opportunities due to slow, document-heavy risk assessment processes ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Regulatory issues ',
                    body: 'Navigating complex compliance requirements with ever-changing insurance regulations ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Data silos ',
                    body: 'Battling to integrate information across claims, policy management, and customer service systems ',
                },
            ],
        },
        {
            type: 'featureSection',
            title: 'Turn Frustration into Efficiency with Powerful Automation ',
            text: [
                'Eliminate Manual Entry Errors',
                'Faster Underwriting Decisions ',
                'Streamline Claims Processing',
                'Cultivate Customer Loyalty',
            ],
            list: [
                {
                    icon: images.industries.EliminateDataEntry,
                    subTitle: 'Eliminate Manual Entry Errors ',
                    body: 'Docscience handles both digital and paper documents seamlessly. Upload files, integrate with your insurance core system, or receive documents via email for automated processing. ',
                },
                {
                    icon: images.industries.FasterUnderwritingDecision,
                    subTitle: 'Faster Underwriting Decisions ',
                    body: 'Docscience automates data extraction from insurance applications, tax forms, and supporting documents, accelerating the underwriting process and improving turnaround times.',
                },
                {
                    icon: images.industries.StreamlineClaimsProcessing,
                    subTitle: 'Streamline Claims Processing',
                    body: 'Automate data extraction from claims forms and supporting documentation, enabling faster and more efficient claims processing. ',
                },
                {
                    icon: images.industries.CultivateCustomerLoyalty,
                    subTitle: 'Cultivate Customer Loyalty',
                    body: 'Faster processing times and fewer errors lead to a positive customer experience, boosting satisfaction and retention rates.',
                },
            ],
        },
        {
            type: 'textCarousel',
            title: 'Process Complex Insurance Documents with 100% Accuracy ',
            client1: [
                'Application Form',
                'Medical Questionnaire',
                'Driving Record',
                'Property Information',
                'Application Form',
                'Medical Questionnaire',
                'Driving Record',
                'Property Information',
            ],
            client2: [
                'Financial Information',
                'Medical History',
                'Prescription Records',
                'Policy Contract',
                'Financial Information',
                'Medical History',
                'Prescription Records',
                'Policy Contract',
            ],
            client3: [
                'Certificate of Insurance',
                'Claim Notification Form',
                'Proof of Loss Form',
                'Police Reports',
                'Certificate of Insurance',
                'Claim Notification Form',
                'Proof of Loss Form',
                'Police Reports',
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Choose Docscience for Real-World Benefits for Your Insurance Team ',
            list: [
                {
                    icon: images.industries.UnleshEfficiency,
                    title: 'Unleash Efficiency',
                    body: 'Reduce manual data entry by 80% and free up your team for higher-value activities like underwriting and customer service. ',
                },
                {
                    icon: images.industries.EnhanceAccuracy,
                    title: 'Enhanced Accuracy ',
                    body: 'Minimize errors in extracted data with 99% accuracy, ensuring faster processing and fewer claim denials. ',
                },
                {
                    icon: images.industries.AcceleratedUnderwriting,
                    title: 'Accelerated Underwriting ',
                    body: 'Shorten underwriting timelines with faster data extraction and streamlined workflows. ',
                },
                {
                    icon: images.industries.EmpoweredCustomerService,
                    title: 'Empowered Customer Service ',
                    body: 'Reduce processing times and improve claim resolution times, leading to happier and more satisfied policyholders. ',
                },
                {
                    icon: images.industries.SimplifiedAuditsAssurance,
                    title: 'Simplified Audits Assurance ',
                    body: 'Docscience provides a secure audit trail of all documents, ensuring compliance with industry regulations. ',
                },
            ],
        },
        {
            type: 'contactForm',
            title: '',
            subTitle: '',
        },
    ],

    industriesRetail: [
        {
            type: 'heroBanner',
            title: 'Tame Your Retail Document Chaos with Docscience ',
            body: 'Drowning in purchase orders, invoices, and inventory reports? Docscience’s Generative AI-powered platform transforms your document headaches into streamlined retail operations. ',
            chips: ['Lightning-Fast Processing ', 'Pin-Point Accuracy ', 'Delighted Customers '],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'cardGroup3xV',
            title: 'Retail Document Processing Challenges',
            list: [
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Document Deluge ',
                    body: 'Struggling to manage thousands of daily transactions and reports',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Error-Prone Manual Entry ',
                    body: 'Risking costly mistakes in pricing, inventory, and customer data ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Processing Bottleneck ',
                    body: 'Watching sales opportunities slip away due to slow document processing ',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'System Silos ',
                    body: 'Fighting to connect data across disconnected POS, inventory, and CRM systems',
                },
                {
                    icon: images.industries.BuiltForHealthcard,
                    title: 'Compliance Quagmire ',
                    body: 'Balancing data security with ever-changing retail regulations',
                },
            ],
        },
        {
            type: 'featureSection',
            title: 'How Docscience Conquers Your Document Challenges ',
            text: [
                'Handle large volumes of documents easily',
                'Eliminate manual data entry errors',
                'Speed up document processing ',
                'Integrate seamlessly with existing systems ',
            ],
            list: [
                {
                    icon: images.documents.DataGraph,
                    subTitle: 'Handle large volumes of documents easily ',
                    body: 'Process millions of documents daily, scaling effortlessly with your busiest seasons',
                },
                {
                    icon: images.documents.CashFlow,
                    subTitle: 'Eliminate manual data entry errors',
                    body: 'Achieve 99% accuracy in data extraction, ensuring precise inventory and financial records ',
                },
                {
                    icon: images.documents.IncreaseData,
                    subTitle: 'Speed up document processing ',
                    body: 'Process documents 10x faster than traditional methods, accelerating every aspect of your operations',
                },
                {
                    icon: images.documents.MultipleDocument,
                    subTitle: 'Integrate seamlessly with existing systems  ',
                    body: 'Connect effortlessly with your retail systems, creating a unified data ecosystem ',
                },
            ],
        },
        {
            type: 'textCarousel',
            title: 'Process All Your Retail Documents with Docscience ',
            client1: [
                'Purchase Orders',
                'Invoices',
                'Receipts',
                'Inventory Reports',
                'Purchase Orders',
                'Invoices',
                'Receipts',
                'Inventory Reports',
            ],
            client2: [
                'Sales Reports',
                'Customer Orders',
                'Shipping Manifests',
                'Return/Exchange Forms',
                'Sales Reports',
                'Customer Orders',
                'Shipping Manifests',
                'Return/Exchange Forms',
            ],
            client3: [
                'Employee Timesheets',
                'Vendor Contracts',
                'Price Lists',
                'Product Catalogs',
                'Employee Timesheets',
                'Vendor Contracts',
                'Price Lists',
                'Product Catalogs',
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'The Docscience Advantage: Retail Edition ',
            list: [
                {
                    icon: images.documents.Setting,
                    title: 'Improved Efficiency ',
                    body: 'Cut manual data entry by 80%, freeing your team to focus on sales and customer experience. Process 1000 documents in the time it took to handle 100 before.',
                },
                {
                    icon: images.documents.Setting,
                    title: 'Pinpoint Accuracy ',
                    body: 'Achieve 99.9% data extraction accuracy, eliminating costly inventory discrepancies and pricing errors. Reduce order errors by 75%',
                },
                {
                    icon: images.documents.Setting,
                    title: 'Improved order processing ',
                    body: 'Cut order processing time by 70%, delighting customers with lightning-fast fulfillment. Increase same-day shipping capacity by 50%',
                },
                {
                    icon: images.documents.Setting,
                    title: 'Real-Time Inventory Management ',
                    body: 'Gain instant visibility into stock levels across all channels. Reduce stock outs by 40% and overstock situations by 35%',
                },
                {
                    icon: images.documents.Setting,
                    title: 'Compliance Made Easy ',
                    body: 'Automatically organize and secure 100% of sensitive documents, cutting audit preparation time by 60%',
                },
            ],
        },
        {
            type: 'contactForm',
            title: 'Transform your healthcare practice with Docscience. Contact us today!',
        },
    ],
};
