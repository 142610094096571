import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import LightLogo from '../../assets/docLogo.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from '../common/Container';
import images from '../../assets/images';

const Footer = () => {
    const [expandedSection, setExpandedSection] = useState(null);

    const footerContent = [
        {
            title: 'Industries',
            links: [
                { label: 'Healthcare', url: 'https://www.docscience.ai/industries?sbm=industriesHealthcare' },
                { label: 'Banking', url: 'https://www.docscience.ai/industries?sbm=industriesBanking' },
                { label: 'Supply Chain', url: 'https://www.docscience.ai/industries?sbm=industriesSupplyChain' },
                { label: 'Insurance', url: 'https://www.docscience.ai/industries?sbm=industriesInsurance' },
            ],
        },
        {
            title: 'Documents',
            links: [
                { label: 'Claim Form', url: 'https://www.docscience.ai/documents?sbm=documentsClaimForms' },
                { label: 'Insurance Application', url: 'https://www.docscience.ai/documents?sbm=documentsInsuranceApplication' },
                { label: 'Expense Reports', url: 'https://www.docscience.ai/documents?sbm=documentsExpenseReports' },
                { label: 'Policy Documents', url: 'https://www.docscience.ai/documents?sbm=documentsPolicyDocuments' },
                { label: 'Purchase Order', url: 'https://www.docscience.ai/documents?sbm=documentsPurchaseOrder' },
                { label: 'Invoice Processing', url: 'https://www.docscience.ai/documents?sbm=documentsInvoiceProcessing' },
            ],
        },
        {
            title: 'Resources',
            links: [
                { label: 'Knowledge Base', url: '', comingSoon: true },
                { label: 'Case study', url: '', comingSoon: true },
                { label: 'Blogs', url: '', comingSoon: true },
            ],
        },
        {
            title: 'Company',
            links: [
                { label: 'Product', url: 'https://www.docscience.ai/product' },
                { label: 'Pricing', url: 'https://www.docscience.ai/pricing' },
                { label: 'Contact Us', url: 'https://www.docscience.ai/contactUs' },
            ],
        },
        {
            title: 'Follow us on',
            links: [
                {
                    icon: images.Linkedin,
                    url: 'https://www.linkedin.com/company/nexyom-consultancy-services-private-limited',
                },
                { icon: images.X, url: '' },
                {
                    icon: images.Instagram,
                    url: 'https://www.instagram.com/nex.yom?igsh=MWNxNGE1aWZxNmlkaQ%3D%3D&utm_source=qr',
                },
                { icon: images.Fb, url: '' },
            ],
        },
    ];

    const handleServiceClick = index => {
        setExpandedSection(expandedSection === index ? null : index);
    };

    return (
        <div className="footerContainer">
            <Container className="container">
                <div className="footer footerContents">
                    {footerContent?.map((_, i) => (
                        <div key={i} className="footer-section">
                            <h5>{_.title}</h5>
                            <ul>
                                {_.links?.map((__, i) => (
                                    <li key={i}>
                                        {__.external ? (
                                            <a href={__.url}>{__.label}</a>
                                        ) : (
                                            <Link style={{ width: '250px' }} to={__.url}>
                                                {__.label}
                                            </Link>
                                        )}
                                        {__.comingSoon && <span className="footerButton">Coming soon</span>}
                                    </li>
                                ))}
                                {_.title === 'Follow us on' && (
                                    <div className="desktop-footer-icons">
                                        {_.links.map(
                                            (__, iconIndex) =>
                                                __.icon && (
                                                    <a
                                                        href={__.url}
                                                        key={iconIndex}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        <img src={__.icon} alt="" />
                                                    </a>
                                                )
                                        )}
                                    </div>
                                )}
                            </ul>
                        </div>
                    ))}
                </div>
            </Container>
            <div className="mobileFooter">
                {footerContent?.map((_, index) => (
                    <div key={index} className="footer-section">
                        <h5 onClick={() => handleServiceClick(index)}>
                            {_.title}
                            <ExpandMoreIcon width={16} height={16} />
                        </h5>
                        <ul style={{ display: expandedSection === index ? 'block' : 'none' }}>
                            {_.links?.map((__, linkIndex) => (
                                <li key={linkIndex}>
                                    {__.external ? (
                                        <a href={__.url}>{__.label}</a>
                                    ) : (
                                        <Link to={__.url}>{__.label}</Link>
                                    )}
                                    {__.comingSoon && <span className="footerButton">Coming soon</span>}
                                </li>
                            ))}
                            {_.title === 'Follow us on' && (
                                <div className="desktop-footer-icons">
                                    {_.links.map(
                                        (__, iconIndex) =>
                                            __.icon && (
                                                <a
                                                    href={__.url}
                                                    key={iconIndex}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img src={__.icon} alt="" />
                                                </a>
                                            )
                                    )}
                                </div>
                            )}
                        </ul>
                    </div>
                ))}
            </div>
            <div className="footer-logo">
                <img src={LightLogo} alt="Nexyom Logo"></img>
                <div className="footer-copyright ">&copy; 2024 All rights reserved. Nexyom Consultancy Pvt. Ltd.</div>
                <div className="footer-privacycopyright">
                    <a href="/Privacy-policy" className="footer-link">Privacy Policies</a>
                    <span> </span>
                    <a href="/Terms-conditions" className="footer-link">Terms and Conditions</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
