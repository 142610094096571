import React from 'react';
import './style.scss';
import Container from '../common/Container';

const TextCarousel = ({ title, client1, client2, client3 }) => {
    return (
        <div className="text-carousel-section">
            <Container className="text-carousel-container">
                {title && <h3 className="carouselTitle">{title}</h3>}
            </Container>
            <div className="text-carousel">
                <div className="carousel-group">
                    {client1.map((_, i) => (
                        <div className="text-group" key={i}>
                            <div className="text">{_}</div>
                        </div>
                    ))}
                </div>
                <div className="carousel-group">
                    {client1.map((_, i) => (
                        <div className="text-group" key={i}>
                            <div className="text">{_}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="text-carousel">
                <div className="carousel-group2">
                    {client2.map((_, i) => (
                        <div className="text-group" key={i}>
                            <div className="text text-center">{_}</div>
                        </div>
                    ))}
                </div>
                <div className="carousel-group2">
                    {client2.map((_, i) => (
                        <div className="text-group" key={i}>
                            <div className="text text-center">{_}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="text-carousel">
                <div className="carousel-group">
                    {client3.map((_, i) => (
                        <div className="text-group" key={i}>
                            <div className="text">{_}</div>
                        </div>
                    ))}
                </div>
                <div className="carousel-group">
                    {client3.map((_, i) => (
                        <div className="text-group" key={i}>
                            <div className="text">{_}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TextCarousel;
