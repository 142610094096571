// src/assets/images.js

//homePage
import ExportImg from './homePage/Features_Export.png';
import UploadImg from './homePage/Features_upload_doc.png';
import ExtractImg from './homePage/Features_data_extraction.png';
import Insurance from './homePage/insurance.svg';
import Finance from './homePage/finance.svg';
import Healthcare from './homePage/healthcare.svg';
import Retail from './homePage/retail.svg';
import SearchDoc from './homePage/searchDocument.svg';
import DataFolder from './homePage/dataFolder.svg';
import AutomateDocument from './homePage/automateDocumentProcess.svg';
import SimplifiedDoc from './homePage/simplifiedDocument.svg';
import CostEffectiveData from './homePage/costEffectiveData.svg';
import Qb from './homePage/qb.svg';
import Gmail from './homePage/gmail.svg';
import Zapier from './homePage/zapier.svg';
import Xero from './homePage/bill.svg';
import Drive from './homePage/drive.svg';
import DataX from './homePage/data-x.svg';
import Cloud from './homePage/cloud.svg';
import Bill from './homePage/xero.svg';
import GDPR from './homePage/gdpr.svg';
import SOC2TYPE2 from './homePage/soc2-type2.svg';
import HIPAA from './homePage/hipaa.svg';
import Card1 from './homePage/card1.svg';
import Card2 from './homePage/card2.svg';
import Card3 from './homePage/card3.svg';
import Card4 from './homePage/card4.svg';


//import 

// Industries
import DataEntry from './industries/dataEntry.svg';
import SeamlessIntegration from './industries/seamlessIntegration.svg';
import DataSecurity from './industries/datasecurity.svg';
import NoTraining from './industries/noTraining.svg';
import BuiltForHealthcard from './industries/builtForHealthcare.svg';
import CostEffectiveEfficiency from './industries/cost-effectiveEfficiency.svg';
import EndToEndSupport from './industries/end-to-endSupport.svg';
import ScalableAndAdaptable from './industries/scalableAndAdaptable.svg';
import ThinkBuindTraditionalIDP from './industries/think-buind-traditional-IDP.svg';
import AcceleratedUnderwriting from './industries/accelerated-underwriting.svg';
import CultivateCustomerLoyalty from './industries/cultivate-customer-loyalty.svg';
import DeliverFasterEveryTime from './industries/deliver-faster-everyTime.svg';
import EliminateCostlyErrors from './industries/eliminate-costly-errors.svg';
import EliminateDataEntry from './industries/eliminate-dataEntry.svg';
import EmpoweredCustomerService from './industries/empowered-customer-service.svg';
import EnhanceAccuracy from './industries/enhance-accuracy.svg';
import EnhanceFraudDetection from './industries/enhance-fraud-detection.svg';
import EnhancedFraudDetection from './industries/enhanced-fraud-detection.svg';
import FasterLoanProcess from './industries/faster-loan-process.svg';
import FasterLoanProcessing from './industries/faster-loan-processing.svg';
import FasterUnderwritingDecision from './industries/faster-underwriting-decisions.svg';
import ImprovdAccuracy from './industries/improved-accuracy.svg';
import IncreasedEfficiency from './industries/increased-efficiency.svg';
import OptimizeInventryManagement from './industries/optimize-inventory-management.svg';
import RealTimeVisibility from './industries/real-time-visibility.svg';
import SimplifiedAuditsAssurance from './industries/simplified-audits-assurance.svg';
import SimplifiedCompliance from './industries/simplified-compliance.svg';
import SimplifiedRegulatoryCompliance from './industries/simplified-regulatory-compliance.svg';
import StreamlineAccount from './industries/streamline-account.svg';
import StreamlineClaimsProcessing from './industries/streamline-claims-processing.svg';
import streamlineCustomsClearance from './industries/streamline-customs-clearance.svg';
import UnleshEfficiency from './industries/unleash-efficiency.svg';
import FasterOrderProcessing from './industries/faster-order-processing.svg';

//documents
import ApproveDocument from './documents/approveDocument.svg';
import CashFlow from './documents/cashFlow.svg';
import DataGraph from './documents/data_graph.svg';
import DocumentAdd from './documents/documents-add.svg';
import Document from './documents/documents.svg';
import Energy from './documents/energy.svg';
import ErrorDocument from './documents/errorsDocument.svg';
import IncreaseData from './documents/increaseData.svg';
import MultipleDocument from './documents/multipleDocument.svg';
import PaymentCheck from './documents/paymentCheck.svg';
import PolicyDocument from './documents/policyDocument.svg';
import SearchDocument from './documents/searchDocument.svg';
import Security from './documents/security.svg';
import Setting from './documents/setting.svg';
import GraphLine from './documents/graphLine.svg';
import ImproveVisiblity from './documents/improveVisibility.svg';
import ArrowDocument from './documents/arrowDocument.svg';
import DocumentError from './documents/documentError.svg';
import ImproveData from './documents/improveData.svg';
import RefreshCoin from './documents/refreshCoin.svg';
import Security1 from './documents/security1.svg';
import Document1 from './documents/document1.svg';
import SearchDocument1 from './documents/searchDocument1.svg';
import CheckDocument1 from './documents/checkDocument1.svg';
import Refresh from './documents/refresh.svg';
import DocumentAdd1 from './documents/documentAdd1.svg';
import SecuritySearch from './documents/securitySearch.svg';

//Product
import Health from './products/inventHealth.svg';
import Bnexgen from './products/b_nexgen.svg';
import Briwo from './products/briwo.svg';
import CloudClix from './products/cloud_clix.svg';
import Fifthgen from './products/fifthgen.svg';
import Adroetech from './products/Adroetech.svg';
import NexgenIot from './products/nexgen_iotsolutions.svg';
import NexgenTech from './products/nexgen_technologies.svg';
import SearchDocuments from './products/document_search.svg';
import ViewDocument from './products/document_view.svg';
import AllDocument from './products/all_documents.svg';
import ShareDocument from './products/share_document.svg';
import Energy1 from './products/energy.svg';
import EditCircle from './products/edit-circle.svg';
import GenAIAccuracy from './products/gen-ai-accuracy.svg';
import IDPFramework from './products/idp-framework.svg';
import LanguageReading from './products/language-reading.svg';
import ReductionProcessing from './products/reduction-processing.svg';

//footer
import Linkedin from './linkedin.svg';
import Fb from './fb.svg';
import Instagram from './instagram.svg';
import X from './x.svg';

//navbar
import Banking from './navbar-icons/banking.svg';
import Ecommerce from './navbar-icons/Ecommerce.svg';
import FinanceAcc from './navbar-icons/finance.svg';
import Healthcaree from './navbar-icons/Healthcare.svg';
import Insurancee from './navbar-icons/Insurance.svg';
import Mortagee from './navbar-icons/Mortage.svg';
import Nbfcc from './navbar-icons/NBFC.svg';
import Retaill from './navbar-icons/Retail.svg';
import Supplychain from './navbar-icons/Supplychain.svg';
import Invoice from './navbar-icons/Invoice.svg';
import Claim from './navbar-icons/Claimforms.svg';
import Expense from './navbar-icons/Expense.svg';
import Insuranceapp from './navbar-icons/Insuranceapp.svg';
import Policy from './navbar-icons/Policydocu.svg';
import Purchase from './navbar-icons/Purchase.svg';
import Account from './navbar-icons/Account.svg';
import Accountsrec from './navbar-icons/Accountsrecon.svg';
import Billland from './navbar-icons/Billlanding.svg';
import Claimfraud from './navbar-icons/Claimfraud.svg';
import Cpg from './navbar-icons/Cpgloyalty.svg';
import Order from './navbar-icons/Order.svg';
import Property from './navbar-icons/Property.svg';

//policy
import Policyimg from './policy/policyicon.svg';
import Termsimg from './policy/termsicon.svg';

const images = {
    navbar: {
        Banking,
        Ecommerce,
        FinanceAcc,
        Healthcaree,
        Insurancee,
        Mortagee,
        Nbfcc,
        Retaill,
        Supplychain,
        Claim,
        Expense,
        Insuranceapp,
        Policy,
        Purchase,
        Invoice,
        Account,
        Accountsrec,
        Billland,
        Claimfraud,
        Cpg,
        Order,
        Property,
    },

    homePage: {
        ExportImg,
        UploadImg,
        ExtractImg,
        Insurance,
        Finance,
        Healthcare,
        Retail,
        SearchDoc,
        DataFolder,
        AutomateDocument,
        SimplifiedDoc,
        CostEffectiveData,
        Qb,
        Gmail,
        Zapier,
        Xero,
        Drive,
        DataX,
        Cloud,
        Bill,
        GDPR,
        SOC2TYPE2,
        HIPAA,
        Card1,
        Card2,
        Card3,
        Card4
    },
    industries: {
        DataEntry,
        SeamlessIntegration,
        DataSecurity,
        NoTraining,
        BuiltForHealthcard,
        CostEffectiveEfficiency,
        EliminateDataEntry,
        EliminateCostlyErrors,
        AcceleratedUnderwriting,
        EndToEndSupport,
        EmpoweredCustomerService,
        DeliverFasterEveryTime,
        CultivateCustomerLoyalty,
        ScalableAndAdaptable,
        FasterLoanProcess,
        EnhancedFraudDetection,
        EnhanceFraudDetection,
        EnhanceAccuracy,
        ThinkBuindTraditionalIDP,
        IncreasedEfficiency,
        ImprovdAccuracy,
        FasterUnderwritingDecision,
        FasterLoanProcessing,
        UnleshEfficiency,
        SimplifiedCompliance,
        SimplifiedAuditsAssurance,
        RealTimeVisibility,
        OptimizeInventryManagement,
        streamlineCustomsClearance,
        StreamlineClaimsProcessing,
        StreamlineAccount,
        SimplifiedRegulatoryCompliance,
        FasterOrderProcessing,
    },
    documents: {
        ApproveDocument,
        CashFlow,
        DataGraph,
        DocumentAdd,
        Document,
        Energy,
        ErrorDocument,
        IncreaseData,
        MultipleDocument,
        PaymentCheck,
        PolicyDocument,
        SearchDocument,
        Security,
        Setting,
        GraphLine,
        ImproveVisiblity,
        ArrowDocument,
        DocumentError,
        ImproveData,
        RefreshCoin,
        Security1,
        Document1,
        SearchDocument1,
        CheckDocument1,
        Refresh,
        DocumentAdd1,
        SecuritySearch,
    },
    product: {
        Health,
        Bnexgen,
        Briwo,
        CloudClix,
        Fifthgen,
        Adroetech,
        NexgenIot,
        NexgenTech,
        SearchDocuments,
        ViewDocument,
        AllDocument,
        ShareDocument,
        Energy1,
        EditCircle,
        GenAIAccuracy,
        IDPFramework,
        LanguageReading,
        ReductionProcessing,
    },

    policy:{
        Policyimg,
        Termsimg,
    },

    Linkedin,
    X,
    Instagram,
    Fb,
};

export default images;
