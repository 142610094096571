import images from '../assets/images';
export const TermsData = [
    {
        type: 'herosect',
        title: 'Terms & Conditions',
        body: 'These are the terms and conditions for:',
        link1: 'www.nexyom.com',
        link2: 'www.docscience.ai',
        subtitle: 'Effective Date/ Last Updated Sep19th, 2024',
        imgSrc: images.policy.Termsimg,
        paragraphText: `The following terms and conditions apply to your use of the Nexyom Consultancy Services Private Ltd product docscience platform and the services available on the platform. This includes the Web Application and API versions. By using the platform, you agree to these terms and conditions and our privacy policy.`,
        paragraphText1: `In these terms and conditions, the words “platform” refer to the Nexyom Consultancy Services Private Ltd product docscience API and the Nexyom Consultancy Services Private Ltd product”docscience” web application, unless referred to individually in the document, “we”, “us”, “our”, and “Nexyom Consultancy Services Private Ltd”, refers to Nexyom Consultancy Services Private Ltd, and “you”, and “user”, refers to you, the Nexyom Consultancy Services Private Ltd user.`,
    },
    {
        type: 'termsdatasec',
        title: 'PLEASE READ THESE CONDITIONS CAREFULLY BEFORE USING THE SERVICES AND FUNCTIONALITIES AVAILABLE ON THE PLATFORM.',
        orderlist: [
            {
                type: 'section',
                heading: '1. ACCEPTANCE OF TERMS',
                paragraph: `By using the platform, you agree to be bound by this agreement. If you do not agree to the terms of this agreement, you must not use our services and our platform. We may modify this agreement from time to time, and such modification will be effective upon posting on the platform. You agree to be bound by any modifications to these terms and conditions when you use Nexyom Consultancy Services Private Ltd platform following the posting of such modification; therefore, it is important that you review this agreement regularly.`,
                secondparagraph: `By using the platform, you agree to be bound by this agreement. If you do not agree to the terms of this agreement, you must not use our services and our platform. We may modify this agreement from time to time, and such modification will be effective upon posting on the platform. You agree to be bound by any modifications to these terms and conditions when you use Nexyom Consultancy Services Private Ltd platform following the posting of such modification; therefore, it is important that you review this agreement regularly.`
            },
            {
                type: 'section',
                heading: '2. NOTIFICATIONS',
                paragraph: 'By providing your email address to Nexyom Consultancy Services Private Ltd, you agree that we may use your email address to send you notifications about Nexyom Consultancy Services Private Ltd. We may also use your email address to send you notifications and other messages, such as changes to service features, news and special content. If you do not wish to receive these emails, you may opt-out of receiving them by submitting your unsubscribe request through the contact information or by using the “unsubscribe” option in the emails. Opting out may prevent you from receiving notifications and mailings about updates, news or special content.',
            },
            {
                type: 'section',
                heading: '3. REGISTRATION AND USER ACCOUNT',
                paragraph: 'Nexyom Consultancy Services Private Ltd platform users can register and login to the platform using an email and password, or via their Google account. To register with an email, it is necessary to provide an active email address, which must be confirmed via a verification link sent by email. Once registration is complete, users have the option to set their name and phone number in the “Account” section of the platform, although this is not mandatory. If users choose to sign in via Google, their name and email information will automatically be taken from their Google account.',
                secondparagraph: `It is the users’ responsibility to maintain the confidentiality of their account credentials, including passwords, and to ensure that their account is not used for unauthorized or illegal activities. Nexyom Consultancy Services Private Ltd is not responsible for any loss or damage resulting from the user’s failure to comply with this obligation. In the event that the user detects any security breach or unauthorized access to your account, you must immediately notify Nexyom Consultancy Services Private Ltd so that appropriate action can be taken. Failure to notify immediately may limit the remediation options available to the user.`,
                thirdparagraph: `Users have the option to close their account at any time by making a request at office@extrata.ai. Also, Nexyom Consultancy Services Private Ltd reserves the right to close any user’s account and suspend their access to the service in the event that any violation of these terms and conditions is detected, at any time, with or without notice.`
            },
            {
                type: 'section',
                heading: '4. USE OF THE PLATFORM',
                paragraph: 'Nexyom Consultancy Services Private Ltd provides specialized services for the extraction of structured data from various types of documents, whether structured or unstructured, including physical documents, digital files and scanned images. These services are available both through a web-based platform and through an API, offering flexibility in their integration and use according to the user’s needs.',
                secondparagraph: `Users of Nexyom Consultancy Services Private Ltd must use the platform responsibly and in compliance with all applicable laws and regulations. It is strictly prohibited to process documents or data that are illegal, contain sensitive personal information without the proper consent of the persons involved, or violate the intellectual property rights of third parties. Users must also refrain from using the platform for any activity that may cause harm to others, interfere with the operation of the platform, or involve fraudulent, malicious activities or contravene any current regulations.`,
                thirdparagraph: `Nexyom Consultancy Services Private Ltd reserves the right to monitor and review the use of the platform to ensure compliance with these terms. In case of detecting any activity that violates these terms, Nexyom Consultancy Services Private Ltd may take the necessary measures, including suspension or termination of access to the platform, without prior notice. Users are responsible for ensuring that their use of the platform does not violate any laws, and for indemnifying Nexyom Consultancy Services Private Ltd for any claims or damages resulting from improper use of the platform.`
            },
            {
                type: 'section',
                heading: '5. PRICING AND PAYMENT',
                paragraph: 'In Nexyom Consultancy Services Private Ltd, the pricing structure is based on the concept of “pages”, which allows users to pay only for the amount of content they process. Each processed document consumes a number of pages, determined by the file type. For documents in PNG or JPG format, each file is considered as one page. For PDF/Word documents, the number of pages depends on the number of physical pages contained in the file. When registering with Nexyom Consultancy Services Private Ltd, each user have to pay for explore the platform’s functionalities. The user can choose to purchase more pages through two payment options:',
                unorderedList: {
                    type: 'bulletpoints',
                    items: [
                        `Pay-as-you-go: The user can create a yearly package based on the number of pages they need to process in a year and complete the payment by calculating the total (number of pages multiply per-page pricing as mentioned on the Docscience.ai pricing webpage). Upon payment, the pages are immediately added to the user’s account balance, and the user can use them as needed, with yearly expiration date.`,
                    ]
                },
                additionalParagraph: 'Users have the flexibility to choose the option that best suits their document processing needs. The platform offers a transparent and convenient system that ensures users pay only for what they really need and use. In addition, users can manage their subscription or make additional purchases at any time through their account on the platform. Should users wish to cancel their subscription, they can do so at any time, and automatic charges will stop as of the following month.'
            },
            {
                type: 'section',
                heading: '6. REFUND POLICY',
                paragraph: `Nexyom Consultancy Services Private Ltd does not offer refunds on any of its services. All purchases, whether for additional pages, subscriptions or any other service on the platform, are final and non-refundable. We recommend users to use the free pages when registering to evaluate the service before making a purchase. In exceptional cases, if a billing error is detected, users may contact our support, but any decision on refunds is at the discretion of Nexyom Consultancy Services Private Ltd. By using our services, users agree to this policy.`,
            },
            {
                type: 'section',
                heading: '7. LIMITATION OF LIABILITY',
                paragraph: 'Nexyom Consultancy Services Private Ltd disclaims any responsibility for the accuracy, completeness or reliability of the data extracted through its platform. While we strive to provide an accurate and efficient service, users should be aware that inaccuracies, errors or discrepancies may occur in the data processed. The platform is provided “as is” and without any explicit or implied warranty that the results obtained are completely correct or fit for specific purposes. Users are strongly advised to verify the accuracy of the extracted data, especially in critical or sensitive applications.',
                secondparagraph: `Nexyom Consultancy Services Private Ltd shall not be liable for any direct, indirect, incidental, special, consequential or punitive damages that may arise from the use or inability to use the platform, including, but not limited to, data errors, technical failures, service interruptions, loss of data or any other type of technical or operational problem. Users fully assume the risk associated with the use of the platform and acknowledge that it is their responsibility to perform the necessary verifications and validations.`,
                thirdparagraph: `Furthermore, Nexyom Consultancy Services Private Ltd guarantees that the data processed through the platform will be used exclusively for the purposes specified in the data extraction and document processing service. Under no circumstances will user data be used to train artificial intelligence models, behavioral analysis or other purposes that are not strictly necessary for the provision of the service offered. All data processing is carried out under strict standards of confidentiality and security, and no data will be used or shared without the explicit consent of the user.`,
                fourparagraph: `In the event of any dispute or claim related to the use of the platform, Nexyom Consultancy Services Private Ltd reserves the right to take the necessary measures to solve the problem, but does not assume any responsibility for the results derived from the use of the services. Users accept these conditions when using the platform and understand that the responsibility falls on them to ensure that the service meets their specific needs.`,
            },
            {
                type: 'section',
                heading: '8. LICENSE TO USE THE PLATFORM',
                paragraph: 'Nexyom Consultancy Services Private Ltd grants you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the Nexyom Consultancy Services Private Ltd platform, including web application and API versions, and modified versions, updates, upgrades, enhancements, extensions, additions and copies, if any. This license is for the sole purpose of allowing you to use the features available on the platform in the manner permitted by these terms and conditions.',
                secondparagraph: `The user agrees not to use the platform negligently, for fraudulent purposes or in an unlawful manner. Likewise, the user agrees not to partake in any conduct or action that could damage the image, interests, or rights of the Nexyom Consultancy Services Private Ltd platform or third parties.`,
                thirdparagraph: `Nexyom Consultancy Services Private Ltd reserves the right to terminate your access immediately, with or without notice, and without liability to you, if Nexyom Consultancy Services Private Ltd believes that you have violated any provision contained in these terms.`
            },
            {
                type: 'section',
                heading: '9. COPYRIGHT',
                paragraph: `All the content available on the platform, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, software and other elements are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by Nexyom Consultancy Services Private Ltd or by third parties that have licensed or otherwise provided their material to the platform. You acknowledge and agree that all Materials on Nexyom Consultancy Services Private Ltd are made available for limited, non-commercial, personal use only. Except as specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without Nexyom Consultancy Services Private Ltd prior express written permission. You may not add, delete, distort, or otherwise modify the material. Any unauthorized attempt to modify any material, to defeat or circumvent any security features, or to utilize Nexyom Consultancy Services Private Ltd or any part of the material for any purpose other than its intended purposes is strictly prohibited.`,
            },
            {
                type: 'section',
                heading: '10. COPYRIGHT INFRINGEMENT',
                paragraph: 'Nexyom Consultancy Services Private Ltd will respond to all inquiries, complaints and claims related to alleged infringements for non-compliance or violation of the provisions contained in the Romanian and international laws and regulations on copyright and intellectual property. Nexyom Consultancy Services Private Ltd respects the intellectual property of others and expects users to do the same. If you believe, in good faith, that any material provided on the website infringes your copyright or other intellectual property rights, please submit your request via our contact information, with the following information: ',
                unorderedList: {
                    type: 'bulletpoints',
                    items: [
                        `Identification of the intellectual property right that is allegedly infringed. All relevant registration numbers or a statement regarding ownership of the work should be included.`,
                        `A statement specifically identifying the location of the infringing material.`,
                        `Your name, address, telephone number and email address.`,
                        `A statement by you that you have a good faith belief that the use of the allegedly infringing material is not authorized by the copyright owner, or its agents, or by law.`,
                        `A statement by you, made under penalty of perjury, that the information in your notification is accurate, and that you are the copyright owner or authorized to act on its behalf.`,
                        `An electronic or physical signature of the copyright owner or of the person authorized to act on the copyright owner’s behalf.`,
                    ]
                },
                additionalParagraph: 'Users have the flexibility to choose the option that best suits their document processing needs. The platform offers a transparent and convenient system that ensures users pay only for what they really need and use. In addition, users can manage their subscription or make additional purchases at any time through their account on the platform. Should users wish to cancel their subscription, they can do so at any time, and automatic charges will stop as of the following month.'
            },
            {
                type: 'section',
                heading: '11. PROHIBITED ACTIVITIES',
                paragraph: 'The following activities are prohibited:',
                unorderedList: {
                    type: 'bulletpoints',
                    items: [
                        `Violate the restrictions in any robot exclusion headers on the services or bypass or circumvent other measures employed to prevent or limit access to the services.`,
                        `Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.`,
                        `Deep-link to any portion of the services for any purpose without our express written permission.`,
                        `Attempt to modify, translate, adapt, edit, decompile, disassemble or reverse engineer any software program used by Nexyom Consultancy Services Private Ltd.`,
                        `Circumvent, disable or otherwise interfere with security-related features of the platform or features that prevent or restrict use or copying of any content.`,
                    ]
                },
            },
            {
                type: 'section',
                heading: '12. DISCLAIMER OF WARRANTIES',
                paragraph: 'Except as expressly provided herein, the services and the platform are provided “as is” and “as available,” without any warranties or conditions of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that the services will be uninterrupted, timely, secure, or error-free, or that any errors will be corrected.',
                secondparagraph: `However, we are committed to providing high-quality service and support. If you encounter any issues, please contact us, and we will do our best to resolve them promptly.`,
                thirdparagraph: `However, we are committed to providing high-quality service and support. If you encounter any issues, please contact us, and we will do our best to resolve them promptly.`,
                fourparagraph: `To the maximum extent permitted by law, Nexyom Consultancy Services Private Ltd liability for any claims arising out of or related to your use of the platform, whether in contract, tort, or otherwise, is limited to the amount you have paid to us in the 3 months preceding the event giving rise to the claim. This limitation applies to all types of damages, Consultancy Services Private Ltd including but not limited to:`,
                unorderedList: {
                    type: 'bulletpoints',
                    items: [
                        `Direct, Indirect, and Consequential Damages: We are not liable for any indirect, consequential, or incidental damages, including loss of profits, data, or goodwill.`,
                        `Data Loss or Inaccuracy: Any loss or damage arising from Consultancy Services Private Ltd incorrect or inaccurate information provided on the platform, or the failure of the platform to store, retrieve, or manage data correctly.`,
                        `Intellectual Property Infringement: Any infringement of third-party intellectual property rights resulting from your use of the platform.`,
                        `Unauthorized Access: Any loss or damage resulting from unauthorized access to your data or account, unless such access is due to our gross negligence or willful misconduct.`,
                        `Viruses and Malicious Software: Any loss or damage caused by viruses or other harmful software that may infect your computer equipment, software, data, or other property due to your use of the platform.`,
                    ]
                },
                additionalParagraph: 'Any claim related to your use of the platform must be brought within one (1) month of the occurrence of the event giving rise to the claim, after which the claim is permanently barred.'
            },
            {
                type: 'section',
                heading: '13. ELECTRONIC COMMUNICATIONS',
                paragraph: 'Nexyom Consultancy Services Private Ltd is not liable for any issues arising from failed, partial, or garbled computer transmissions, including those caused by breakdowns, failures, or connectivity problems related to computer, telephone, network, electronic, or Internet hardware or software. We also do not take responsibility for issues with Internet accessibility, traffic congestion, or any unauthorized actions by third parties, including errors or mistakes.',
            },
            {
                type: 'section',
                heading: '14. INDEMNIFICATION',
                paragraph: 'You agree to defend and indemnify Nexyom Consultancy Services Private Ltd from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:',
                unorderedList: {
                    type: 'bulletpoints',
                    items: [
                        `Your breach of this agreement or the documents referenced herein.`,
                        `Your violation of any law or the rights of a third party.`,
                        `Your use of the Nexyom Consultancy Services Private Ltd platform.`,
                    ]
                },
            },
            {
                type: 'section',
                heading: '15. CHANGES',
                paragraph: 'We may change the platform and these terms at any time, in our sole discretion and without notice to you. You are responsible for remaining knowledgeable about these terms. Your continued use of the platform constitutes your acceptance of any changes to these terms and any changes will supersede all previous versions of the terms. Unless otherwise specified herein, all changes to these terms apply to all users take effect.',
            },
            {
                type: 'section',
                heading: '16. INTEGRATION CLAUSE',
                paragraph: 'This agreement together with the privacy policy and any other legal notices published by Nexyom Consultancy Services Private Ltd, shall constitute the entire agreement between you and Nexyom Consultancy Services Private Ltd concerning and governs your use of the platform.',
            },
            {
                type: 'section',
                heading: '17. SEVERABILITY',
                paragraph: 'If any section of these terms is held invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired. Our failure to enforce or delay in enforcing any provision of these terms at any time does not waive our right to enforce the same or any other provision in the future.',
            },
            {
                type: 'section',
                heading: '18. FORCE MAJEURE',
                paragraph: 'Nexyom Consultancy Services Private Ltd shall not be liable for any failure due to causes beyond its reasonable control, including but not limited to acts of civil authorities, acts of military authorities, riots, acts of nature and natural disasters, and other acts which may be due to unforeseen circumstances.',
            },
            {
                type: 'section',
                heading: '19. TERMINATION',
                paragraph: 'Both the user and Nexyom Consultancy Services Private Ltd acknowledge that failure to comply with these terms and conditions may result in termination of the agreement between both parties.',
                secondparagraph: `In case of non-compliance by the user: If the user fails to comply with any of the terms set forth in these terms and conditions, Nexyom Consultancy Services Private Ltd reserves the right to unilaterally terminate the user’s access to and use of the platform. Nexyom Consultancy Services Private Ltd may take this action without prior notice, and the user will automatically lose all rights to access and use the platform. Furthermore, Nexyom Consultancy Services Private Ltd shall not be liable for any loss of data or information associated with the user’s account.`,
                thirdparagraph: `In case of non-compliance by Nexyom Consultancy Services Private Ltd: If Nexyom Consultancy Services Private Ltd fails to comply with its obligations under the agreed terms and conditions, the user shall be entitled to terminate the agreement and stop using the platform. The user shall notify Nexyom Consultancy Services Private Ltd in writing of the breach and allow a reasonable time for Nexyom Consultancy Services Private Ltd to remedy the situation. If Nexyom Consultancy Services Private Ltd does not remedy the breach within the set period, the user may proceed with the termination of the agreement.`,
                fourparagraph: `Termination of the agreement shall not release either party from any obligations and liabilities that have arisen prior to the date of termination. Continued use of the platform after termination of the agreement by either party shall imply acceptance of the conditions set forth in the terms and conditions in effect at that tim`,
            },
            {
                type: 'section',
                heading: '20. DISPUTES',
                paragraph: 'You acknowledge and agree that any dispute, claim or controversy arising out of or relating to these terms and conditions, whether for breach, termination, enforcement, interpretation or validity, as well as any aspect of the use of the platform, shall preferably be resolved by binding arbitration between you and Nexyom Consultancy Services Private Ltd. However, the right of both parties to bring individual claims before a court of competent jurisdiction if deemed necessary remains.',
                secondparagraph: `In the event of any dispute arising in connection with the use of the platform or breach of these terms and conditions, both parties agree to seek a solution through an arbitration process. This process will be carried out before a recognized and respected arbitration entity, selected by mutual agreement of both parties and following the applicable commercial arbitration rules.`,
                thirdparagraph: `In addition, the user agrees, to the extent permitted by applicable law, not to initiate, join or participate in class action lawsuits associated with any claim, dispute or controversy that may arise in connection with the use of the platform. This commitment seeks to promote a more agile and personalized resolution of any conflict, favoring arbitration and individual legal actions over collective proceedings.`,
            },
            {
                type: 'section',
                heading: '21. GOVERNING LAW AND JURISDICTION',
                paragraph: 'These terms and conditions shall be governed by and construed in accordance with the laws of Romania. Any dispute relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Romania. This applies unless binding arbitration is agreed in the relevant section.',
            },
            {
                type: 'section',
                heading: '22. FINAL PROVISIONS',
                paragraph: 'The use of our platform and the services offered by Nexyom Consultancy Services Private Ltd is conditioned upon your acceptance of and compliance with all of the terms and conditions set forth. This authorization to use our services does not extend to jurisdictions where these provisions are not respected or enforced.',
                secondparagraph: `Our commitment to the enforcement of these terms is strictly governed by applicable laws and legal process. Importantly, these terms do not restrict our ability to comply with legal or governmental requirements, including, but not limited to, those related to law enforcement and the use of our platform. Information provided or collected in connection with the use of the platform will be subject to these requirements.`,
                thirdparagraph: `In the event that any provision of these terms is held invalid, illegal or unenforceable by a court or competent authority, such decision shall not affect the validity or enforceability of the remaining provisions. The failure or delay in enforcing any of these terms by us at any time shall not constitute a waiver of our rights to enforce such provision, or any other provision, in the future.`,
                fourparagraph: `We reserve all rights not expressly granted herein, while maintaining the protection of and respect for our intellectual property rights and prerogatives at all times`,
            },
            {
                type: 'section',
                heading: '23. CONTACT INFORMATION',
                paragraph: `If you have questions or concerns about these terms, please contact us using the contact information below:`,
                secondparagraph: `Email: contact.us@nexyom.com`
            },
        ]
    }
];