import React from 'react';
import Container from '../common/Container';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { LOGIN_REDIRECT_URL } from '../../env';

import { useState } from 'react';

const Cards3 = ({ title, subtext, subhead, subtitle, button, buttonText, list }) => {
    const navigate = useNavigate();

    const handleContactClick = () => {
        if (button === 'Contact Sales') {  
            navigate('/contactUs'); 
        }
    };

    return (
        <div className="card3_container">
            <div className="card3_item">
                <div className="card3y_content">
                    <h2 className="card_title3y">
                        {title}
                        {subtext && <span className="card_subtitle3y">{subtext}</span>}
                    </h2>
                    <h3 className="card_subtitle3y">
                        {subhead && <span className="card_title3y">{subhead}</span>} {subtitle}
                    </h3>
                    {buttonText && <a  rel="noopener noreferrer" href={LOGIN_REDIRECT_URL} className="card_button3y" >{buttonText}</a>}
                    {button && <button className="card_button3y" onClick={handleContactClick} >{button}</button>}
                    <ul className="card_list3y">
                        {list?.map((listItem, index) => (
                            <li key={index} className="card_list_item3y">
                                {listItem.title}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const Cards3y = ({ title, cardtabsName, pricingpage }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const currentPricing = pricingpage[currentTab]?.pricing[0];

    return (
        <div className="cards3y">
            <Container className="card_group3y_container">
                {title && <div className="card3yGroupTitle">{title}</div>}

                <div className="card3y_groups card3y">
                    {pricingpage.map((cardItem, index) => (
                        <div key={index} className="card_group_item3y">
                            <Cards3
                                title={cardItem.pricing[0].title}
                                subtext={cardItem.pricing[0].subtext}
                                subhead={cardItem.pricing[0].subhead}
                                subtitle={cardItem.pricing[0].subtitle}
                                button={cardItem.pricing[0].button}
                                list={cardItem.pricing[0].list1}
                            />
                        </div>
                    ))}
                </div>

                <div className="card3y_mobile">
                    <div className="cardtabs_section ">
                        {cardtabsName.map((tabName, i) => (
                            <div
                                key={i}
                                className={`cardtabs_container ${currentTab === i ? 'cardactive_tab' : 'tabs'}`}
                                onClick={() => setCurrentTab(i)}>
                                {tabName}
                            </div>
                        ))}
                    </div>
                    {currentPricing && (
                        <div className="card_group_item3y_mobile ">
                            <Cards3
                                title={currentPricing.title}
                                subtext={currentPricing.subtext}
                                subhead={currentPricing.subhead}
                                subtitle={currentPricing.subtitle}
                                button={currentPricing.button}
                                list={currentPricing.list1}
                            />
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default Cards3y;
