import React from 'react';
import Container from '../common/Container';
import './style.scss';

const Cards = ({ title, body }) => {
    return (
        <div className="card2xv_container">
            <div className="card2xv_item">
                <h3 className="card2xv_title">{title}</h3>
                <p className="card2xv_text">{body}</p>
            </div>
        </div>
    );
};

const Card2xv = ({ title, list }) => {
    return (
        <div className="card2xv_group_wrapper">
            <Container className="card2xv_group_container">
                {title && <h3 className="card2xv_group_heading">{title}</h3>}
                <div className="card2xv_group card_group2xv">
                    {list?.map((_, i) => {
                        return (
                            <div key={i} className="card_group_item2xv">
                                <Cards title={_.title} body={_.body} />
                            </div>
                        );
                    })}
                </div>
            </Container>
        </div>
    );
};

export default Card2xv;
