import React from 'react';
import { ProductData } from '../../config/product-data';
import HeroSection from '../../components/Product/HeroSection';
import ClientList from '../../components/Product/client-list';
import ExploreMore from '../../components/Landing/ExploreMore';
import Cards3x from '../../components/Product/cards3x';
import IntegrationBlock from '../../components/Landing/IntegrationBlock';
import DataSecurityLogo from '../../components/Landing/DataSecurityLogo';
import ContactForm from '../../components/contact-form';
import CardGroup4X from '../../components/Product/card-group4x';
import SmallCardGroup3x from '../../components/Product/small-cardGroup3x';
import Card4xV from '../../components/Landing/card4xV';
import ScheduleDemoModal from '../../components/ScheduleDemoModal';

const ProductPage = () => {
    const [showDemoModel, toggleDemoModel] = React.useState(false);

    const contactUsHandler = () => {
        toggleDemoModel(true);
    };
    const closeModal = () => {
        toggleDemoModel(false);
    };

    const getSection = d => {
        switch (d.type) {
            case 'heroSection':
                return <HeroSection {...d} action={contactUsHandler} />;
            case 'clientBanner':
                return <ClientList list={d.client} speed="fast" direction="left" />;
            case 'card4xV':
                return <Card4xV {...d} />;
            case 'cardGroup4X':
                return <CardGroup4X {...d} />;
            case 'cards3x':
                return <Cards3x {...d} />;
            case 'smallCardGroup3x':
                return <SmallCardGroup3x {...d} />;
            case 'exploreMore':
                return <ExploreMore {...d} />;
            case 'integrationBlock':
                return <IntegrationBlock {...d} />;
            case 'dataSecurityLogo':
                return <DataSecurityLogo {...d} />;
            case 'contactForm':
                return <ContactForm {...d} />;
            default:
                return null;
        }
    };

    return (
        <>
            <div>
                {ProductData?.map((d, i) => (
                    <div key={i}>{getSection(d)}</div>
                ))}
            </div>
            {showDemoModel && <ScheduleDemoModal onClose={closeModal} />}
        </>
    );
};

export default ProductPage;
