import images from '../assets/images';

export const HomeData = [
    {
        type: 'heroSection',
        title: 'Automate data capture from unstructured business documents',
        body: 'Ditch the traditional way of capturing data and automate it through Docscience. Generative AI makes data extraction smooth, accurate and insightful that enables business to scale seamlessly. ',
        buttonText: 'Schedule Demo',
        action: () => { },
    },
    {
        type: 'card4xV',
        title: 'Process complex business documents end-to-end in 3 simple steps ',
        list: [
            {
                img: images.homePage.Card1,
                title: 'Upload',
                body: 'Upload files from Google Drive, OneDrive, your computer, or any other source.',
            },
            {
                img: images.homePage.Card2,
                title: 'GenAI Data Capture',
                body: 'Automatically extract data with our smart AI, no templates needed.',
            },
            {
                img: images.homePage.Card3,
                title: 'Take Action',
                body: 'Use our tools to flag, review, and validate files, or improve your extracted data.',
            },
            {
                img: images.homePage.Card4,
                title: 'Export',
                body: 'Send data to your CRM or database, or export as XLS, CSV, XML, and more.',
            },
        ],
    },
    {
        type: 'featuresSection',
        list: [
            {
                title: 'Document Ingest',
                subText: 'Automate documents upload, or push through any data source',
                image: images.homePage.UploadImg,
            },
            {
                title: 'Intelligent Data Extraction',
                subText:
                    'Generative-AI captures insightful data from complex documents, and provides necessary information for you to grow your business',
                image: images.homePage.ExtractImg,
            },
            {
                title: 'Take Action',
                subText:
                    'Utilize decision engines to identify, review, and validate files, or to enrich your extracted/missing data ',
                image: '',
            },
            {
                title: 'Export/Download Data',
                subText:
                    'Push extracted structured information into your ERP/CRM/WMS/Database directly, or download in CSV/JSON/Excel format',
                image: images.homePage.ExportImg,
            },
        ],
    },
    {
        type: 'exploreMore',
        title: 'Process complex business documents end-to-end',
        subTitle:
            'Traditional data capture solutions claim to process unstructured documents with 100% accuracy. DOCSCIENCE DOES THAT!',
        tabsName: ['Insurance', 'Finance & Accounting', 'Healthcare', 'Retail & supply chain'],
        HomePageExplore: [
            {
                tag: 'Insurance',
                industries: [
                    {
                        heading: 'Insurance',
                        icon: images.homePage.Insurance,
                        description:
                            'Efficient underwriting and claim-processing  by extracting data from applications, policies, and claims ',
                        list: [
                            'Insurance Applications',
                            'Policy Documents',
                            'Claims Forms',
                            'Medical Records',
                            'Correspondence',
                            'Loss Reports',
                        ],
                    },
                ],
            },
            {
                tag: 'Finance & Accounting',
                industries: [
                    {
                        heading: 'Finance & Accounting',
                        icon: images.homePage.Finance,
                        description:
                            'Automate invoice processing, reporting, and ensure accuracy with intelligent data extraction',
                        list: [
                            'Invoices',
                            'Receipts',
                            'Purchase Orders',
                            'Bank Statements',
                            'Expense Reports',
                            'Tax Forms',
                        ],
                    },
                ],
            },
            {
                tag: 'Healthcare',
                industries: [
                    {
                        heading: 'Healthcare',
                        icon: images.homePage.Healthcare,
                        description:
                            'Improve efficiency and patient care with automatic  extraction of data from records for faster treatment and delivery ',
                        list: [
                            'Patient Records',
                            'Discharge Summaries',
                            'Insurance Claims',
                            'Lab Results',
                            'Appointment Scheduling Documents',
                        ],
                    },
                ],
            },
            {
                tag: 'Retail & supply chain',
                industries: [
                    {
                        heading: 'Retail & supply chain',
                        icon: images.homePage.Retail,
                        description:
                            'Optimize your supply chain by streamlining data processing from purchase orders to invoices and receipts ',
                        list: [
                            'Purchase Orders',
                            'Bills of Lading',
                            'Inventory Lists',
                            'Packing Slips',
                            'Invoices',
                            'Receipts',
                            'Customs Documents',
                        ],
                    },
                ],
            },
        ],
    },
    {
        type: 'cardGroup3X',
        title: 'Why businesses choose Docscience',
        list: [
            {
                icon: images.homePage.SearchDoc,
                title: 'Enhanced Data Capture Accuracy with Gen-AI ',
                subTitle: 'Achieve 100% data extraction accuracy with Generative AI ',
                body: 'Move away from traditional OCR to Docscience made especially to automate data capture workflows from unstructured complex documents ',
            },
            {
                icon: images.homePage.DataFolder,
                title: 'Effortless Implementation, Immediate Results ',
                subTitle: 'No time-consuming data annotation or training required. ',
                body: 'Eliminate upfront prep work. Get started with Docscience out-of-the-box data extraction solution. ',
            },
            {
                icon: images.homePage.AutomateDocument,
                title: 'Customize as per your business needs  ',
                subTitle:
                    'Scale your document processing with your business. Docscience adapts to your needs, from startups to large enterprises. ',
                body: 'Automate document processing regardless of volume. Stop worrying about document overload, Docscience can handle it all',
            },
            {
                icon: images.homePage.SimplifiedDoc,
                title: 'End-to-end Workflow, Simplified Integration ',
                subTitle:
                    'Break down data silos. Integrate Docscience with your CRM and ERP to centralize your information. ',
                body: 'Streamline workflows. Docscience seamlessly connects with your existing software, creating a smooth information flow across your organization. ',
            },
            {
                icon: images.homePage.CostEffectiveData,
                title: 'Cost Effective Smart Data Capture Solution ',
                subTitle: 'Save 50% cost from any other data capture solution in the market. GUARANTEED! ',
                body: 'Ditch expensive data capture methods. Docscience automates tasks, reducing costs and manual data entry.',
            },
        ],
    },
    {
        type: 'integrationBlock',
        title: 'Not just data capture, automate document processing workflows end-to-end with smart Docscience integrations ',
        images: [
            images.homePage.Qb,
            images.homePage.Gmail,
            images.homePage.Zapier,
            images.homePage.Xero,
            images.homePage.Drive,
            images.homePage.DataX,
            images.homePage.Cloud,
            images.homePage.Bill,
        ],
    },
    {
        type: 'dataSecurityLogo',
        title: 'Your data is secure and under your control',
        logos: [
            { src: images.homePage.GDPR, text: 'GDPR-Compliant' },
            { src: images.homePage.SOC2TYPE2, text: 'SOC-2 Type-2 Certified' },
            { src: images.homePage.HIPAA, text: 'HIPPA Compliant' },
        ],
    },
    {
        type: 'contactForm',
    },
];
