import React from 'react';
import Container from '../common/Container';
import './style.scss';

const Cards = ({ img, title, body }) => {
    return (
        <div className="card_container">
            <div className="card_item">
                <img src={img} alt="" />
                <h3 className="card_title">{title}</h3>
                <p className="card_body">{body}</p>
            </div>
        </div>
    );
};

const CardGroup3xV = ({ title, list }) => {
    return (
        <div className="card-group-feature">
            <Container className="card-group-container">
                {title && <h3 className="card-group-title">{title}</h3>}
                <div className="card-group card-group3xV">
                    {list?.map((item, i) => (
                        <div
                            key={i}
                            className={`card-group-item3xV ${i >= list.length - 2 ? 'card-group-item-half' : ''}`}>
                            <Cards img={item.icon} title={item.title} body={item.body} />
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default CardGroup3xV;
