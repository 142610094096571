import React from 'react';
import { PrivacyData } from '../../config/privacy-data';
import HeroSection from '../../components/Privacy-policy/HeroSection';
import TabularSection from '../../components/Privacy-policy/TabularSection';
import Listsection from '../../components/Privacy-policy/Listsection';
import Datasection from '../../components/Privacy-policy/Datasection';

const PrivacyPage = () => {

    const getSection = d => {
        switch (d.type) {
            case 'heroSection':
                return <HeroSection {...d} />;
            case 'tabularSection':
                return <TabularSection {...d} />;
            case 'listsection':
                return <Listsection {...d} />;
            case 'datasection':
                return <Datasection {...d} />;
            default:
                return null;
        }
    };
    return (
        <>
            <div>
                {PrivacyData?.map((d, i) => (
                    <div key={i}>{getSection(d)}</div>
                ))}
            </div>
        </>
    );
};
export default PrivacyPage;