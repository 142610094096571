import React from 'react';
import Container from '../common/Container';

const DataSecurityLogo = ({ title, logos }) => {
    return (
        <div className="data_security_section">
            <Container className="data_security_container">
                <h3 className="data_security_heading">{title}</h3>
                <div className="data_security_content">
                    {logos.map((logo, i) => (
                        <div className="security_logoText" key={i}>
                            <div className="security_logo">
                                <img src={logo.src} alt={logo.text} />
                                <div className="vertical_line"></div>
                            </div>
                            <h5 className="logoText">{logo.text}</h5>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default DataSecurityLogo;
