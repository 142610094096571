export const PricingData = [
    {
        type: 'cards3y',
        title: 'Choose plans according to your needs',
        cardtabsName: ['Starter', 'Professional', 'Enterprise'],
        list: [
            {
                title: 'Starter ',
                subtext: '(Pre built API @ $0.2)',
                subhead: '$4800',
                subtitle: 'Annual Suscription',
                buttonText: 'Login',
                list1: [
                    { title: '24000 pages per year' },
                    { title: '1 pre-trained model' },
                    { title: '3 User License' },
                    { title: 'Self Learning Capability' },
                    { title: 'Validation Logic' },
                    { title: 'Auto Capture Line Items' },
                    { title: 'CRM/ERP Integration' },
                    { title: 'Review Screen' },
                    { title: '+ Auto Classify' },
                    { title: '+ Developer Support' },
                ],
            },
            {
                title: 'Professional',
                subtitle: 'Annual Subscription',
                button: 'Contact Sales',
                list1: [
                    { title: 'Everything in Starter' },
                    { title: '100,000 pages per year' },
                    { title: 'Custom ML Models' },
                    { title: '5 User Licenses' },
                    { title: 'Self Learning Capability' },
                    { title: 'Validation Logic' },
                    { title: 'On-going developer support' },
                    { title: 'Review Screen' },
                    { title: '+ Auto Classify' },
                    { title: '+ Embeddable review screen' },
                ],
            },
            {
                title: 'Enterprise',
                subtitle: 'Annual Subscription',
                button: 'Contact Sales',
                list1: [
                    { title: 'Everything in Professional' },
                    { title: '500,000 Number of pages per year' },
                    { title: 'Custom ML Models' },
                    { title: '10 User Licenses' },
                    { title: 'Self Learning Capability' },
                    { title: 'Validation Logic' },
                    { title: 'On-going developer support' },
                    { title: 'Review Screen' },
                    { title: '+ Auto Classify' },
                    { title: '+ Embeddable review screen' },
                    { title: 'SSO' },
                    { title: 'Developer Support' },
                    { title: 'Custom Integration & API Support' },
                    { title: 'Team Training' },
                    { title: 'White Labelled UI' },
                    { title: 'On-premise solution' },
                ],
            },
        ],
        pricingpage: [
            {
                tag: 'Starter',
                pricing: [
                    {
                        title: 'Starter ',
                        subtext: '(Pre built API @ $0.2)',
                        subhead: '$4800',
                        subtitle: 'Annual Suscription',
                        button: 'Login',
                        list1: [
                            { title: '24000 pages per year' },
                            { title: '1 pre-trained model' },
                            { title: '3 User License' },
                            { title: 'Self Learning Capability' },
                            { title: 'Validation Logic' },
                            { title: 'Auto Capture Line Items' },
                            { title: 'CRM/ERP Integration' },
                            { title: 'Review Screen' },
                            { title: '+ Auto Classify' },
                            { title: '+ Developer Support' },
                        ],
                    },
                ],
            },
            {
                tag: 'Professional',
                pricing: [
                    {
                        title: 'Professional',
                        subtitle: 'Annual Subscription',
                        button: 'Contact Sales',
                        list1: [
                            { title: 'Everything in Starter' },
                            { title: '100,000 pages per year' },
                            { title: 'Custom ML Models' },
                            { title: '5 User Licenses' },
                            { title: 'Self Learning Capability' },
                            { title: 'Validation Logic' },
                            { title: 'On-going developer support' },
                            { title: 'Review Screen' },
                            { title: '+ Auto Classify' },
                            { title: '+ Embeddable review screen' },
                        ],
                    },
                ],
            },
            {
                tag: 'Enterprise',
                pricing: [
                    {
                        title: 'Enterprise',
                        subtitle: 'Annual Subscription',
                        button: 'Contact Sales',
                        list1: [
                            { title: 'Everything in Professional' },
                            { title: '500,000 Number of pages per year' },
                            { title: 'Custom ML Models' },
                            { title: '10 User Licenses' },
                            { title: 'Self Learning Capability' },
                            { title: 'Validation Logic' },
                            { title: 'On-going developer support' },
                            { title: 'Review Screen' },
                            { title: '+ Auto Classify' },
                            { title: '+ Embeddable review screen' },
                            { title: 'SSO' },
                            { title: 'Developer Support' },
                            { title: 'Custom Integration & API Support' },
                            { title: 'Team Training' },
                            { title: 'White Labelled UI' },
                            { title: 'On-premise solution' },
                        ],
                    },
                ],
            },
        ],
    },
    {
        type: 'cards4y',
        title: 'Plan Comaprison',
        list: [
            {
                title: '',
                list1: [
                    { title: 'Prebuilt API Integration' },
                    { title: 'User License' },
                    { title: 'No of Pages Per Year' },
                    { title: 'Review Screen' },
                    { title: 'Validation Logic' },
                    { title: 'Self Learning Capability' },
                    { title: 'Workflow Setup' },
                    { title: 'Auto Capture Line Items' },
                    { title: 'Auto Import from Email' },
                    { title: 'Table or Line Items' },
                    { title: 'Custom Models' },
                    { title: 'Team Features' },
                    { title: 'Checkbox Detection' },
                    { title: 'Document Classification' },
                    { title: 'Custom Integration' },
                    { title: 'Signature Detection' },
                    { title: 'White labelled UI' },
                    { title: 'Guaranteed SLA' },
                    { title: 'Dedicated Account Manager' },
                    { title: 'Setup' },
                    { title: 'Guaranteed SLA' },
                    { title: 'Custom Response' },
                    { title: 'Analytics' },
                    { title: 'SSO' },
                    { title: 'Embeddable Review Screen' },
                ],
            },
            {
                title: 'STARTER',
                list1: [
                    { title: 'Yes' },
                    { title: '3' },
                    { title: '24000' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'No' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'No' },
                    { title: 'Yes' },
                    { title: 'No' },
                    { title: 'No' },
                    { title: 'No' },
                    { title: 'No' },
                    { title: 'No' },
                    { title: 'No' },
                    { title: 'No' },
                    { title: 'Self Serve' },
                    { title: 'No' },
                    { title: 'No' },
                    { title: 'No' },
                    { title: 'No' },
                    { title: 'No' },
                ],
            },
            {
                title: 'PRO',
                list1: [
                    { title: 'Moderate' },
                    { title: '5' },
                    { title: '100,000' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'No' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'No' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'No' },
                    { title: 'No' },
                    { title: 'No' },
                    { title: 'Docscience Setup' },
                    { title: 'No' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'No' },
                    { title: 'No' },
                ],
            },
            {
                title: 'ENTERPRISE',
                list1: [
                    { title: 'Moderate' },
                    { title: 'As per demand' },
                    { title: '500,000' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Docscience Setup' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Yes' },
                    { title: 'Additional' },
                ],
            },
        ],
        planList: [
            {
                subText: 'Prebuilt APi Integration',
                item: [
                    { plan: 'Starter', value: 'Yes' },
                    { plan: 'Pro', value: 'Moderate' },
                    { plan: 'Enterprice', value: 'Moderate' },
                ],
            },
            {
                subText: 'User License',
                item: [
                    { plan: 'Starter', value: '3' },
                    { plan: 'Pro', value: '5' },
                    { plan: 'Enterprice', value: 'As per demand' },
                ],
            },
            {
                subText: 'No of Pages Per Year',
                item: [
                    { plan: 'Starter', value: '24000' },
                    { plan: 'Pro', value: '100,000' },
                    { plan: 'Enterprice', value: '500,000' },
                ],
            },
            {
                subText: 'Review Screen',
                item: [
                    { plan: 'Starter', value: 'Yes' },
                    { plan: 'Pro', value: 'Yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Validation Logic',
                item: [
                    { plan: 'Starter', value: 'Yes' },
                    { plan: 'Pro', value: 'Yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Self Learning Capability',
                item: [
                    { plan: 'Starter', value: 'Yes' },
                    { plan: 'Pro', value: 'Yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Workflow Setup',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'No' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Auto Capture Line Items',
                item: [
                    { plan: 'Starter', value: 'Yes' },
                    { plan: 'Pro', value: 'Yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Auto Import from Email',
                item: [
                    { plan: 'Starter', value: 'Yes' },
                    { plan: 'Pro', value: 'Yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Table or Line Items',
                item: [
                    { plan: 'Starter', value: 'Yes' },
                    { plan: 'Pro', value: 'Yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Custom Models',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'Yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Team Features',
                item: [
                    { plan: 'Starter', value: 'Yes' },
                    { plan: 'Pro', value: 'Yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Checkbox Detection',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'No' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Document Classification',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Custom Integration',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'Yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Signature Detection',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'Yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'White labelled UI',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'No' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Guaranteed SLA',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'No' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Dedicated Account Manager',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'No' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Setup',
                item: [
                    { plan: 'Starter', value: 'Self Serve' },
                    { plan: 'Pro', value: 'Docscience Setup' },
                    { plan: 'Enterprice', value: 'Docscience Setup' },
                ],
            },
            {
                subText: 'Guaranteed SLA',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'No' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Custom Response',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'Yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Analytics',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'Yes' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'SSO',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'No' },
                    { plan: 'Enterprice', value: 'Yes' },
                ],
            },
            {
                subText: 'Embeddable Review Screen',
                item: [
                    { plan: 'Starter', value: 'No' },
                    { plan: 'Pro', value: 'No' },
                    { plan: 'Enterprice', value: 'Additional' },
                ],
            },
        ],
    },
];
