import React from 'react';
import './index.scss';
import HighlightText from '../common/HighLightText';
import Container from '../../components/common/Container';
import Button from '@mui/material/Button';

const Cards = ({ icon, title, subText }) => {
    return (
        <div className="card_container">
            <div className="card_item">
                <img src={icon} alt={title} />
                <div className="card_itemx">
                    <h3 className="card_title">{title}</h3>
                    <h4 className="card_text">{subText}</h4>
                </div>
            </div>
        </div>
    );
};

const HeroSection = ({ title, body, buttonText, list, action = () => {} }) => {
    return (
        <div className="hero_section_wrapper">
            <Container>
                <div className="hero_section">
                    <div className="hero_section_contentL">
                        {title && (
                            <h3 className="hero_section_title">
                                {title} <HighlightText text={'Complex Unstructured Documents'} />{' '}
                            </h3>
                        )}
                        {body && <h5 className="hero_section_subTitle">{body}</h5>}
                        <Button variant="contained" data-testid="schedule-demo-modal" onClick={action}>
                            {buttonText}
                        </Button>
                    </div>
                    <div className="hero_section_contentR">
                        <div className="card_group" style={{ padding: '60px 0' }}>
                            {list?.map((_, i) => {
                                return (
                                    <div key={i} className="card_group_item">
                                        <Cards icon={_.icon} title={_.title} subText={_.subText} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default HeroSection;
