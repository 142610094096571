import React from 'react';
import Container from '../common/Container';
import './index.scss';

const Cards4 = ({ title, list }) => {
    return (
        <div className="card4_container">
            <div className="card4_item">
                <div className="card4y_content">
                    <h2 className="card_title4y">{title}</h2>
                    <ul className="card_list4y">
                        {list?.map((listItem, index) => (
                            <li key={index} className="card_list_item4y">
                                {listItem.title}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const Cards4y = ({ title, list, planList }) => {
    return (
        <div className="cards4y">
            <Container className="card_group4y_container">
                {title && <div className="card4yGroupTitle">{title}</div>}
                <div className="card4y_groups card4y">
                    {list?.map((cardItem, index) => (
                        <div key={index} className="card_group_item4y">
                            <Cards4 title={cardItem.title} list={cardItem.list1} />
                        </div>
                    ))}
                </div>
                <div className="plan_comparison_mobile">
                    <div className="plan_comparison_wrapper">
                        {planList?.map((item, i) => {
                            return (
                                <div key={i} className="plan_comparison_content">
                                    <div className="plan_comparison_title">{item.subText}</div>
                                    <ul>
                                        {item.item?.map((subItem, j) => (
                                            <li key={j} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span className="plan_comparison_plan">{subItem.plan}</span>
                                                <span className="plan_comparison_value">{subItem.value}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Cards4y;
