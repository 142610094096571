import React from 'react';
import Container from '../common/Container';

const Cards = ({ icon, title, subTitle }) => {
    return (
        <div className="card_container">
            <div className="card_item">
                <img src={icon} alt={title} />
                <h3 className="card_title4x">{title}</h3>
                <h4 className="card_text4x">{subTitle}</h4>
            </div>
        </div>
    );
};

const CardGroup4X = ({ title, list }) => {
    return (
        <div className="card_group4x">
            <Container className="card_group4x_container">
                {title && <div className="cardGroupTitle">{title}</div>}
                <div className="card_group card_group4X">
                    {list?.map((_, i) => {
                        return (
                            <div key={i} className="card_group_item4X">
                                <Cards icon={_.icon} title={_.title} subTitle={_.subTitle} />
                            </div>
                        );
                    })}
                </div>
            </Container>
        </div>
    );
};

export default CardGroup4X;
