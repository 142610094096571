import React from 'react';
import { PricingData } from '../../config/pricing-data';
import Cards3y from '../../components/Pricing/index';
import Cards4y from '../../components/Pricing/cards4y';

const Pricingpage = () => {
    const getSection = d => {
        switch (d.type) {
            case 'cards3y':
                return <Cards3y {...d} />;
            case 'cards4y':
                return <Cards4y {...d} />;
        }
    };

    return (
        <div>
            {PricingData?.map((d, i) => {
                return <div key={i}>{getSection(d)}</div>;
            })}
        </div>
    );
};
export default Pricingpage;
