import React from 'react';
import { TermsData } from '../../config/terms-data';
import Termsdatasec from '../../components/Terms-conditions/termsdatasec';
import Herosect from '../../components/Terms-conditions/herosect';

const TermsPage = () => {
    const getSection = d => {
        switch (d.type) {
            case 'herosect':
                return <Herosect {...d} />;
            case 'termsdatasec':
                return <Termsdatasec data={d} />;
            default:
                return null;
        }
    };

    return (
        <div>
            {TermsData && TermsData.map((d, i) => (
                <div key={i}>{getSection(d)}</div>
            ))}
        </div>
    );
};

export default TermsPage;
