import images from '../assets/images';

export const UsecaseData = {
    usecaseAccountsPayable: [
        {
            type: 'heroBanner',
            title: 'Eliminate Accounts Payable bottlenecks with Gen-AI powered invoice processing',
            body: "Docscience's intelligent AP solution leverages generative AI to automate your entire invoice processing workflow. Reduce costs and empower your team to focus on strategic financial management.",
            buttonText: 'Schedule Demo',
            action: () => { },
        },
        {
            type: 'clientBanner',
            client: [
                {
                    logo: images.product.Health,
                    alt: 'inventHealth',
                },
                {
                    logo: images.product.NexgenTech,
                    alt: 'nexgen_technologies',
                },
                {
                    logo: images.product.NexgenIot,
                    alt: 'nexgen_iotsolutions',
                },
                { logo: images.product.Briwo, alt: 'briwo' },
                { logo: images.product.CloudClix, alt: 'cloud_clix' },
                { logo: images.product.Fifthgen, alt: 'fifthgen' },
                { logo: images.product.Adroetech, alt: 'Adroetech' },
                { logo: images.product.Bnexgen, alt: 'b_nexgen' },
            ],
        },
        {
            type: 'card4xV',
            title: 'End-to-end Accounts Payable process in 4 simple steps',
            list: [
                {
                    img: images.homePage.Card1,
                    title: 'Ingest documents',
                    body: 'Push invoices, purchase orders, receipts, credit notes, and expense reports through direct upload, email, or API.',
                },
                {
                    img: images.homePage.Card2,
                    title: 'Capture data',
                    body: 'Our Gen-AI powered IDP engine extracts data from even the most complex documents with near-perfect accuracy.',
                },
                {
                    img: images.homePage.Card3,
                    title: 'Take Action',
                    body: 'Automated data validation and optional human review ensure maximum accuracy and compliance.',
                },
                {
                    img: images.homePage.Card4,
                    title: 'Export & Analyze',
                    body: 'Seamlessly push captured data into AP software or download in CSV, Excel, or JSON formats for in-depth analysis and decision-making.',
                },
            ],
        },
        {
            type: 'cards2xv',
            title: "Empower your Accounts Payable team with Docscience's AI-Powered AP Automation",

            list: [
                {

                    title: 'Accelerate Invoice processing',
                    body: 'Reduce manual tasks by 90% and process invoices 5x faster. Our Gen-AI engine allows your team to handle higher volumes of invoices without increasing headcount, significantly reducing processing time from weeks to days. ',
                },
                {

                    title: 'Achieve unmatched accuracy',
                    body: 'Attain up to 99.5% data extraction accuracy with our Gen-AI powered engine. Minimize errors associated with manual data entry, reducing exceptions and improving vendor relationships through timely, accurate payments. ',
                },
                {

                    title: 'Integrate with existing systems',
                    body: 'Connect with your current AP software in minutes, not months. Our flexible API and pre-built connectors ensure smooth data flow between Docscience and your financial systems, eliminating data silos and improving overall efficiency.',
                },
                {

                    title: 'Reduce operational cost',
                    body: 'Cut AP processing costs by up to 80% through intelligent automation. Reduce labor costs associated with manual data entry and reconciliation, while also minimizing late payment fees and capturing more early payment discounts.',
                },
                {

                    title: 'Enhanced financial visibility and control',
                    body: 'Gain real-time insights into your AP metrics for better cash flow management. Our customizable dashboards provide instant visibility into invoice status, payment trends, and vendor performance, enabling data-driven financial decisions.',
                },
            ],
        },
        {
            type: 'integrationBlock',
            title: 'Not just data capture, automate document processing workflows end-to-end with smart Docscience integrations ',
            images: [
                images.homePage.Qb,
                images.homePage.Gmail,
                images.homePage.Zapier,
                images.homePage.Xero,
                images.homePage.Drive,
                images.homePage.DataX,
                images.homePage.Cloud,
                images.homePage.Bill,
            ],
        },
        {
            type: 'dataSecurityLogo',
            title: 'Your data is secure and under your control',
            logos: [
                { src: images.homePage.GDPR, text: 'GDPR-Compliant' },
                { src: images.homePage.SOC2TYPE2, text: 'SOC-2 Type-2 Certified' },
                { src: images.homePage.HIPAA, text: 'HIPPA Compliant' },
            ],
        },
        {
            type: 'contactForm',
        },
    ],

    usecaseAccountsReconciliation: [
        {
            type: 'heroBanner',
            title: 'Eliminate reconciliation bottlenecks with Generative AI based intelligent document automation',
            body: "Docscience's AI-driven account reconciliation solution streamlines your financial close process, reducing errors and freeing your team to focus on high-value analysis and decision-making.",
            buttonText: 'Schedule Demo',
            action: () => { },
        },
        {
            type: 'clientBanner',
            client: [
                {
                    logo: images.product.Health,
                    alt: 'inventHealth',
                },
                {
                    logo: images.product.NexgenTech,
                    alt: 'nexgen_technologies',
                },
                {
                    logo: images.product.NexgenIot,
                    alt: 'nexgen_iotsolutions',
                },
                { logo: images.product.Briwo, alt: 'briwo' },
                { logo: images.product.CloudClix, alt: 'cloud_clix' },
                { logo: images.product.Fifthgen, alt: 'fifthgen' },
                { logo: images.product.Adroetech, alt: 'Adroetech' },
                { logo: images.product.Bnexgen, alt: 'b_nexgen' },
            ],
        },
        {
            type: 'card4xV',
            title: 'Simplify your Account Reconciliation process in 4 easy steps',
            list: [
                {
                    img: images.homePage.Card1,
                    title: 'Data import',
                    body: 'Seamlessly import financial data from bank statements, ERP systems, and accounting software through direct upload, API integration, or email.',
                },
                {
                    img: images.homePage.Card2,
                    title: 'Intelligent matching',
                    body: 'Our Gen-AI powered engine automatically matches transactions across different systems, identifying discrepancies and exceptions with unparalleled accuracy',
                },
                {
                    img: images.homePage.Card3,
                    title: 'Review & Resolve',
                    body: 'Quickly review AI-flagged discrepancies and resolve issues through an intuitive interface, with smart suggestions for faster problem-solving.',
                },
                {
                    img: images.homePage.Card4,
                    title: 'Reporting & Analysis',
                    body: 'Generate comprehensive reconciliation reports and gain actionable insights through customizable dashboards for auditing and analysis.',
                },
            ],
        },
        {
            type: 'cards2xv',
            title: "Increase efficiency at financial closing with Docscience's AI-powered reconciliation",

            list: [
                {

                    title: 'Accelerate the month-end close',
                    body: 'Reduce reconciliation time by up to 80% and close books faster. Our AI-driven matching engine processes thousands of transactions in minutes, allowing your team to complete monthly reconciliations in hours instead of days. ',
                },
                {

                    title: 'Achieve 100% accuracy',
                    body: 'Attain up to 99.9% matching accuracy with our Gen-AI powered system. Minimize human errors and detect anomalies that might be overlooked in manual processes, ensuring the integrity of your financial statements. ',
                },
                {

                    title: 'Enhance visibility and control',
                    body: 'Gain real-time insights into your reconciliation status and financial health. Our dynamic dashboards provide instant visibility into match rates, exception trends, and reconciliation progress, enabling proactive financial management.',
                },
                {

                    title: 'Streamline audit preparation',
                    body: 'Cut audit preparation time by up to 70% with comprehensive audit trails. Automatically document all reconciliation activities, including match rules, exceptions, and resolutions, creating a clear audit trail that simplifies regulatory compliance.',
                },
                {

                    title: 'Eliminate manual document processing',
                    body: "Free up to 40% of your team's time for strategic activities. By automating routine reconciliation tasks, your finance professionals can focus on value-added analysis, financial planning, and supporting business growth initiatives.",
                },
            ],
        },
        {
            type: 'integrationBlock',
            title: 'Not just data capture, automate document processing workflows end-to-end with smart Docscience integrations ',
            images: [
                images.homePage.Qb,
                images.homePage.Gmail,
                images.homePage.Zapier,
                images.homePage.Xero,
                images.homePage.Drive,
                images.homePage.DataX,
                images.homePage.Cloud,
                images.homePage.Bill,
            ],
        },
        {
            type: 'dataSecurityLogo',
            title: 'Your data is secure and under your control',
            logos: [
                { src: images.homePage.GDPR, text: 'GDPR-Compliant' },
                { src: images.homePage.SOC2TYPE2, text: 'SOC-2 Type-2 Certified' },
                { src: images.homePage.HIPAA, text: 'HIPPA Compliant' },
            ],
        },
        {
            type: 'contactForm',
        },
    ],

    usecaseBillOfLading: [
        {
            type: 'heroBanner',
            title: 'Accelerate supply chain efficiency with Gen-AI based Bill of Lading processing',
            body: "Docscience's AI-driven solution transforms your Bill of Lading workflow, automating document processing to enhance accuracy, reduce delays, and optimize your shipping operations",
            buttonText: 'Schedule Demo',
            action: () => { },
        },
        {
            type: 'clientBanner',
            client: [
                {
                    logo: images.product.Health,
                    alt: 'inventHealth',
                },
                {
                    logo: images.product.NexgenTech,
                    alt: 'nexgen_technologies',
                },
                {
                    logo: images.product.NexgenIot,
                    alt: 'nexgen_iotsolutions',
                },
                { logo: images.product.Briwo, alt: 'briwo' },
                { logo: images.product.CloudClix, alt: 'cloud_clix' },
                { logo: images.product.Fifthgen, alt: 'fifthgen' },
                { logo: images.product.Adroetech, alt: 'Adroetech' },
                { logo: images.product.Bnexgen, alt: 'b_nexgen' },
            ],
        },
        {
            type: 'card4xV',
            title: 'Optimize your Bill of Lading processing in 4 easy steps',
            list: [
                {
                    img: images.homePage.Card1,
                    title: 'Document capture',
                    body: 'Ingest Bills of Lading from multiple sources including email, through direct upload, or API integration.',
                },
                {
                    img: images.homePage.Card2,
                    title: 'Intelligent data extraction',
                    body: 'Our Gen-AI powered engine automatically extracts key information from complex BOL documents with high accuracy.',
                },
                {
                    img: images.homePage.Card3,
                    title: 'Automated validation & routing',
                    body: 'Instantly validate extracted data against predefined rules, flagging discrepancies for review and routing compliant BOLs for further processing or approval.',
                },
                {
                    img: images.homePage.Card4,
                    title: 'Integration & reporting',
                    body: 'Seamlessly integrate processed BOL data with your existing systems and generate comprehensive reports through customizable dashboards for informed decision-making.',
                },
            ],
        },
        {
            type: 'cards2xv',
            title: "Transform your Bill of Lading processing with Docscience's AI-powered solution",

            list: [
                {

                    title: 'Accelerate document processing',
                    body: 'Reduce BOL processing time by up to 90% and minimize shipping delays. Our AI-driven system extracts and validates critical BOL information in seconds, allowing your team to process hundreds of documents daily, significantly reducing time from receipt to actionable data ',
                },
                {

                    title: 'Enhance data accuracy',
                    body: 'Achieve up to 99.9% data extraction accuracy with our Gen-AI powered engine. Minimize errors associated with manual data entry, reducing costly mistakes in shipping information, customs declarations, and freight calculations.  ',
                },
                {

                    title: 'Optimize operational efficiency',
                    body: 'Cut BOL processing costs by up to 70% while improving overall supply chain efficiency. Automate routine document handling tasks, allowing your team to focus on exception handling and strategic shipping optimizations that drive business value.',
                },
                {

                    title: 'Improve compliance & auditability',
                    body: 'Reduce compliance risks and audit preparation time by 80% with comprehensive documentation. Automatically flag potential compliance issues, maintain a clear audit trail of all BOL processing activities, and generate reports that simplify regulatory compliance.',
                },
                {

                    title: 'Empower data-driven decision making',
                    body: 'Gain real-time insights into shipping trends and performance metrics. Our AI-powered analytics provide instant visibility into shipping volumes, carrier performance, and potential bottlenecks, enabling strategic decisions to optimize your supply chain operations.',
                },
            ],
        },
        {
            type: 'integrationBlock',
            title: 'Not just data capture, automate document processing workflows end-to-end with smart Docscience integrations ',
            images: [
                images.homePage.Qb,
                images.homePage.Gmail,
                images.homePage.Zapier,
                images.homePage.Xero,
                images.homePage.Drive,
                images.homePage.DataX,
                images.homePage.Cloud,
                images.homePage.Bill,
            ],
        },
        {
            type: 'dataSecurityLogo',
            title: 'Your data is secure and under your control',
            logos: [
                { src: images.homePage.GDPR, text: 'GDPR-Compliant' },
                { src: images.homePage.SOC2TYPE2, text: 'SOC-2 Type-2 Certified' },
                { src: images.homePage.HIPAA, text: 'HIPPA Compliant' },
            ],
        },
        {
            type: 'contactForm',
        },
    ],

    usecaseClaimFraudDetection: [
        {
            type: 'heroBanner',
            title: 'Detect Insurance Fraud faster with Gen-AI for intelligent claim processing',
            body: "Docscience's AI-driven solution transforms your claim fraud detection process, leveraging advanced analytics to identify suspicious patterns, reduce losses, and streamline legitimate claim processing.",
            buttonText: 'Schedule Demo',
            action: () => { },
        },
        {
            type: 'clientBanner',
            client: [
                {
                    logo: images.product.Health,
                    alt: 'inventHealth',
                },
                {
                    logo: images.product.NexgenTech,
                    alt: 'nexgen_technologies',
                },
                {
                    logo: images.product.NexgenIot,
                    alt: 'nexgen_iotsolutions',
                },
                { logo: images.product.Briwo, alt: 'briwo' },
                { logo: images.product.CloudClix, alt: 'cloud_clix' },
                { logo: images.product.Fifthgen, alt: 'fifthgen' },
                { logo: images.product.Adroetech, alt: 'Adroetech' },
                { logo: images.product.Bnexgen, alt: 'b_nexgen' },
            ],
        },
        {
            type: 'card4xV',
            title: 'Enhance your Claim Fraud Detection in 4 steps ',
            list: [
                {
                    img: images.homePage.Card1,
                    title: 'Data ingestion',
                    body: 'Seamlessly import claim data from multiple sources including claim forms, police reports, medical records, and historical databases through secure API integration or direct upload.',
                },
                {
                    img: images.homePage.Card2,
                    title: 'Intelligent analysis',
                    body: 'Our Gen-AI powered engine automatically analyzes claim data, extracting key information and identifying suspicious patterns with unparalleled accuracy and speed.',
                },
                {
                    img: images.homePage.Card3,
                    title: 'Risk scoring & flagging',
                    body: 'Automatically score claims based on multiple risk factors, flagging high-risk cases for further investigation while fast-tracking low-risk claims for prompt processing.',
                },
                {
                    img: images.homePage.Card4,
                    title: 'Investigation & Reporting',
                    body: 'Generate comprehensive fraud analysis reports and gain actionable insights through customizable dashboards for informed decision-making.',
                },
            ],
        },
        {
            type: 'cards2xv',
            title: "Transform your Claim Fraud Detection with Docscience's AI-powered solution",

            list: [
                {

                    title: 'Accelerate fraud detection',
                    body: 'Reduce fraud detection time by up to 75% and identify potential fraud faster. Our AI-driven system analyzes thousands of claims in minutes, allowing your team to quickly focus on high-risk cases while expediting legitimate claims. ',
                },
                {

                    title: 'Enhance detection accuracy',
                    body: 'Achieve up to 95% accuracy in fraud prediction with our Gen-AI powered engine. Minimize false positives and negatives by leveraging advanced machine learning algorithms that continually improve based on new data and outcomes. ',
                },
                {

                    title: 'Optimize operational efficiency',
                    body: 'Cut claim processing costs by up to 40% while improving investigation effectiveness. Automate routine claim assessments, allowing your fraud investigators to concentrate on complex, high-value cases that require human expertise.',
                },
                {

                    title: 'Reduce fraud losses',
                    body: 'Decrease fraudulent claim payouts by up to 60% through early detection and prevention. Our system identifies subtle fraud indicators that might be missed by traditional rule-based systems, helping you stop fraudulent claims before they result in losses.',
                },
                {

                    title: 'Empower data-driven decision making',
                    body: 'Gain real-time insights into fraud trends and emerging patterns. Our AI-powered analytics provide instant visibility into fraud hotspots, common tactics, and seasonal variations, enabling proactive fraud prevention strategies and policy adjustments.',
                },
            ],
        },
        {
            type: 'integrationBlock',
            title: 'Not just data capture, automate document processing workflows end-to-end with smart Docscience integrations ',
            images: [
                images.homePage.Qb,
                images.homePage.Gmail,
                images.homePage.Zapier,
                images.homePage.Xero,
                images.homePage.Drive,
                images.homePage.DataX,
                images.homePage.Cloud,
                images.homePage.Bill,
            ],
        },
        {
            type: 'dataSecurityLogo',
            title: 'Your data is secure and under your control',
            logos: [
                { src: images.homePage.GDPR, text: 'GDPR-Compliant' },
                { src: images.homePage.SOC2TYPE2, text: 'SOC-2 Type-2 Certified' },
                { src: images.homePage.HIPAA, text: 'HIPPA Compliant' },
            ],
        },
        {
            type: 'contactForm',
        },
    ],

    usecaseCPGLoyalty: [
        {
            type: 'heroBanner',
            title: 'Elevate consumer engagement with Gen-AI based smart CPG loyalty programs',
            body: "Docscience's AI-driven solution transforms your CPG loyalty program, leveraging advanced analytics to enhance customer engagement, increase repeat purchases, and drive brand growth",
            buttonText: 'Schedule Demo',
            action: () => { },
        },
        {
            type: 'clientBanner',
            client: [
                {
                    logo: images.product.Health,
                    alt: 'inventHealth',
                },
                {
                    logo: images.product.NexgenTech,
                    alt: 'nexgen_technologies',
                },
                {
                    logo: images.product.NexgenIot,
                    alt: 'nexgen_iotsolutions',
                },
                { logo: images.product.Briwo, alt: 'briwo' },
                { logo: images.product.CloudClix, alt: 'cloud_clix' },
                { logo: images.product.Fifthgen, alt: 'fifthgen' },
                { logo: images.product.Adroetech, alt: 'Adroetech' },
                { logo: images.product.Bnexgen, alt: 'b_nexgen' },
            ],
        },
        {
            type: 'card4xV',
            title: 'Optimize your CPG Loyalty program in 4 innovative steps ',
            list: [
                {
                    img: images.homePage.Card1,
                    title: 'Data collection',
                    body: 'Seamlessly capture customer data from multiple touchpoints including purchase receipts, mobile apps, social media, and in-store interactions through API integration or direct upload.',
                },
                {
                    img: images.homePage.Card2,
                    title: 'Intelligent analysis',
                    body: 'Our Gen-AI powered engine automatically analyzes customer behavior, purchase patterns, and preferences, extracting valuable insights to drive personalized engagement.',
                },
                {
                    img: images.homePage.Card3,
                    title: 'Dynamic segmentation & targeting',
                    body: "Automatically segment customers based on multiple factors, creating targeted campaigns and personalized offers that resonate with each group's unique preferences and behaviors.",
                },
                {
                    img: images.homePage.Card4,
                    title: 'Reward distribution',
                    body: 'Generate comprehensive loyalty program reports and gain actionable insights through customizable dashboards, enabling data-driven decision-making.',
                },
            ],
        },
        {
            type: 'cards2xv',
            title: "Elevate your CPG Loyalty program with Docscience's AI-powered solution",

            list: [
                {

                    title: 'Enhance customer engagement',
                    body: ' Increase customer engagement rates by up to 70% through hyper-personalized interactions. Our AI-driven system analyzes customer behavior in real-time, allowing you to deliver tailored offers, content, and experiences that resonate with individual preferences and purchase history.',
                },
                {

                    title: 'Boost repeat purchases',
                    body: 'Drive a 40% increase in repeat purchases by leveraging predictive analytics. Our Gen-AI engine identifies optimal times and channels for customer outreach, recommending products and offers most likely to convert based on individual customer profiles. ',
                },
                {

                    title: 'Optimize program ROI',
                    body: 'Improve loyalty program ROI by up to 50% while reducing operational costs. Automate reward calculations, distribution, and redemption processes, freeing your team to focus on strategic initiatives that drive program value and customer satisfaction.',
                },
                {

                    title: 'Reduce customer churn',
                    body: 'Decrease customer churn rates by up to 30% through early identification of at-risk customers. Our system detects subtle indicators of declining engagement, enabling proactive retention strategies before customers become inactive.',
                },
                {

                    title: 'Empower data-driven decision making',
                    body: 'Gain real-time insights into program performance and customer trends. Our AI-powered analytics provide instant visibility into key metrics such as customer lifetime value, redemption rates, and campaign effectiveness, enabling agile program adjustments and strategic planning.',
                },
            ],
        },
        {
            type: 'integrationBlock',
            title: 'Not just data capture, automate document processing workflows end-to-end with smart Docscience integrations ',
            images: [
                images.homePage.Qb,
                images.homePage.Gmail,
                images.homePage.Zapier,
                images.homePage.Xero,
                images.homePage.Drive,
                images.homePage.DataX,
                images.homePage.Cloud,
                images.homePage.Bill,
            ],
        },
        {
            type: 'dataSecurityLogo',
            title: 'Your data is secure and under your control',
            logos: [
                { src: images.homePage.GDPR, text: 'GDPR-Compliant' },
                { src: images.homePage.SOC2TYPE2, text: 'SOC-2 Type-2 Certified' },
                { src: images.homePage.HIPAA, text: 'HIPPA Compliant' },
            ],
        },
        {
            type: 'contactForm',
        },
    ],
 
    usecaseOrderManagement: [
        {
            type: 'heroBanner',
            title: 'Boost order fulfillment efficiency with Gen-AI powered document processing',
            body: "Docscience's AI-driven solution transforms your order management workflow, automating document processing to accelerate fulfillment, reduce errors, and enhance customer satisfaction.",
            buttonText: 'Schedule Demo',
            action: () => { },
        },
        {
            type: 'clientBanner',
            client: [
                {
                    logo: images.product.Health,
                    alt: 'inventHealth',
                },
                {
                    logo: images.product.NexgenTech,
                    alt: 'nexgen_technologies',
                },
                {
                    logo: images.product.NexgenIot,
                    alt: 'nexgen_iotsolutions',
                },
                { logo: images.product.Briwo, alt: 'briwo' },
                { logo: images.product.CloudClix, alt: 'cloud_clix' },
                { logo: images.product.Fifthgen, alt: 'fifthgen' },
                { logo: images.product.Adroetech, alt: 'Adroetech' },
                { logo: images.product.Bnexgen, alt: 'b_nexgen' },
            ],
        },
        {
            type: 'card4xV',
            title: 'Optimize your Order Management process in 4 easy steps',
            list: [
                {
                    img: images.homePage.Card1,
                    title: 'Document capture',
                    body: 'Effortlessly ingest orders from multiple channels including e-commerce platforms, EDI, emails, and manual entry through direct upload or API integration.',
                },
                {
                    img: images.homePage.Card2,
                    title: 'Intelligent data extraction',
                    body: 'Our Gen-AI powered engine automatically extracts key information from complex order documents with high accuracy, eliminating manual data entry and reducing errors.',
                },
                {
                    img: images.homePage.Card3,
                    title: 'Automated order Processing',
                    body: 'Trigger automated workflows based on extracted data, such as inventory checks, shipping label generation, and invoice creation, with optional human review for exceptions.',
                },
                {
                    img: images.homePage.Card4,
                    title: 'Analytics & reporting',
                    body: 'Generate comprehensive order management reports and gain actionable insights through customizable dashboards, exportable in various formats for informed decision-making.',
                },
            ],
        },
        {
            type: 'cards2xv',
            title: "Elevate your Order Management with Docscience's AI-powered solution",

            list: [
                {

                    title: 'Accelerate order processing',
                    body: 'Reduce order processing time by up to 80% and fulfill orders faster. Our AI-driven system extracts key order details in seconds, allowing your team to handle higher order volumes without increasing headcount, significantly reducing time from order placement to shipment. ',
                },
                {

                    title: 'Enhance order accuracy',
                    body: ' Achieve up to 99.9% order accuracy with our Gen-AI powered engine. Minimize errors associated with manual data entry, reducing order exceptions, returns, and customer complaints. This improved accuracy leads to higher customer satisfaction and loyalty.',
                },
                {

                    title: 'Optimize inventory management',
                    body: 'Improve inventory turnover by 30% through real-time order insights. Automatically update inventory levels as orders are processed, trigger reorder points, and gain visibility into product demand trends, enabling more efficient stock management and reducing stockouts.',
                },
                {

                    title: 'Streamline multi-channel order fulfillment',
                    body: 'Unify order processing across all sales channels, reducing operational complexity by 50%. Our system integrates orders from various sources into a single platform, standardizing processes and providing a consistent experience regardless of the order origin.',
                },
                {

                    title: 'Empower data-driven decision making',
                    body: 'Gain real-time insights into order performance and customer behavior. Our AI-powered analytics provide instant visibility into order volumes, fulfillment rates, popular products, and seasonal trends, enabling strategic inventory and marketing decisions.',
                },
            ],
        },
        {
            type: 'integrationBlock',
            title: 'Not just data capture, automate document processing workflows end-to-end with smart Docscience integrations ',
            images: [
                images.homePage.Qb,
                images.homePage.Gmail,
                images.homePage.Zapier,
                images.homePage.Xero,
                images.homePage.Drive,
                images.homePage.DataX,
                images.homePage.Cloud,
                images.homePage.Bill,
            ],
        },
        {
            type: 'dataSecurityLogo',
            title: 'Your data is secure and under your control',
            logos: [
                { src: images.homePage.GDPR, text: 'GDPR-Compliant' },
                { src: images.homePage.SOC2TYPE2, text: 'SOC-2 Type-2 Certified' },
                { src: images.homePage.HIPAA, text: 'HIPPA Compliant' },
            ],
        },
        {
            type: 'contactForm',
        },
    ],

    usecasePropertyManagement: [
        {
            type: 'heroBanner',
            title: 'Streamline your real estate operations with Gen-AI for smart property management',
            body: "Docscience's AI-driven solution transforms your property management workflow, automating document processing to enhance tenant satisfaction, reduce operational costs, and boost your bottom line.",
            buttonText: 'Schedule Demo',
            action: () => { },
        },
        {
            type: 'clientBanner',
            client: [
                {
                    logo: images.product.Health,
                    alt: 'inventHealth',
                },
                {
                    logo: images.product.NexgenTech,
                    alt: 'nexgen_technologies',
                },
                {
                    logo: images.product.NexgenIot,
                    alt: 'nexgen_iotsolutions',
                },
                { logo: images.product.Briwo, alt: 'briwo' },
                { logo: images.product.CloudClix, alt: 'cloud_clix' },
                { logo: images.product.Fifthgen, alt: 'fifthgen' },
                { logo: images.product.Adroetech, alt: 'Adroetech' },
                { logo: images.product.Bnexgen, alt: 'b_nexgen' },
            ],
        },
        {
            type: 'card4xV',
            title: 'Optimize your Property Management process in 4 simple steps',
            list: [
                {
                    img: images.homePage.Card1,
                    title: 'Document capture',
                    body: 'Effortlessly digitize and import all property-related documents through direct upload, email, or API integration, supporting leases, maintenance requests, invoices, and mre.',
                },
                {
                    img: images.homePage.Card2,
                    title: 'Intelligent extraction',
                    body: 'Our Gen-AI powered engine automatically extracts key information from complex documents with high accuracy, eliminating manual data entry and reducing errors.',
                },
                {
                    img: images.homePage.Card3,
                    title: 'Automated workflow',
                    body: 'Trigger automated processes based on extracted data, such as lease renewals, maintenance scheduling, or payment processing, with optional human review for complex cases',
                },
                {
                    img: images.homePage.Card4,
                    title: 'Reporting & analytics',
                    body: 'Generate comprehensive property management reports and gain actionable insights through customizable dashboards for informed decision-making.',
                },
            ],
        },
        {
            type: 'cards2xv',
            title: "Transform your Property Management with Docscience's AI-powered solution",

            list: [
                {

                    title: 'Accelerate lease processing',
                    body: 'Reduce lease processing time by up to 75% and onboard tenants faster. Our AI-driven system extracts key lease terms and tenant information in seconds, allowing your team to process multiple leases simultaneously and improve occupancy rates.',
                },
                {

                    title: 'Enhance maintenance efficiency',
                    body: 'Streamline maintenance requests and reduce response time by 60%. Automatically categorize and prioritize maintenance tickets, assign tasks to appropriate personnel, and track completion status, leading to improved tenant satisfaction and property upkeep.',
                },
                {

                    title: 'Optimize financial operations',
                    body: 'Cut accounting and invoicing time by up to 70% while improving accuracy. Automate rent collection, late fee calculations, and vendor payment processing, ensuring timely cash flow and reducing revenue leakage.',
                },
                {

                    title: 'Simplify compliance & reporting',
                    body: 'Reduce audit preparation time by 80% with comprehensive documentation. Automatically organize and store all property-related documents in compliance with regulations, creating a clear audit trail that simplifies reporting and reduces legal risks.',
                },
                {

                    title: 'Empower data-driven decision making',
                    body: 'Gain real-time insights into property performance and market trends. Our AI-powered analytics provide instant visibility into occupancy rates, rental yield, maintenance costs, and tenant satisfaction, enabling strategic portfolio management and investment decisions.',
                },
            ],
        },
        {
            type: 'integrationBlock',
            title: 'Not just data capture, automate document processing workflows end-to-end with smart Docscience integrations ',
            images: [
                images.homePage.Qb,
                images.homePage.Gmail,
                images.homePage.Zapier,
                images.homePage.Xero,
                images.homePage.Drive,
                images.homePage.DataX,
                images.homePage.Cloud,
                images.homePage.Bill,
            ],
        },
        {
            type: 'dataSecurityLogo',
            title: 'Your data is secure and under your control',
            logos: [
                { src: images.homePage.GDPR, text: 'GDPR-Compliant' },
                { src: images.homePage.SOC2TYPE2, text: 'SOC-2 Type-2 Certified' },
                { src: images.homePage.HIPAA, text: 'HIPPA Compliant' },
            ],
        },
        {
            type: 'contactForm',
        },
    ],
    
};