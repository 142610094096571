import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { UsecaseData } from '../../config/usecase-data';
import HeroBanner from '../../components/industries/HeroBanner';
import ClientList from '../../components/Product/client-list';
import Card4xV from '../../components/Landing/card4xV';
import IntegrationBlock from '../../components/Landing/IntegrationBlock';
import DataSecurityLogo from '../../components/Landing/DataSecurityLogo';
import ContactForm from '../../components/contact-form';
import Card2xv from '../../components/Usecase/cards2xv';
import ScheduleDemoModal from '../../components/ScheduleDemoModal';

const UsecasePage = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const [menu, setMenu] = useState('');
    const [data, setData] = useState([]);

    const [showDemoModel, toggleDemoModel] = React.useState(false);

    const contactUsHandler = () => {
        toggleDemoModel(true);
    };
    const closeModal = () => {
        toggleDemoModel(false);
    };

    const getSection = d => {
        switch (d.type) {
            case 'heroBanner':
                return <HeroBanner {...d} action={contactUsHandler} />;
            case 'clientBanner':
                return <ClientList list={d.client} speed="fast" direction="left" />;
            case 'card4xV':
                return <Card4xV {...d} />;
            case 'cards2xv':
                return <Card2xv {...d} />;
            case 'integrationBlock':
                return <IntegrationBlock {...d} />;
            case 'dataSecurityLogo':
                return <DataSecurityLogo {...d} />;
            case 'contactForm':
                return <ContactForm {...d} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        const sbm = searchParams.get('sbm');
        setMenu(sbm);
    }, [location.search]);

    useEffect(() => {
        if (menu && UsecaseData[menu]) {
            setData(UsecaseData[menu]);
        } else {
            setData([]);
        }
    }, [menu]);

    return (
        <>
            <div>
                {data.map((d, i) => (
                    <div key={i} style={{ backgroundColor: '#121212' }}>
                        {getSection(d)}
                    </div>
                ))}
            </div>
            {showDemoModel && <ScheduleDemoModal onClose={closeModal} />}
        </>
    );
};

export default UsecasePage;
