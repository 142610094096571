import images from '../assets/images';

export const DocumentsData = {
    documentsClaimForms: [
        {
            type: 'heroBanner',
            title: 'Accelerate Claim Processing: Automate, Extract, and Resolve Faster ',
            body: 'Buried in paperwork, deciphering handwritten notes, and chasing down missing information create delays and frustration. Docscience automates claim processing document handling, transforming the way you handle claims. ',
            chips: ['Effortless Data Capture', 'Frictionless Workflows', 'Faster Claim Resolution'],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'featureSection',
            title: 'Conquer the Chaos of Claim Processing Documents',
            text: [
                'Move Beyond Traditional Data Capture',
                'Eliminate Errors and Delays',
                'Faster Claim Decisions ',
                'Improved Fraud Detection',
            ],
            list: [
                {
                    icon: images.documents.DataGraph,
                    subTitle: 'Move Beyond Traditional Data Capture ',
                    body: 'Docscience handles both digital and paper claim documents seamlessly. Upload scanned documents, integrate with online portals, or leverage our data capture tools for effortless processing.',
                },
                {
                    icon: images.documents.ErrorDocument,
                    subTitle: 'Eliminate Errors and Delays',
                    body: 'Docscience’s Generative AI technology extracts key information with exceptional accuracy, minimizing errors and rework. Say goodbye to typos, illegible handwriting, and wasted time spent verifying data!',
                },
                {
                    icon: images.documents.Energy,
                    subTitle: 'Faster Claim Decisions ',
                    body: 'Docscience automates data extraction and streamlines the claim review process, allowing adjusters to make informed decisions faster.',
                },
                {
                    icon: images.documents.SearchDocument,
                    subTitle: 'Improved Fraud Detection ',
                    body: "Docscience's AI technology can analyze claim documents for suspicious patterns, helping you identify potential fraud attempts and protect your business. ",
                },
            ],
        },
        {
            type: 'exploreMore',
            title: 'Capture all crucial information from claim documents with 100% accuracy',
            subTitle: '',
            tabsName: ['Policy Information', 'Loss Details', 'Supporting Documentation', 'Witness Statements'],
            HomePageExplore: [
                {
                    tag: 'Policy Information',
                    industries: [
                        {
                            heading: 'Policy Information',
                            icon: images.homePage.Insurance,
                            description:
                                'Essential details about the insurance contract, including policy number and type ',
                            list: [
                                'Policyholder Name',
                                'Policyholder Address',
                                'Coverage Limits ',
                                'Deductible Amount',
                                'Claim Reporting Date',
                            ],
                        },
                    ],
                },
                {
                    tag: 'Loss Details',
                    industries: [
                        {
                            heading: 'Loss Details',
                            icon: images.homePage.Insurance,
                            description:
                                'Comprehensive description of the incident, including date, time, and location. ',
                            list: [
                                'Loss Type (Fire, Theft, Accident, etc.)',
                                'Loss Cause (if known)',
                                'Loss Location (Specific address)',
                                'Loss Time',
                            ],
                        },
                    ],
                },
                {
                    tag: 'Supporting Documentation',
                    industries: [
                        {
                            heading: 'Supporting Documentation',
                            icon: images.homePage.Insurance,
                            description:
                                'Evidence to support the claim, such as police reports, medical records, and repair estimates ',
                            list: ['Claim Form Completion Date', 'Date of Police Report', 'Reporting Officer’s Name'],
                        },
                    ],
                },
                {
                    tag: 'Witness Statements',
                    industries: [
                        {
                            heading: 'Witness Statements',
                            icon: images.homePage.Insurance,
                            description: 'Accounts of the incident from individuals who observed it. ',
                            list: [
                                'Witness Name',
                                'Witness Contact Information',
                                'Witness Relationship to Insured (if any)',
                                'Witness Account of Incident',
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Why Choose Docscience for Claim Processing Documents?',
            list: [
                {
                    icon: images.documents.Setting,
                    title: 'Effortless Automation ',
                    body: 'Automate the entire claim document processing workflow, freeing up your adjusters for more strategic tasks.',
                },
                {
                    icon: images.documents.GraphLine,
                    title: 'Seamless Integration',
                    body: 'Docscience integrates seamlessly with your existing claims management systems. ',
                },
                {
                    icon: images.documents.ImproveVisiblity,
                    title: 'Improved Visibility and Control ',
                    body: 'Gain real-time insights into claim status and identify missing information quickly. ',
                },
                {
                    icon: images.documents.ArrowDocument,
                    title: 'Faster Claim Resolution ',
                    body: 'Streamlined workflows and automated data extraction lead to faster claim resolutions for policyholders.',
                },
                {
                    icon: images.documents.DocumentError,
                    title: 'Enhanced Fraud Detection',
                    body: "Docscience's AI technology helps identify potential fraud attempts, protecting your business. ",
                },
            ],
        },
        {
            type: 'contactForm',
            title: 'Transform your healthcare practice with Docscience. Contact us today!',
        },
    ],

    documentsInsuranceApplication: [
        {
            type: 'heroBanner',
            title: 'Streamline Insurance Application Processing: Automate, Extract, and Simplify ',
            body: 'Manual data entry, deciphering handwritten forms, and chasing down missing information slow you down and keep your team away from higher-value tasks. Docscience automates insurance application processing, transforming the way you handle applications ',
            chips: ['Effortless Data Capture', 'Frictionless Workflows', 'Real-Time Visibility'],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'featureSection',
            title: 'Conquer the Chaos of Insurance Applications ',
            text: [
                'Move Beyond Traditional Data Capture ',
                'Eliminate Errors and Delays ',
                'Faster Underwriting Decisions',
                'Enhanced Fraud Detection  ',
            ],
            list: [
                {
                    icon: images.documents.DataGraph,
                    subTitle: 'Move Beyond Traditional Data Capture ',
                    body: 'Docscience handles both digital and paper applications seamlessly. Upload scanned documents, integrate with online portals, or leverage our data capture tools for seamless processing. ',
                },
                {
                    icon: images.documents.ErrorDocument,
                    subTitle: 'Eliminate Errors and Delays ',
                    body: 'Docscience’s Generative AI technology extracts key information with exceptional accuracy, minimizing errors and rework. Say goodbye to typos, illegible handwriting, and wasted time spent verifying data!',
                },
                {
                    icon: images.documents.Energy,
                    subTitle: 'Faster Underwriting Decisions ',
                    body: 'Docscience automates data extraction and streamlines the application review process, allowing underwriters to make informed decisions faster. ',
                },
                {
                    icon: images.documents.SearchDocument,
                    subTitle: 'Enhanced Fraud Detection ',
                    body: 'Docscience’s AI technology can analyze applications for suspicious patterns, helping you identify potential fraud attempts and protect your business.',
                },
            ],
        },
        {
            type: 'exploreMore',
            title: 'Extract all crucial information from insurance applications with 100% accuracy ',
            subTitle: '',
            tabsName: ['Applicant Details', 'Policy Information', 'Medical History'],
            HomePageExplore: [
                {
                    tag: 'Applicant Details',
                    industries: [
                        {
                            heading: 'Applicant Details',
                            icon: images.homePage.Insurance,
                            description: 'Personal information about the individual applying for insurance ',
                            list: ['Name', 'Address', 'Contact Information', 'Date of Birth', 'Social Security Number'],
                        },
                    ],
                },
                {
                    tag: 'Policy Information',
                    industries: [
                        {
                            heading: 'Policy Information',
                            icon: images.homePage.Insurance,
                            description: 'Specifics about the insurance coverage being requested ',
                            list: [
                                'Policy Type',
                                'Policy Term',
                                'Coverage Type',
                                'Coverage Amount',
                                'Deductible Amount',
                            ],
                        },
                    ],
                },
                {
                    tag: 'Medical History',
                    industries: [
                        {
                            heading: 'Medical History',
                            icon: images.homePage.Insurance,
                            description: 'Health-related details relevant to the insurance application',
                            list: [
                                'Medical Conditions',
                                'Medications',
                                'Procedures',
                                'Current Medication',
                                'Last Physical Exam Date',
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Why Choose Docscience for Insurance Application Processing? ',
            list: [
                {
                    icon: images.documents.Setting,
                    title: 'Effortless Automation ',
                    body: 'Automate the entire insurance application processing workflow, freeing up your team for more strategic tasks. ',
                },
                {
                    icon: images.documents.GraphLine,
                    title: 'Seamless Integration ',
                    body: 'Docscience integrates seamlessly with your existing insurance core systems. ',
                },
                {
                    icon: images.documents.ImproveVisiblity,
                    title: 'Improved Visibility and Control ',
                    body: 'Gain real-time insights into application status and identify incomplete information quickly. ',
                },
                {
                    icon: images.documents.ArrowDocument,
                    title: 'Faster Underwriting Decisions ',
                    body: 'Accelerate the underwriting process with automated data extraction and streamlined workflows. ',
                },
                {
                    icon: images.documents.DocumentError,
                    title: 'Enhanced Fraud Detection ',
                    body: "Docscience's AI technology helps identify potential fraud attempts, protecting your business. ",
                },
            ],
        },
        {
            type: 'contactForm',
        },
    ],

    documentsExpenseReports: [
        {
            type: 'heroBanner',
            title: 'Streamline Expense Reporting: Effortless Document Processing for Your Team ',
            body: 'From lost receipts and manual data entry for companies, government agencies, universities, and non-profit organizations, to chasing approvals and wasted time for employees, contractors, and volunteers, the traditional expense reporting process is ripe for disruption. Docscience empowers a smoother expense reporting experience for everyone. ',
            chips: ['Effortless Expense Tracking ', 'Simplified Approval Process ', 'Improved Policy Compliance '],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'featureSection',
            title: 'Conquer the Chaos of Expense Reports, Regardless of Use Case ',
            text: [
                'Move Beyond Traditional Data Capture ',
                'Eliminate Manual Data Entry ',
                'Faster Approvals and Reimbursements ',
                'Streamlined Policy Compliance ',
            ],
            list: [
                {
                    icon: images.documents.DataGraph,
                    subTitle: 'Move Beyond Traditional Data Capture  ',
                    body: 'Docscience handles both digital and paper receipts seamlessly. Capture receipts with your phone camera, upload digital files, or integrate with your existing expense management system for automated processing ',
                },
                {
                    icon: images.documents.DocumentAdd,
                    subTitle: 'Eliminate Manual Data Entry ',
                    body: 'Docscience’s Generative AI technology extracts key information (date, vendor, amount, etc.) from receipts with unmatched accuracy, saving you valuable time and frustration. , practice management software, and other healthcare IT systems. No disruptions, just a smooth transition to a more efficient workflow. ',
                },
                {
                    icon: images.documents.Energy,
                    subTitle: 'Faster Approvals and Reimbursements ',
                    body: 'Docscience automates workflows and streamlines the approval process, getting reimbursements to employees, contractors, and volunteers faster.',
                },
                {
                    icon: images.documents.PolicyDocument,
                    subTitle: 'Streamlined Policy Compliance ',
                    body: 'Docscience automatically checks expense reports against your institutional policies, minimizing errors and ensuring compliance with company spending guidelines or grant funding restrictions. ',
                },
            ],
        },
        {
            type: 'exploreMore',
            title: 'Extract all crucial information from expense reports with 100% accuracy ',
            subTitle: '',
            tabsName: ['Transaction Details', 'Payment Information', 'Itemized List'],
            HomePageExplore: [
                {
                    tag: 'Transaction Details',
                    industries: [
                        {
                            heading: 'Transaction Details',
                            icon: images.homePage.Insurance,
                            description:
                                'Basic information about the expense, including date, vendor, amount, and category',
                            list: [
                                'Expense Date',
                                'Vendor Name',
                                'Expense Amount',
                                'Expense Category',
                                'Expense Currency',
                            ],
                        },
                    ],
                },
                {
                    tag: 'Payment Information',
                    industries: [
                        {
                            heading: 'Payment Information',
                            icon: images.homePage.Insurance,
                            description: 'Details about how the expense was paid ',
                            list: [
                                'Payment Method',
                                'Payment Reference Number',
                                'Payment Date',
                                'Payee Name',
                                'Payment Currency',
                            ],
                        },
                    ],
                },
                {
                    tag: 'Itemized List',
                    industries: [
                        {
                            heading: 'Itemized List',
                            icon: images.homePage.Insurance,
                            description: 'Detailed breakdown of the expense, and additional information',
                            list: ['Item Description', 'Quantity', 'Unit Price', 'Total Item Amount', 'Tax Amount'],
                        },
                    ],
                },
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Why Choose Docscience for Expense Report Automation? ',
            list: [
                {
                    icon: images.documents.ImproveData,
                    title: 'Improved Visibility and Control ',
                    body: 'Gain real-time insights into expense trends and identify areas for cost savings. ',
                },
                {
                    icon: images.documents.RefreshCoin,
                    title: 'Faster Reimbursements ',
                    body: 'Streamlined workflows and automated approvals get reimbursements to users faster. ',
                },
                {
                    icon: images.documents.Security1,
                    title: 'Enhanced Compliance ',
                    body: 'Docscience automates policy checks, ensuring compliance with institutional spending guidelines. ',
                },
                {
                    icon: images.documents.Setting,
                    title: 'Effortless Automation ',
                    body: 'Automate the entire expense reporting workflow, freeing up valuable time for institutions and users. ',
                },
                {
                    icon: images.documents.Document1,
                    title: 'Reduced Administrative Burden ',
                    body: 'Eliminate manual data entry and free up staff for higher-value tasks. ',
                },
            ],
        },
        {
            type: 'contactForm',
            title: 'Transform your healthcare practice with Docscience. Contact us today!',
        },
    ],

    documentsPolicyDocuments: [
        {
            type: 'heroBanner',
            title: 'Conquer the Complexity of Policy Documents: Automate, Extract, and Simplify ',
            body: 'Manually extracting key information, maintaining accurate records, and ensuring compliance across various policies can overwhelm your team. Docscience automates policy document processing, resulting in 100% data capture accuracy.',
            chips: [
                'Effortless Data Extraction ',
                'Streamlined Document Management ',
                'Enhanced Compliance Management',
            ],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'featureSection',
            title: 'Simplify Policy Management: Untangle the Web of Documents ',
            text: [
                'Move Beyond Traditional Data Capture ',
                'Eliminate Manual Data Entry ',
                'Improved Policy Visibility ',
                'Streamlined Compliance Management ',
            ],
            list: [
                {
                    icon: images.documents.DataGraph,
                    subTitle: 'Move Beyond Traditional Data Capture ',
                    body: 'Docscience handles both digital and paper policies seamlessly. Upload scanned documents, integrate with online portals, or leverage our data capture tools for effortless processing. ',
                },
                {
                    icon: images.documents.Document,
                    subTitle: 'Eliminate Manual Data Entry ',
                    body: 'Docscience’s Generative AI technology extracts key information with exceptional accuracy, minimizing errors and wasted time spent on manual data entry. ',
                },
                {
                    icon: images.documents.Energy,
                    subTitle: 'Improved Policy Visibility',
                    body: 'Gain instant access to all policy documents in one central location, simplifying retrieval and analysis.',
                },
                {
                    icon: images.documents.SearchDocument,
                    subTitle: 'Streamlined Compliance Management ',
                    body: 'Docscience automates policy reviews and identifies potential coverage gaps or compliance issues, allowing you to address them proactively. ',
                },
            ],
        },
        {
            type: 'exploreMore',
            title: 'Extract Everything You Need, Every Time',
            subTitle: '',
            tabsName: ['Policy Details', 'Insured Information', 'Coverage Details', 'Premiums'],
            HomePageExplore: [
                {
                    tag: 'Policy Details',
                    industries: [
                        {
                            heading: 'Policy Details',
                            icon: images.homePage.Insurance,
                            description: 'Identifies the core information about the insurance contract. ',
                            list: [
                                'Policy Number ',
                                'Policy Effective Date',
                                'Policy Expiration Date',
                                'Policy Type',
                                'Policy Issuing Company',
                            ],
                        },
                    ],
                },
                {
                    tag: 'Insured Information',
                    industries: [
                        {
                            heading: 'Insured Information',
                            icon: images.homePage.Insurance,
                            description: 'Outlines the personal details of the policyholder',
                            list: ['Name', 'Address', 'Contact Information', 'Social Security Number', 'Occupation'],
                        },
                    ],
                },
                {
                    tag: 'Coverage Details',
                    industries: [
                        {
                            heading: 'Coverage Details',
                            icon: images.homePage.Insurance,
                            description: 'Specifies the types of protection provided by the policy',
                            list: [
                                'Coverage Types',
                                'Limits of Liability',
                                'Deductibles',
                                'Coverage Periods',
                                'Exclusions',
                            ],
                        },
                    ],
                },
                {
                    tag: 'Premiums',
                    industries: [
                        {
                            heading: 'Premiums',
                            icon: images.homePage.Insurance,
                            description: 'Summarizes the cost and payment terms for the policy',
                            list: [
                                'Premium Amount',
                                'Payment Due Date',
                                'Payment Method',
                                'Payment Frequency',
                                'Grace Period',
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Why Choose Docscience for Policy Document Processing? ',
            list: [
                {
                    icon: images.documents.Setting,
                    title: 'Effortless Automation ',
                    body: "Automate the entire policy document processing workflow, freeing up your team's valuable time. ",
                },
                {
                    icon: images.documents.GraphLine,
                    title: 'Seamless Integration ',
                    body: 'Docscience integrates seamlessly with your existing insurance core systems. ',
                },
                {
                    icon: images.documents.ImproveVisiblity,
                    title: 'Improved Policy Visibility',
                    body: 'Gain instant access to all policy documents in a central location. ',
                },
                {
                    icon: images.documents.ArrowDocument,
                    title: 'Streamlined Compliance Management ',
                    body: 'Docscience helps you stay compliant with regulations by automating policy reviews. ',
                },
                {
                    icon: images.documents.SearchDocument1,
                    title: 'Enhanced Search Capabilities ',
                    body: 'Easily search and retrieve specific policy information with powerful search tools. ',
                },
            ],
        },
        {
            type: 'contactForm',
            title: '',
        },
    ],

    documentsPurchaseOrder: [
        {
            type: 'heroBanner',
            title: 'Eliminate Purchase Order Headaches: Automate, Extract, and Simplify ',
            body: 'Managing purchase orders can be a time-consuming and error-prone process, filled with excessive data entry, chasing approvals, and potential mistakes. Docscience automates purchase order processing, transforming the way you handle these orders. ',
            chips: ['Effortless Data Capture ', 'Frictionless Workflows ', 'Real-Time Visibility '],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'featureSection',
            title: 'Turn Paper Piles into Procurement Power ',
            text: [
                'Move Beyond Traditional Data Capture ',
                'Eliminate Errors and Delays ',
                'Faster Approvals and Delivery ',
                'Effortless Reconciliation ',
            ],
            list: [
                {
                    icon: images.documents.DataGraph,
                    subTitle: 'Move Beyond Traditional Data Capture ',
                    body: 'Docscience handles both digital and paper purchase orders seamlessly. Upload files, integrate with your existing procurement software, or receive purchase orders electronically for automated processing. ',
                },
                {
                    icon: images.documents.ErrorDocument,
                    subTitle: 'Eliminate Errors and Delays ',
                    body: 'Docscience’s Generative AI technology minimizes errors in extracted data, ensuring accurate information for all purchasing processes. Say goodbye to typos and wasted time spent correcting mistakes and chasing clarifications! ',
                },
                {
                    icon: images.documents.Energy,
                    subTitle: 'Faster Approvals and Delivery ',
                    body: 'Docscience automates data extraction and streamlines approval workflows, accelerating the purchase order process and ensuring timely delivery of goods. ',
                },
                {
                    icon: images.documents.SearchDocument,
                    subTitle: 'Effortless Reconciliation  ',
                    body: 'Docscience seamlessly integrates with your accounting software, automatically matching purchase orders with invoices for effortless reconciliation. ',
                },
            ],
        },
        {
            type: 'exploreMore',
            title: 'Extract all crucial information from purchase orders with 100% accuracy',
            subTitle: '',
            tabsName: ['Vendor Information', 'Order Details', 'Itemized List', 'Payment Terms'],
            HomePageExplore: [
                {
                    tag: 'Vendor Information',
                    industries: [
                        {
                            heading: 'Vendor Information',
                            icon: images.homePage.Insurance,
                            description: 'Identifies the supplier or seller of the goods or services. ',
                            list: ['Name', 'Address', 'Phone Number', 'Email', 'Tax ID'],
                        },
                    ],
                },
                {
                    tag: 'Order Details',
                    industries: [
                        {
                            heading: 'Order Details',
                            icon: images.homePage.Insurance,
                            description: 'Outlines the core information about the purchase order itself. ',
                            list: ['Order Number', 'Date', 'Due Date', 'Delivery Address', 'Shipping Method'],
                        },
                    ],
                },
                {
                    tag: 'Itemized List',
                    industries: [
                        {
                            heading: 'Itemized List',
                            icon: images.homePage.Insurance,
                            description: 'Specifies the individual items being purchased, quantities, and prices. ',
                            list: ['Product/Service Description', 'Quantity', 'Unit Price', 'Line Total', 'Tax Amount'],
                        },
                    ],
                },
                {
                    tag: 'Payment Terms',
                    industries: [
                        {
                            heading: 'Payment Terms',
                            icon: images.homePage.Insurance,
                            description: 'Agreed on payment conditions for the order. ',
                            list: [
                                'Total Amount Due',
                                'Discount Terms',
                                'Payment Terms',
                                'Late Payment Fees',
                                'Payment Currency',
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Why Choose Docscience for Purchase Order Processing? ',
            list: [
                {
                    icon: images.documents.Setting,
                    title: 'Effortless Automation ',
                    body: 'Automate the entire purchase order processing workflow, freeing up your team for more strategic tasks. ',
                },
                {
                    icon: images.documents.GraphLine,
                    title: 'Seamless Integration ',
                    body: 'Docscience integrates seamlessly with your existing procurement and accounting software. ',
                },
                {
                    icon: images.documents.SearchDocument1,
                    title: 'Exceptional Accuracy ',
                    body: 'Our advanced technology ensures accurate data extraction, minimizing errors and rework. ',
                },
                {
                    icon: images.documents.ImproveVisiblity,
                    title: 'Improved Visibility and Control ',
                    body: 'Gain real-time insights into your purchase order process and identify areas for improvement. ',
                },
                {
                    icon: images.documents.CheckDocument1,
                    title: 'Faster Approvals and Delivery ',
                    body: 'Accelerate the purchase order process with automated data extraction and streamlined workflows, leading to faster delivery times. ',
                },
            ],
        },
        {
            type: 'contactForm',
            title: '',
        },
    ],

    documentsInvoiceProcessing: [
        {
            type: 'heroBanner',
            title: 'Effortless Invoice Processing, s, and Faster Payments ',
            body: 'Managing invoices can be a time-consuming and error-prone process. Docscience automates invoice processing, transforming the way you handle invoices and unlocking valuable benefits:',
            chips: ['Faster Processing', 'Enhance Accuracy', 'Improved Visibility'],
            buttonText: 'Schedule Demo',
            action: () => {},
        },
        {
            type: 'featureSection',
            title: 'Turn Invoice Headaches into Smooth Operations ',
            text: [
                'No more paper trail, no more manual data entry ',
                'Faster Invoice Approvals, Faster Payments',
                'Payment Reconciliation with Automated Matching ',
                'Streamline Account Management',
            ],
            list: [
                {
                    icon: images.documents.DataGraph,
                    subTitle: 'No more paper trail, no more manual data entry  ',
                    body: 'Upload PDFs, receive invoices through email, or integrate with your accounting software for a seamless workflow.',
                },
                {
                    icon: images.documents.ApproveDocument,
                    subTitle: 'Faster Invoice Approvals, Faster Payments ',
                    body: 'Docscience automates workflows and streamlines approvals, ensuring invoices get paid faster and avoiding late payment penalties.  ',
                },
                {
                    icon: images.documents.PaymentCheck,
                    subTitle: 'Payment Reconciliation with Automated Matching ',
                    body: 'Docscience seamlessly integrates with your accounting software, automatically matching invoices with payments for effortless reconciliation. ',
                },
                {
                    icon: images.documents.Security,
                    subTitle: 'Simplify Audits with a Secure Audit Trail ',
                    body: 'Docscience provides a secure audit trail of all invoices, simplifying the audit process and giving you peace of mind. ',
                },
            ],
        },
        {
            type: 'exploreMore',
            title: 'Process complex business documents end-to-end',
            subTitle: '',
            tabsName: ['Invoice Header Data', 'Invoice Line Items', 'Payment Information'],
            HomePageExplore: [
                {
                    tag: 'Invoice Header Data',
                    industries: [
                        {
                            heading: 'Invoice Header Data',
                            icon: images.homePage.Insurance,
                            description: 'Basic identifying information about the invoice ',
                            list: [
                                'Vendor Name',
                                'Invoice Number',
                                'Invoice Date',
                                'Due Date',
                                'Purchase Order Number ',
                            ],
                        },
                    ],
                },
                {
                    tag: 'Invoice Line Items',
                    industries: [
                        {
                            heading: 'Invoice Line Items',
                            icon: images.homePage.Insurance,
                            description: 'Detailed breakdown of the goods or services provided. ',
                            list: [
                                'Description of Goods or Services',
                                'Quantity',
                                'Unit Price',
                                'Discount Amount',
                                'Line Total',
                            ],
                        },
                    ],
                },
                {
                    tag: 'Payment Information',
                    industries: [
                        {
                            heading: 'Payment Information',
                            icon: images.homePage.Insurance,
                            description: 'Payment terms and details for settling the invoice.',
                            list: [
                                'Total Amount Due',
                                'Payment Terms',
                                'Discount Terms',
                                'Late Payment Fees',
                                'Payment Currency',
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: 'cardGroup3xV',
            title: 'Why Choose Docscience for Invoice Processing? ',
            list: [
                {
                    icon: images.documents.Setting,
                    title: 'Effortless Automation',
                    body: "Automate the entire invoice processing workflow, freeing up your team's time for more strategic tasks.",
                },
                {
                    icon: images.documents.GraphLine,
                    title: 'Seamless Integration ',
                    body: 'Docscience integrates seamlessly with your existing accounting software and ERP systems. ',
                },
                {
                    icon: images.documents.SearchDocument1,
                    title: 'Exceptional Accuracy ',
                    body: 'Our advanced technology ensures accurate data extraction, minimizing errors and rework. ',
                },
                {
                    icon: images.documents.ImproveVisiblity,
                    title: 'Improved Visibility and Control ',
                    body: 'Gain real-time insights into your invoice processing workflows and identify areas for improvement. ',
                },
                {
                    icon: images.documents.SecuritySearch,
                    title: 'Enhanced Security and Compliance ',
                    body: 'Docscience prioritizes data security and adheres to industry regulations. ',
                },
            ],
        },
        {
            type: 'contactForm',
            title: '',
        },
    ],
};
