import React from "react";
import Container from "../common/Container";
import './index.scss';

const SinglePara = ({ title, subtitle, body, body1, body2, body3, body4, body5, subBody }) => {
    return (
        <div className="data_wrapper">
            <Container className='data_sec'>
                <div className="section-block">
                    {title && <h3>{title}</h3>}
                    {subtitle && <h4>{subtitle}</h4>}
                    <div>
                        {body && <p>{body}</p>}
                        {body1 && <p>{body1}</p>}
                        {body2 && <p>{body2}</p>}
                        {body3 && <p>{body3}</p>}
                        {body4 && <p>{body4}</p>}
                        {body5 && <p>{body5}</p>}
                        {subBody && <span>{subBody}</span>}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SinglePara;