import images from './assets/images';
import upperTestimonialImage from './assets/upperTestimonialImage.svg';
import lowerTestimonialImage from './assets/lowerTestimonialImage.png';
import dollar from './assets/dollar-sign.svg';
import zap from './assets/zap.svg';
import file from './assets/file-text.svg';
import shopping from './assets/shopping-bag.svg';
// import searchDoc from './assets/searchDocument.svg';
// import dataFolder from './assets/dataFolder.svg';
// import automateDocument from './assets/automateDocumentProcess.svg';
// import simplifiedDoc from './assets/simplifiedDocument.svg';
// import costEffectiveData from './assets/costEffectiveData.svg';
// import Healthcare from './assets/healthcare.svg';
// import Finance from './assets/finance.svg';
// import Insurance from './assets/insurance.svg';
// import Retail from './assets/retail.svg';

export const SITE_TEXT = {
    Pricing: 'Pricing',
    Product: 'Product',
    Login: 'Login',
    ContactUs: 'Contact us',
    ScheduleDemo: 'Schedule Demo',
    ScheduleDemoModal: 'Schedule a demo',
    BookDemo: 'Book a demo',
    mainHeader: 'Extract Data From Documents with',
    subHeader: 'Unmatched Accuracy and Speed',
    witnessMeta: 'Witness how Docscience redefines efficiency, accuracy, and industry standards.',
    futureHeader: 'The <span>Future</span> of Document Handling Across Industries',
    leapHeading: 'Take the leap into a smarter document processing era.',
    getStarted: 'Get started with Docscience',
    Name: 'Name',
    NamePlaceholder: 'Enter your name',
    InvalidName: 'Please enter your name',
    Email: 'Email',
    EmailPlaceholder: 'Enter your email',
    InvalidEmail: 'Please provide a valid email ID',
    Phone: 'Phone',
    PhonePlaceholder: 'Enter your phone number',
    InvalidPhone: 'Please enter your phone number',
    CompanyName: 'Company name',
    CompanyPlaceholder: 'Enter your company name',
    InvalidCompanyName: 'Please enter your company name',
    ProjectDetails: 'Tell us about your project',
    ProjectDetailsPlaceHolder: 'Start typing',
    Cancel: 'Cancel',
    ContantHelp: 'What can we help you with?',
    FormHeader: 'Start a conversation by filling the form below',
    contantButton: 'Start a conversation',
    noVideoSupport: 'Your browser does not support the video tag.',
    contantSuccessHead: 'We have received your details',
    contantSuccessPara: 'Thank you for your interest in Docscience! Someone from our team will reach out to you.',
    chooseDocPlaceholder: 'Choose your document type',
    pickTime: 'Pick a time',

    // new
    // header
    solutions: 'Solutions',
    tools: 'Tools',
    pricing: 'Pricing',
    contactUs: 'Contact Us',
    login: 'Login',
    scheduleDemo: 'Schedule Demo',

    // contactForm
    contactFormTitle: 'Let’s talk today ',
    contactFormSubTitle:
        'What’s your requirement - let us know. Our technical expert will schedule a call and discuss your idea in detail post sign of an NDA. ',
    contantFormButton: 'Contact us',

    // hero banner
    heroBannerHeading: 'Automate data capture from unstructured business documents with Generative AI',
    heroBannerPara:
        'Ditch the traditional way of capturing data and automate it through Docscience. Generative AI makes data extraction smooth, accurate and insightful that enables business to scale seamlessly.',
    cardGroupHeading: 'Why businesses choose Docscience ',
    integrationHeading:
        'Not just data capture, automate document processing workflows end-to-end with smart Docscience integrations ',
    dataSecurityHeading: 'Your data is secure and under your control ',
};

export const TESTIMONIALS = [
    {
        heading: "Docscience's <span>Cutting-Edge Models</span> for Data Extraction",
        para: 'Revolutionize your document handling experience with Docscience – the cutting-edge Intelligent Document Processing (IDP) platform designed to elevate your workflow to new heights. Dive into a world where efficiency meets customization, where accuracy is paramount, and where your documents are transformed into actionable insights.',
        imgSrc: upperTestimonialImage,
    },
    {
        heading: '<span>Generative AI</span> and Document Extraction',
        para: 'Embark on a journey into the future of document extraction. Uncover the transformative influence of generative AI in collaboration with Docscience. Experience a paradigm shift in accuracy, customization, and efficiency as we redefine the landscape of intelligent document processing. <span>Welcome to a future where innovation converges with intelligence.</span>',
        imgSrc: lowerTestimonialImage,
    },
];

export const FUTURE_CONTENT = [
    {
        heading: 'Finance & Accounting',
        para: 'Invoices and bank statements are the lifeblood of financial operations. Docscience brings unparalleled accuracy to financial data extraction, ensuring error-free transactions and streamlined accounting processes.',
        imgSrc: dollar,
    },
    {
        heading: 'Utilities and Energy',
        para: 'Utility bills are complex, filled with critical information. Docscience simplifies the extraction of data from utility bills, empowering energy companies to manage costs effectively and optimize resource allocation.',
        imgSrc: zap,
    },
    {
        heading: 'Insurance',
        para: 'Simplify insurance complexities with Docscience. Effortlessly extract vital policy details, enhancing claims processing and policy management through advanced IDP capabilities for swift information retrieval.',
        imgSrc: file,
    },
    {
        heading: 'Retail and Supply Chain',
        para: 'Invoices and receipts drive retail operations. Docscience optimizes inventory management by swiftly extracting data from these documents. Elevate your supply chain efficiency and keep your business moving forward.',
        imgSrc: shopping,
    },
];

export const BOTTOM_META = [
    {
        heading: 'Unparalleled Accuracy',
        para: 'Docscience boasts an impressive accuracy rate of over 95% across various document types, including invoices, bank statements, utility bills, and insurance documents. Say goodbye to errors and hello to precision.',
    },
    {
        heading: 'Industry-Leading Processing Speed',
    },
    {
        heading: 'Tailor made for you',
    },
    {
        heading: 'Seamless Integrations',
    },
];

export const SLIDER_SETTING = {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
    arrows: false,
    useTransform: false,
    adaptiveHeight: false,
};

export const HomePageExplore = [
    {
        tag: 'Insurance',
        industries: [
            {
                heading: 'Insurance',
                icon: images.homePage.Insurance,
                description:
                    'Efficient underwriting and claim-processing  by extracting data from applications, policies, and claims ',
                list: [
                    'Insurance Applications',
                    'Policy Documents',
                    'Claims Forms',
                    'Medical Records',
                    'Correspondence',
                    'Loss Reports',
                ],
            },
        ],
    },
    {
        tag: 'Finance & Accounting',
        industries: [
            {
                heading: 'Finance & Accounting',
                icon: images.homePage.Finance,
                description:
                    'Automate invoice processing, reporting, and ensure accuracy with intelligent data extraction',
                list: ['Invoices', 'Receipts', 'Purchase Orders', 'Bank Statements', 'Expense Reports', 'Tax Forms'],
            },
        ],
    },
    {
        tag: 'Healthcare',
        industries: [
            {
                heading: 'Healthcare',
                icon: images.homePage.Healthcare,
                description:
                    'Improve efficiency and patient care with automatic  extraction of data from records for faster treatment and delivery ',
                list: [
                    'Patient Records',
                    'Discharge Summaries',
                    'Insurance Claims',
                    'Lab Results',
                    'Appointment Scheduling Documents',
                ],
            },
        ],
    },
    {
        tag: 'Retail & supply chain',
        industries: [
            {
                heading: 'Retail & supply chain',
                icon: images.homePage.Retail,
                description:
                    'Optimize your supply chain by streamlining data processing from purchase orders to invoices and receipts ',
                list: [
                    'Purchase Orders',
                    'Bills of Lading',
                    'Inventory Lists',
                    'Packing Slips',
                    'Invoices',
                    'Receipts',
                    'Customs Documents',
                ],
            },
        ],
    },
];
