const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@[a-zA-Z0-9]+(?:[.-][a-zA-Z0-9]+)*\.[a-zA-Z0-9]{2,}$/; /* eslint-disable-line */

export const removeUnderscore = text => text.replace(/_/g, ' ');

export const validateEmail = (email = '') => {
    if (email.toLowerCase().match(emailRegex)) {
        return true;
    } else {
        return false;
    }
};
