import React from 'react';
import Container from '../common/Container';

const Cards = ({ icon, title, subTitle }) => {
    return (
        <div className="card_container" style={{ border: 0 }}>
            <div className="card_item" style={{ paddingBottom: '12px' }}>
                <div className="card_itemV">
                    <img src={icon} alt="" width={32} height={32} />
                    <h3 className="card_title" style={{ margin: 0 }}>
                        {title}
                    </h3>
                </div>
                <h4 className="card_text" style={{ color: '#F9FAFB' }}>
                    {subTitle}
                </h4>
            </div>
        </div>
    );
};

const SmallCardGroup3x = ({ title, list }) => {
    return (
        <div className="card_group_wrapper" style={{ paddingTop: 0 }}>
            <Container className="card_group_container">
                {title && <h3 className="card_group_heading">{title}</h3>}
                <div className="card_group card_group3X">
                    {list?.map((_, i) => {
                        return (
                            <div key={i} className="card_group_item3X">
                                <Cards icon={_.icon} title={_.title} subTitle={_.subTitle} />
                            </div>
                        );
                    })}
                </div>
            </Container>
        </div>
    );
};

export default SmallCardGroup3x;
