import React from 'react';
import './style.scss';

const IndustriesCard = ({ cardData, currentCard, setCurrentCard }) => {
    return (
        <div className="industries_card_section">
            <div
                className={`industries_cards ${
                    currentCard === cardData?.heading ? 'industries_card_container' : 'industries_card'
                }`}
                onClick={() => setCurrentCard(cardData?.heading)}>
                {/* //left */}
                <div className="industries_card_contentL">
                    <div className={`card_heading ${currentCard === cardData?.heading}`}>
                        <span><img src={cardData?.icon} alt='' width={40} height={40}/>{' '}</span>
                        {cardData?.heading}
                    </div>
                    <div className="card_description">{cardData?.description}</div>
                </div>
                {/* right */}
                <div className="industries_card_contentR">
                    {cardData?.list?.map((__, i) => {
                        return (
                            <div className="industries_chip_card" key={i}>
                                {__}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default IndustriesCard;
