import React from 'react';
import Container from '../common/Container';
import './Card4xV.scss';

const Cards = ({ icon, title, body, index }) => {
    return (
        <div className="card_container">
            <div className="card_item">
                <div className="card_button">
                    <button className="circle_button">{index + 1}</button>
                </div>
                <div className="card_image4x">
                    <img src={icon} alt={title} height={165} width={253} />
                </div>
                <div className="card_content">
                    <h3 className="card_title4xV">{title}</h3>
                    <h4 className="card_text4xV">{body}</h4>
                </div>
            </div>
        </div>
    );
};

const Card4xV = ({ title, list }) => {
    return (
        <div className="card4xV">
            <Container className="card_group4xV_container">
                {title && <div className="cardGroupTitle">{title}</div>}
                <div className="card_groups card4XV">
                    {list?.map((cardItem, index) => (
                        <div key={index} className="card_group_item4xV">
                            <Cards icon={cardItem.img} title={cardItem.title} body={cardItem.body} index={index} />
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default Card4xV;
