import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { SITE_TEXT } from '../../constants';
import { validateEmail } from '../../functions';
import ContactImage from '../../assets/homePage/contactImage.svg';
import { useNavigate } from 'react-router-dom';
import Container from '../common/Container';
import './style.scss';

const ContactForm = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = React.useState({
        Name: '',
        Phone: '',
        Email: '',
        Company: '',
        About: '',
    });

    const [errors, updateErrors] = React.useState({
        Name: '',
        Phone: '',
        Company: '',
        Email: '',
    });

    const [touched, setTouched] = React.useState({
        Name: false,
        Phone: false,
        Email: false,
        Company: false,
        About: false,
    });

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleBlur = e => {
        const { name, value } = e.target;
        setTouched(prevTouched => ({
            ...prevTouched,
            [name]: true,
        }));

        validateField(name, value);
    };

    const validateField = (fieldName, value) => {
        let isError = !value;

        if (fieldName === 'Email') {
            isError = !validateEmail(value);
        }

        updateErrors(prevErrors => ({
            ...prevErrors,
            [fieldName]: isError ? `${fieldName} is required` : '',
        }));
    };

    const validateAllFields = () => {
        const newErrors = {};
        const fields = ['Name', 'Phone', 'Email', 'Company'];

        fields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field} is required`;
            } else if (field === 'Email' && !validateEmail(formData[field])) {
                newErrors[field] = 'Invalid Email';
            }
        });

        updateErrors(newErrors);
        setTouched({
            Name: true,
            Phone: true,
            Email: true,
            Company: true,
        });

        return newErrors;
    };

    const onButtonClick = e => {
        e.preventDefault();
        const newErrors = validateAllFields();

        if (Object.values(newErrors).some(error => error)) {
            return;
        }

        const { Name: firstname, Email: email, Phone: mobilephone, Company, About } = formData;

        if (firstname && email && mobilephone && Company && About) {
            navigate('/contactUs', { state: { formData, isSubmitted: true } });
        }
    };

    return (
        <div className="contact_form_section">
            <Container className="contact_form_container">
                <div className="contact_form_contentL">
                    <h3 className="contact_form_title">{SITE_TEXT.contactFormTitle}</h3>
                    <h5 className="contact_form_subTitle">{SITE_TEXT.contactFormSubTitle}</h5>
                    <div className="input_halfs">
                        <div className="input_half">
                            <label>{SITE_TEXT.Name}</label>
                            <TextField
                                name="Name"
                                value={formData.Name}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                                placeholder={SITE_TEXT.NamePlaceholder}
                                error={touched.Name && errors.Name}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.Name && <div className="warning">{errors.Name}</div>}
                        </div>
                        <div className="input_half">
                            <label>{SITE_TEXT.Phone}</label>
                            <TextField
                                name="Phone"
                                type="tel"
                                value={formData.Phone}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                                placeholder={SITE_TEXT.PhonePlaceholder}
                                error={touched.Phone && errors.Phone}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.Phone && <div className="warning">{errors.Phone}</div>}
                        </div>
                        <div className="input_half">
                            <label>{SITE_TEXT.CompanyName}</label>
                            <TextField
                                name="Company"
                                value={formData.Company}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                                placeholder={SITE_TEXT.CompanyPlaceholder}
                                error={touched.Company && errors.Company}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.Company && <div className="warning">{errors.Company}</div>}
                        </div>
                        <div className="input_half">
                            <label>{SITE_TEXT.Email}</label>
                            <TextField
                                name="Email"
                                value={formData.Email}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                                placeholder={SITE_TEXT.EmailPlaceholder}
                                error={touched.Email && errors.Email}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.Email && <div className="warning">{errors.Email}</div>}
                        </div>
                        <div className="input_full">
                            <label>{SITE_TEXT.ProjectDetails}</label>
                            <TextField
                                fullWidth
                                name="About"
                                value={formData.About}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                                placeholder={SITE_TEXT.ProjectDetailsPlaceHolder}
                                error={touched.About && errors.About}
                                inputProps={{ maxLength: 100 }}
                                multiline
                                rows={3}
                            />
                        </div>
                        <Button type="submit" onClick={onButtonClick} fullWidth>
                            {SITE_TEXT.contantFormButton}
                        </Button>
                    </div>
                </div>
                <div className="contact_form_contentR">
                    <img src={ContactImage} alt="" />
                </div>
            </Container>
        </div>
    );
};

export default ContactForm;
