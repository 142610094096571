import React from 'react';
import Container from '../common/Container';
import { Button } from '@mui/material';
import './style.scss';

const HeroBanner = ({ title, body, buttonText, action = () => { }, chips }) => {
    return (
        <div className="heroBanner">
            <Container className="heroContainer">
                <h2 className="heroContainerTitle">{title}</h2>
                <p className="heroContainerBody">{body}</p>
                {chips && chips.length > 0 ? (
                    <div className="heroChips">
                        {chips.map((_, i) => (
                            <div key={i} className="heroChip">
                                {_}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div style={{ marginBottom: '0px' }} />
                )}

                <Button
                    variant="contained"
                    onClick={action}
                    style={chips && chips.length > 0 ? {} : { marginTop: '10px' }}
                >
                    {buttonText}
                </Button>
            </Container>
        </div>
    );
};

export default HeroBanner;
