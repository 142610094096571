import React, { useState } from 'react';
import Container from '../common/Container';
import FeatureCard from '../card-group/FeatureCard';
import './style.scss';

const IndustryFeatures = ({ title, list, text }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleTextClick = index => {
        setSelectedIndex(index);
    };

    return (
        <div className="feature_section">
            <Container className="features_container">
                {title && <h3 className="feature-title">{title}</h3>}
                <div className="features-content">
                    <div className="feature-contentL">
                        {text?.map((item, index) => (
                            <div
                                key={index}
                                className={`feature-text ${selectedIndex === index ? 'selected' : ''}`}
                                onClick={() => handleTextClick(index)}>
                                {item}
                            </div>
                        ))}
                    </div>
                    <div className="feature-contentR">
                        {list?.map(
                            (item, index) =>
                                selectedIndex === index && (
                                    <div key={index}>
                                        <FeatureCard imgSrc={item.icon} subTitle={item.subTitle} body={item.body} />
                                    </div>
                                )
                        )}
                    </div>
                </div>
                <div className="mobile-feature-content">
                    {list?.map((_, i) => {
                        return (
                            <div key={i}>
                                <FeatureCard imgSrc={_.icon} subTitle={_.subTitle} body={_.body} />
                            </div>
                        );
                    })}
                </div>
            </Container>
        </div>
    );
};

export default IndustryFeatures;
