import React, { useEffect } from 'react';
import Container from '../common/Container';

const Cards3x = ({ title, list }) => {
    useEffect(() => {
        const solutionNeedsTitle = document.querySelector('.card3x-title');
        if (solutionNeedsTitle && solutionNeedsTitle.textContent === 'Solution Needs') {
            solutionNeedsTitle.style.fontSize = '18px';
            solutionNeedsTitle.style.fontWeight = '400';
        }
    }, []);

    return (
        <div className="card_group_wrapper" style={{ paddingTop: 0 }}>
            <Container className="card_group_container">
                {title && <h3 className="card_group_heading">{title}</h3>}
                <div className="card_group cards3x">
                    {list.map((item, index) => (
                        <div
                            key={index}
                            className={`cards-contens ${index === 1 || index === 2 ? 'gradient-card' : ''} ${
                                index === 0 ? 'light-text-card' : ''
                            } ${index === 2 ? 'colored-text-card' : ''}`}>
                            <div className="card3x-title ">{item.title}</div>
                            <div className="cardSubItem cards-contents">
                                {item.list1?.map((subItem, i) => (
                                    <div className="card3x-subItem" key={i}>
                                        {subItem}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default Cards3x;
