import React from 'react';
import PropTypes from 'prop-types';
import Container from '../common/Container';
import IndustriesCard from '../HomePage/IndustriesCard';
import { Select, MenuItem, FormControl } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './style.scss';

const ExploreMore = ({ title, subTitle, tabsName, HomePageExplore }) => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [industries, setIndustries] = React.useState(HomePageExplore[0]?.industries || []);
    const [currentCard, setCurrentCard] = React.useState(HomePageExplore[0]?.industries[0]?.heading || '');

    const setMyCards = index => {
        if (index >= 0 && index < HomePageExplore.length) {
            setCurrentTab(index);
            const result = HomePageExplore.filter(industries => industries.tag === tabsName[index]);
            setIndustries(result[0].industries);
            setCurrentCard(result[0].industries[0].heading);
        }
    };

    const handleChange = event => {
        setMyCards(event.target.value);
    };

    return (
        <div className="explore_more_section">
            <Container className="explore_more_container">
                <div className="explore_more_content">
                    {title && <h3>{title}</h3>}
                    {subTitle && <h5>{subTitle}</h5>}
                </div>
                <div id="select-wrapper">
                    <div className="exploreMore-Card">
                        <div className="tabs_section">
                            {tabsName.map((tabName, i) => {
                                return (
                                    <div
                                        className={`tabs_container ${currentTab === i ? 'active_tab' : 'tabs'}`}
                                        key={i}
                                        onClick={() => setMyCards(i)}>
                                        {tabName}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="cards_section">
                            {industries.map((industry, i) => (
                                <IndustriesCard
                                    key={i}
                                    cardData={industry}
                                    currentCard={currentCard}
                                    setCurrentCard={setCurrentCard}
                                />
                            ))}
                        </div>
                    </div>
                    <FormControl fullWidth className="select_container">
                        <Select
                            labelId="select-tab-label"
                            id="select-tab"
                            value={currentTab}
                            onChange={handleChange}
                            variant="outlined"
                            IconComponent={ExpandMoreIcon}
                            className="custom_select">
                            {tabsName.map((tabName, index) => (
                                <MenuItem value={index} key={index}>
                                    {tabName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </Container>
        </div>
    );
};

ExploreMore.propTypes = {
    tabsName: PropTypes.arrayOf(PropTypes.string).isRequired,
    HomePageExplore: PropTypes.arrayOf(
        PropTypes.shape({
            tag: PropTypes.string.isRequired,
            industries: PropTypes.arrayOf(
                PropTypes.shape({
                    heading: PropTypes.string.isRequired,
                    icon: PropTypes.string.isRequired,
                    description: PropTypes.string.isRequired,
                    list: PropTypes.arrayOf(PropTypes.string).isRequired,
                })
            ).isRequired,
        })
    ).isRequired,
};

ExploreMore.defaultProps = {
    tabsName: [],
    HomePageExplore: [],
};

export default ExploreMore;
