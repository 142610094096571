import React from 'react';
import Container from '../common/Container';
import './index.scss';

const DotedLine = ({ title, subtitle, para, body, list, paragraph }) => {
    return (
        <div className="list_wrapper">
            <Container className="list_sec">
                <div className="section-block">
                    {title && <h3>{title}</h3>}
                    {subtitle && <h4>{subtitle}</h4>}
                    {para && <p>{para}</p>}
                    <div>
                        {body && <p>{body}</p>}
                    </div>
                    {list?.map((item, i) => {
                        return (
                            <ul key={i}>
                                <li key={i}>
                                    {item}
                                </li>
                            </ul>
                        );
                    })}
                    <div>
                        {paragraph && <p>{paragraph}</p>}
                    </div>
                </div>
            </Container>
        </div>
    );
};
export default DotedLine;