import React from 'react';
import './client-list.scss';

const ClientList = ({ list, mobileCarousel = true }) => {
    return (
        <div className="client-list-section">
            <div className="image-carousel">
                <div className="carousel-group">
                    {list.map((client, i) => (
                        <div className="image-group" key={i}>
                            <img src={client.logo} alt={client.alt} className="carousel-image" height={48} />
                        </div>
                    ))}
                </div>
                <div className="carousel-group">
                    {list.map((client, i) => (
                        <div className="image-group" key={i}>
                            <img src={client.logo} alt={client.alt} className="carousel-image" height={48} />
                        </div>
                    ))}
                </div>
            </div>
            <div className={`${mobileCarousel ? 'cardGroupMobile' : 'noCardGroupDisplay'}`}>
                <div className="container">
                    <div className="bannerMobileList">
                        {list?.map((l, i) => {
                            return (
                                <div className="imageMobileWrapper" key={i}>
                                    <img src={l.logo} alt={l.alt} height={28} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientList;
