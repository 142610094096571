import axios, { CancelToken } from 'axios';

const Authorization =
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQG5leHlvbS5jb20iLCJ1c2VybmFtZSI6ImFkbWluIiwiaWF0IjoxNzA0NjQwOTQyLCJleHAiOjE3MDcyMzI5NDJ9.JGNvICaUsiOLnTC32XV9f2BDRFvYRAsnGPP2MKEJDUM';

function getConfig() {
    let cancel;
    // Return the cancel function and add it to the header.
    // See: https://github.com/mzabriskie/axios#cancellation
    const config = {
        cancelToken: new CancelToken(c => {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization,
        },
    };

    return {
        cancel,
        config,
    };
}

export function webApiPost(url, options, token) {
    const config = getConfig(token);
    return {
        request: axios.post(url, options, config.config),
        cancel: config.cancel,
    };
}
