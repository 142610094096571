import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './style.scss';
import docLogo from '../../assets/docLogo.svg';
import { Button, Drawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { SITE_TEXT } from '../../constants';
import { LOGIN_REDIRECT_URL } from '../../env';
import images from '../../assets/images';
import Container from '../common/Container';
import OutsideAlerter from '../../utils/DetectOutsideClick';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScheduleDemoModal from '../ScheduleDemoModal';

const Header = () => {
    const [active, setActive] = useState('');
    const [showMobileMenu, toggleMobileMenu] = useState(false);
    const [showDemoModel, toggleDemoModel] = React.useState(false);
    const [openSubmenu, setOpenSubMenu] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState({});
    const navigate = useNavigate();
    const [openCategory, setOpenCategory] = useState(false);

    const NavLinkList = [
        {
            title: 'Solutions',
            list: [
                {
                    title: 'Industries',
                    childrens: [
                        { title: 'Banking', value: 'industriesBanking', icon: images.navbar.Banking },
                        { title: 'E-Commerce', value: 'industriesECommerce', icon: images.navbar.Ecommerce },
                        {
                            title: 'Finance & Accounting',
                            value: 'industriesFinanceAndAccounting',
                            icon: images.navbar.FinanceAcc,
                        },
                        { title: 'Healthcare', value: 'industriesHealthcare', icon: images.navbar.Healthcaree },
                        { title: 'Insurance', value: 'industriesInsurance', icon: images.navbar.Insurancee },
                        { title: 'Mortgage', value: 'industriesMortgage', icon: images.navbar.Mortagee },
                        { title: 'NBFC', value: 'industriesNBFC', icon: images.navbar.Nbfcc },
                        { title: 'Retail', value: 'industriesRetail', icon: images.navbar.Retaill },
                        { title: 'Supply Chain', value: 'industriesSupplyChain', icon: images.navbar.Supplychain },
                    ],
                },
                {
                    title: 'Documents',
                    childrens: [
                        { title: 'Claim Forms', value: 'documentsClaimForms', icon: images.navbar.Claim },
                        { title: 'Expense Reports', value: 'documentsExpenseReports', icon: images.navbar.Expense },
                        {
                            title: 'Insurance Application',
                            value: 'documentsInsuranceApplication',
                            icon: images.navbar.Insuranceapp,
                        },
                        { title: 'Policy Documents', value: 'documentsPolicyDocuments', icon: images.navbar.Policy },
                        { title: 'Purchase Order', value: 'documentsPurchaseOrder', icon: images.navbar.Purchase },
                        {
                            title: 'Invoice Processing',
                            value: 'documentsInvoiceProcessing',
                            icon: images.navbar.Invoice,
                        },
                    ],
                },
                {
                    title: 'Usecase',
                    childrens: [
                        { title: 'Accounts Payable', value: 'usecaseAccountsPayable', icon: images.navbar.Account },
                        {
                            title: 'Accounts Reconciliation',
                            value: 'usecaseAccountsReconciliation',
                            icon: images.navbar.Accountsrec,
                        },
                        { title: 'Bill of Lading', value: 'usecaseBillOfLading', icon: images.navbar.Billland },
                        {
                            title: 'Claim Fraud Detection',
                            value: 'usecaseClaimFraudDetection',
                            icon: images.navbar.Claim,
                        },
                        { title: 'CPG Loyalty', value: 'usecaseCPGLoyalty', icon: images.navbar.Cpg },
                        { title: 'Order Management', value: 'usecaseOrderManagement', icon: images.navbar.Order },
                        {
                            title: 'Property Management',
                            value: 'usecasePropertyManagement',
                            icon: images.navbar.Property,
                        },
                    ],
                },
            ],
        },
    ];

    const handleNavClick = nav => {
        const isNavItemActive = active === nav.title.toLowerCase();
        setOpenSubMenu(!isNavItemActive);
        setActive(isNavItemActive ? '' : nav.title.toLowerCase());
    };

    const handleMobileNavClick = nav => {
        const isNavActive = openCategory === nav.title.toLowerCase();
        setOpenCategory(isNavActive ? '' : nav.title.toLowerCase());
    };

    const handleLinkClick = () => {
        toggleMobileMenu(false);
        setOpenSubMenu(false);
        setOpenCategory(false);
    };
    const closeModal = () => {
        toggleDemoModel(false);
        toggleMobileMenu(false);
        setOpenSubMenu(false);
    };

    const handleClickMenuItem = (item, parentTitle) => {
        setSelectedMenuItem(item);
        if (parentTitle === 'industries') {
            navigate(`/industries?sbm=${item.value}`);
        } else if (parentTitle === 'documents') {
            navigate(`/documents?sbm=${item.value}`);
        } else if (parentTitle === 'usecase') {
            navigate(`/usecase?sbm=${item.value}`);
        }
        toggleMobileMenu(false);
        setOpenCategory(false);
        setOpenSubMenu(false);
    };

    return (
        <header>
            <Container>
                <div className="header_content navbar">
                    <Link to="/" style={{ cursor: 'pointer' }}>
                        <img src={docLogo} alt="Logo" />
                    </Link>
                    <div className="header_menu">
                        {NavLinkList.map((nav, i) => (
                            <div
                                key={`navlink-${i}`}
                                onClick={() => handleNavClick(nav)}
                                className={`header_menu_items ${active === nav.title.toLowerCase() ? 'active' : ''}`}>
                                {nav.title}
                                <div className="icon">
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                        ))}
                        <Link to="/product" className="header_menu_items">
                            {SITE_TEXT.Product}
                        </Link>
                        <Link to="/pricing" className="header_menu_items">
                            {SITE_TEXT.Pricing}
                        </Link>
                        <Link to="/contactUs" className="header_menu_items">
                            {SITE_TEXT.ContactUs}
                        </Link>
                    </div>
                    <div className="header_menu">
                        <a rel="noopener noreferrer" href={LOGIN_REDIRECT_URL} className="header_menu_items login_link">
                            {SITE_TEXT.Login}
                        </a>
                        <Button
                            onClick={() => toggleDemoModel(true)}
                            className="header_menu_items button"
                            variant="contained"
                            color="primary">
                            {SITE_TEXT.ScheduleDemo}
                        </Button>
                    </div>

                    <IconButton
                        className="mobileBtn"
                        onClick={() => toggleMobileMenu(true)}
                        classname="mobileBtn
                ">
                        <MenuIcon />
                    </IconButton>
                </div>
                <Drawer
                    anchor="top"
                    open={showMobileMenu}
                    onClose={() => toggleMobileMenu(false)}
                    classes={{ paper: 'mobileDrawer' }}>
                    <div className="mobileDrawer">
                        <div className="mobileDrawerContent">
                            <div className="header-content">
                                <Link to="/" style={{ cursor: 'pointer' }} onClick={() => toggleMobileMenu(false)}>
                                    <img className="mobimg" src={docLogo} alt="Logo" />
                                </Link>
                                <div className="icone" onClick={() => toggleMobileMenu(false)}>
                                    <CloseIcon />
                                </div>
                            </div>

                            <ul>
                                {NavLinkList.map((nav, i) => (
                                    <li
                                        className="list"
                                        key={`mobile-navlink-${i}`}
                                        onClick={() => handleMobileNavClick(nav)}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            {nav.title}
                                            <div className="icon">
                                                <ExpandMoreIcon />
                                            </div>
                                        </div>
                                        {openCategory === nav.title.toLowerCase() && (
                                            <div className="mobileDropdown">
                                                {nav.list.map((item, idx) => (
                                                    <div key={`mobile-dropdown-${idx}`}>
                                                        <h4>{item.title}</h4>
                                                        {item.childrens.map((child, childIdx) => (
                                                            <div
                                                                key={`child-${childIdx}`}
                                                                className="menuNavItem"
                                                                onClick={() =>
                                                                    handleClickMenuItem(child, item.title.toLowerCase())
                                                                }>
                                                                <img
                                                                    style={{ paddingRight: '8px' }}
                                                                    src={child.icon}
                                                                    alt=""
                                                                />
                                                                <span>{child.title}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </li>
                                ))}
                                <li className="list" onClick={() => { navigate('/product'); handleLinkClick(); }}>
                                    <div className="list-content">
                                        <span>{SITE_TEXT.Product}</span>
                                    </div>
                                </li>
                                <li className="list" onClick={() => { navigate('/pricing'); handleLinkClick(); }}>
                                    <div className="list-content">
                                        <span>{SITE_TEXT.Pricing}</span>
                                    </div>
                                </li>
                                <li className="list" onClick={() => { navigate('/contactUs'); handleLinkClick(); }}>
                                    <div className="list-content">
                                        <span>{SITE_TEXT.ContactUs}</span>
                                    </div>
                                </li>
                            </ul>
                            <a rel="noopener noreferrer" href={LOGIN_REDIRECT_URL} className="list">
                                {SITE_TEXT.Login}
                            </a>
                            <Button onClick={() => toggleDemoModel(true)}>{SITE_TEXT.ScheduleDemo}</Button>
                        </div>
                    </div>
                </Drawer>

                {openSubmenu && active === 'solutions' && (
                    <section className="solutions_dropdown">
                        <OutsideAlerter callBack={() => setOpenSubMenu(false)}>
                            <div className="dropdown_content">
                                <div className="dropdown_column">
                                    <h4>By Industry</h4>
                                    {NavLinkList[0].list[0].childrens.map((item, i) => (
                                        <div
                                            key={`industry-item-${i}`}
                                            className={`menuNavItem ${item.value === selectedMenuItem?.value ? 'active' : ''
                                                }`}
                                            onClick={() => handleClickMenuItem(item, 'industries')}>
                                            <img style={{ paddingRight: '8px' }} src={item.icon} alt="" />
                                            <span>{item.title}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="dropdown_column">
                                    <h4>By Document Type</h4>
                                    {NavLinkList[0].list[1].childrens.map((item, i) => (
                                        <div
                                            key={`document-item-${i}`}
                                            className={`menuNavItem ${item.value === selectedMenuItem?.value ? 'active' : ''
                                                }`}
                                            onClick={() => handleClickMenuItem(item, 'documents')}>
                                            <img style={{ paddingRight: '8px' }} src={item.icon} alt="" />
                                            <span>{item.title}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="dropdown_column">
                                    <h4>By Function</h4>
                                    {NavLinkList[0].list[2].childrens.map((item, i) => (
                                        <div
                                            key={`usecase-item-${i}`}
                                            className={`menuNavItem ${item.value === selectedMenuItem?.value ? 'active' : ''
                                                }`}
                                            onClick={() => handleClickMenuItem(item, 'usecase')}>
                                            <img style={{ paddingRight: '8px' }} src={item.icon} alt="" />
                                            <span>{item.title}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </OutsideAlerter>
                    </section>
                )}
            </Container>
            {showDemoModel && <ScheduleDemoModal onClose={closeModal} />}
        </header>
    );
};
export default Header;
