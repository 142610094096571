import images from '../assets/images';

export const ProductData = [
    {
        type: 'heroSection',
        title: 'Go-to Data Capture Solution from',
        body: 'Revolutionize data handling from unstructured documents with Docscience. leverage Large Language Models (LLMs) and Generative AI to enhance decision-making and streamline your document processing workflows. ',
        buttonText: 'Schedule Demo',
        action: () => { },
        list: [
            {
                icon: images.product.GenAIAccuracy,
                title: 'Gen AI for 100%',
                subText: 'Accuracy in classification and extraction ',
            },
            {
                icon: images.product.EditCircle,
                title: 'Handwritten',
                subText: 'document reading ',
            },
            {
                icon: images.product.IDPFramework,
                title: 'Tailored IDP Frameworks',
                subText: 'to fit industry-specific needs',
            },
            {
                icon: images.product.LanguageReading,
                title: '70+',
                subText: 'language reading',
            },
            {
                icon: images.product.ReductionProcessing,
                title: '85% Reduction',
                subText: 'in document processing time ',
            },
        ],
    },
    {
        type: 'clientBanner',
        client: [
            {
                logo: images.product.Health,
                alt: 'inventHealth',
            },
            {
                logo: images.product.NexgenTech,
                alt: 'nexgen_technologies',
            },
            {
                logo: images.product.NexgenIot,
                alt: 'nexgen_iotsolutions',
            },
            { logo: images.product.Briwo, alt: 'briwo' },
            { logo: images.product.CloudClix, alt: 'cloud_clix' },
            { logo: images.product.Fifthgen, alt: 'fifthgen' },
            { logo: images.product.Adroetech, alt: 'Adroetech' },
            { logo: images.product.Bnexgen, alt: 'b_nexgen' },
        ],
    },
    {
        type: 'card4xV',
        title: 'Process complex business documents end-to-end in 3 simple steps ',
        list: [
            {
                img: images.homePage.Card1,
                title: 'Upload',
                body: 'Upload files from Google Drive, OneDrive, your computer, or any other source.',
            },
            {
                img: images.homePage.Card2,
                title: 'GenAI Data Capture',
                body: 'Automatically extract data with our smart AI, no templates needed.',
            },
            {
                img: images.homePage.Card3,
                title: 'Take Action',
                body: 'Use our tool to flag, review, and validate files, or improve your extracted data.',
            },
            {
                img: images.homePage.Card4,
                title: 'Export',
                body: 'Send data to your CRM or database, or export as XLS, CSV, XML, and more.',
            },
        ],
    },
    {
        type: 'cardGroup4X',
        title: 'Why Choose Docscience?',
        list: [
            {
                icon: images.product.SearchDocuments,
                title: 'Enhanced Accuracy',
                subTitle: '99%+ accurate data extraction ',
            },
            {
                icon: images.product.ViewDocument,
                title: 'Improved Efficiency',
                subTitle: '85% reduction in processing time ',
            },
            {
                icon: images.product.AllDocument,
                title: 'Cost Savings',
                subTitle: '70% cheaper than traditional data capture ',
            },
            {
                icon: images.product.ShareDocument,
                title: 'Better Decision-Making',
                subTitle: '30X faster than traditional workflows',
            },
        ],
    },
    {
        type: 'cards3x',
        title: 'Reasons to choose Gen-AI powered document processing',
        list: [
            {
                title: 'Solution Needs',
                list1: [
                    'Training Data',
                    'Accuracy',
                    'Deployment Time',
                    'Handling Unstructured Data',
                    'Post-Processing Efforts ',
                ],
            },
            {
                title: 'Traditional IDP',
                list1: [
                    'Extensive',
                    'Moderate',
                    'Long',
                    'Limited',
                    'High',
                ],
            },
            {
                title: 'Gen-AI Powered IDP',
                list1: [
                    'Minimal',
                    'High',
                    'Short',
                    'Advanced',
                    'Minimal',
                ],
            },
        ],
    },
    {
        type: 'smallCardGroup3x',
        title: 'Features that differentiate Docscience from others ',
        list: [
            {
                icon: images.product.Energy1,
                title: 'End-to-End Workflow',
                subTitle: 'Fully automated touchless process from ingestion to output ',
            },
            {
                icon: images.product.Energy1,
                title: 'Gen-AI Solution',
                subTitle: 'AI models for document classification and extraction',
            },
            {
                icon: images.product.Energy1,
                title: 'LLM Extraction',
                subTitle: 'Extract data using state-of-the-art language models',
            },
            {
                icon: images.product.Energy1,
                title: 'Zero Annotation',
                subTitle: 'No need for manual data labeling',
            },
            {
                icon: images.product.Energy1,
                title: 'Template free solution ',
                subTitle: 'Process any document without any prior training required',
            },
            {
                icon: images.product.Energy1,
                title: 'Document Classification',
                subTitle: 'Automatically sort documents into predefined categorie',
            },
            {
                icon: images.product.Energy1,
                title: 'Data Validation ',
                subTitle: 'Ensure the integrity and accuracy of extracted data',
            },
            {
                icon: images.product.Energy1,
                title: 'Table Extraction',
                subTitle: 'Captures data from tables within documents',
            },
            {
                icon: images.product.Energy1,
                title: 'Handwritten Document Reading',
                subTitle: 'Processes handwritten content with high accuracy',
            },
        ],
    },
    {
        type: 'exploreMore',
        title: 'Industry-agnostic intelligent data capture solutions with 100% accuracy',
        subTitle: '',
        tabsName: ['Insurance', 'Finance & Accounting', 'Healthcare', 'Retail & supply chain'],
        HomePageExplore: [
            {
                tag: 'Insurance',
                industries: [
                    {
                        heading: 'Insurance',
                        icon: images.homePage.Insurance,
                        description:
                            'Efficient underwriting and claim-processing  by extracting data from applications, policies, and claims ',
                        list: [
                            'Insurance Applications',
                            'Policy Documents',
                            'Claims Forms',
                            'Medical Records',
                            'Correspondence',
                            'Loss Reports',
                        ],
                    },
                ],
            },
            {
                tag: 'Finance & Accounting',
                industries: [
                    {
                        heading: 'Finance & Accounting',
                        icon: images.homePage.Finance,
                        description:
                            'Automate invoice processing, reporting, and ensure accuracy with intelligent data extraction',
                        list: [
                            'Invoices',
                            'Receipts',
                            'Purchase Orders',
                            'Bank Statements',
                            'Expense Reports',
                            'Tax Forms',
                        ],
                    },
                ],
            },
            {
                tag: 'Healthcare',
                industries: [
                    {
                        heading: 'Healthcare',
                        icon: images.homePage.Healthcare,
                        description:
                            'Improve efficiency and patient care with automatic  extraction of data from records for faster treatment and delivery ',
                        list: [
                            'Patient Records',
                            'Discharge Summaries',
                            'Insurance Claims',
                            'Lab Results',
                            'Appointment Scheduling Documents',
                        ],
                    },
                ],
            },
            {
                tag: 'Retail & supply chain',
                industries: [
                    {
                        heading: 'Retail & supply chain',
                        icon: images.homePage.Retail,
                        description:
                            'Optimize your supply chain by streamlining data processing from purchase orders to invoices and receipts ',
                        list: [
                            'Purchase Orders',
                            'Bills of Lading',
                            'Inventory Lists',
                            'Packing Slips',
                            'Invoices',
                            'Receipts',
                            'Customs Documents',
                        ],
                    },
                ],
            },
        ],
    },
    {
        type: 'cardGroup3X',
        title: 'Why businesses choose Docscience',
        list: [
            {
                icon: images.homePage.SearchDoc,
                title: 'Enhanced Data Capture Accuracy with Gen-AI ',
                subTitle: 'Achieve 100% data extraction accuracy with Generative AI ',
                body: 'Move away from traditional OCR to Docscience made especially to automate data capture workflows from unstructured complex documents ',
            },
            {
                icon: images.homePage.DataFolder,
                title: 'Effortless Implementation, Immediate Results ',
                subTitle: 'No time-consuming data annotation or training required. ',
                body: 'Eliminate upfront prep work. Get started with Docscience out-of-the-box data extraction solution. ',
            },
            {
                icon: images.homePage.AutomateDocument,
                title: 'Customize as per your business needs  ',
                subTitle:
                    'Scale your document processing with your business. Docscience adapts to your needs, from startups to large enterprises. ',
                body: 'Automate document processing regardless of volume. Stop worrying about document overload, Docscience can handle it all',
            },
            {
                icon: images.homePage.SimplifiedDoc,
                title: 'End-to-end Workflow, Simplified Integration ',
                subTitle:
                    'Break down data silos. Integrate Docscience with your CRM and ERP to centralize your information. ',
                body: 'Streamline workflows. Docscience seamlessly connects with your existing software, creating a smooth information flow across your organization. ',
            },
            {
                icon: images.homePage.CostEffectiveData,
                title: 'Cost Effective Smart Data Capture Solution ',
                subTitle: 'Save 50% cost from any other data capture solution in the market. GUARANTEED! ',
                body: 'Ditch expensive data capture methods. Docscience automates tasks, reducing costs and manual data entry.',
            },
        ],
    },
    {
        type: 'cardGroup4X',
        title: 'Our Values',
        list: [
            {
                icon: images.product.SearchDocuments,
                title: 'Use innovative ',
                subTitle: 'pioneering AI technologies for document processing',
            },
            {
                icon: images.product.ViewDocument,
                title: 'Efficiently',
                subTitle: 'streamline operations and improve productivity',
            },
            {
                icon: images.product.AllDocument,
                title: 'Accurate and',
                subTitle: 'high precision in data extraction and validation',
            },
            {
                icon: images.product.ShareDocument,
                title: 'Scale and',
                subTitle: 'adapt to the needs of any business size',
            },
        ],
    },
    {
        type: 'integrationBlock',
        title: 'Not just data capture, automate document processing workflows end-to-end with smart Docscience integrations ',
        images: [
            images.homePage.Qb,
            images.homePage.Gmail,
            images.homePage.Zapier,
            images.homePage.Xero,
            images.homePage.Drive,
            images.homePage.DataX,
            images.homePage.Cloud,
            images.homePage.Bill,
        ],
    },
    {
        type: 'dataSecurityLogo',
        title: 'Your data is secure and under your control',
        logos: [
            { src: images.homePage.GDPR, text: 'GDPR-Compliant' },
            { src: images.homePage.SOC2TYPE2, text: 'SOC-2 Type-2 Certified' },
            { src: images.homePage.HIPAA, text: 'HIPPA Compliant' },
        ],
    },
    {
        type: 'contactForm',
    },
];
