import React from 'react';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import { SITE_TEXT } from '../../constants';
import './index.scss';

export default function ScheduleDemoModal({ onClose }) {
    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Modal
            open
            classes={{ root: 'addModalRoot' }}
            onClose={onClose}
            aria-labelledby="confirm-user-action-modal"
            aria-describedby="confirm-user-action-modal"
        >
            <section className="modalContent">
                <h2>
                    {SITE_TEXT.ScheduleDemoModal}
                    <IconButton aria-label="close" className="closeButton" onClick={onClose}>
                        <CloseIcon className="CloseIcon" alt="" />
                    </IconButton>
                </h2>
                <section className="dataSection">
                    <div
                        className="meetings-iframe-container"
                        data-src="https://meetings.hubspot.com/contact-us6?embed=true"
                    ></div>
                </section>
            </section>
        </Modal>
    );
}

ScheduleDemoModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};
