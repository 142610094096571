import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import c from 'classnames';

import { API_BASE_PATH } from '../../env';
import { postWebApi } from '../../webapis/dashboard';
import { SITE_TEXT } from '../../constants';
import { validateEmail } from '../../functions';
import ScheduleDemoModal from '../../components/ScheduleDemoModal';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { useLocation } from 'react-router-dom';

import './index.scss';

const ContactUs = () => {
    const [showDemoModel, toggleDemoModel] = React.useState(false);
    const [isApiSuccess, showSuccess] = React.useState(false);
    const [showLoader, setLoader] = React.useState(false);
    const [body, updateParams] = React.useState({
        Name: '',
        Phone: '',
        Company: '',
        Email: '',
        About: '',
    });

    const location = useLocation();

    const [errors, updateErrors] = React.useState({
        Name: '',
        Phone: '',
        Company: '',
        Email: '',
    });

    const handleData = ({ name, value }) => {
        updateParams({
            ...body,
            [name]: value,
        });
        updateErrors({
            ...errors,
            [name]: false,
        });
    };

    async function callContactUs(dataToSend) {
        const url = `${API_BASE_PATH}/contactUs`;
        let errorsInApi = null;
        setLoader(true);
        try {
            const { data: mainData = {} } = await postWebApi(url, dataToSend).request;
            const { data = {} } = mainData;
            errorsInApi = data;
        } catch (error) {
            errorsInApi = error;
        }

        setLoader(false);
        showSuccess(true);
        return errorsInApi;
    }

    const callSaveDetails = e => {
        e.preventDefault();
        const { Name, Phone, Email, Company } = body;
        const emailError = !validateEmail(Email);
        updateErrors({
            Name: !Name,
            Phone: !Phone,
            Email: emailError,
            Company: !Company,
        });

        if (Name && Phone && !emailError && Company) {
            callContactUs(body);
        }
    };

    const closeModal = () => {
        toggleDemoModel(false);
    };

    React.useEffect(() => {
        if (location.state?.isSubmitted) {
            showSuccess(true);
        }
    }, [location.state]);

    return (
        <main>
            {showLoader && <Loader position="fixed" />}
            <Header toggleDemoModel={toggleDemoModel} />
            <section className="mainContent">
                <div>
                    <h1>{SITE_TEXT.ContantHelp}</h1>
                </div>
                {isApiSuccess ? (
                    <section className="success">
                        <div className="checkOuter">
                            <div>
                                <CheckIcon />
                            </div>
                        </div>
                        <h2>{SITE_TEXT.contantSuccessHead}</h2>
                        <p>{SITE_TEXT.contantSuccessPara}</p>
                    </section>
                ) : (
                    <form onSubmit={callSaveDetails} className="loginWrapper">
                        <h2>{SITE_TEXT.FormHeader}</h2>
                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.Name,
                            })}>
                            <label>{SITE_TEXT.Name}</label>
                            <TextField
                                fullWidth
                                name="Name"
                                value={body.Name}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.NamePlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.Name && <div className="warning">{SITE_TEXT.InvalidName}</div>}
                        </div>
                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.Phone,
                            })}>
                            <label>{SITE_TEXT.Phone}</label>
                            <TextField
                                type="number"
                                fullWidth
                                name="Phone"
                                value={body.Phone}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.PhonePlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.Phone && <div className="warning">{SITE_TEXT.InvalidPhone}</div>}
                        </div>
                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.Company,
                            })}>
                            <label>{SITE_TEXT.CompanyName}</label>
                            <TextField
                                fullWidth
                                name="Company"
                                value={body.Company}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.CompanyPlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.Company && <div className="warning">{SITE_TEXT.InvalidCompanyName}</div>}
                        </div>

                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.Email,
                            })}>
                            <label>{SITE_TEXT.Email}</label>
                            <TextField
                                fullWidth
                                name="Email"
                                value={body.Email}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.EmailPlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.Email && <div className="warning">{SITE_TEXT.InvalidEmail}</div>}
                        </div>

                        <div className="inputWrap">
                            <label>{SITE_TEXT.ProjectDetails}</label>
                            <TextField
                                fullWidth
                                name="About"
                                value={body.About}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.ProjectDetailsPlaceHolder}
                                multiline
                                rows={5}
                            />
                        </div>
                        <Button type="submit" onClick={callSaveDetails} fullWidth>
                            {SITE_TEXT.contantButton}
                        </Button>
                    </form>
                )}
            </section>

            {showDemoModel && <ScheduleDemoModal onClose={closeModal} />}
        </main>
    );
};

export default ContactUs;
