import React from 'react';
import Container from '../common/Container';

const IntegrationBlock = ({ title, images }) => {
    return (
        <div className="integration_block_feature">
            <Container className="integration_block_container">
                <div className="integration_content">{title}</div>
                <div className="integration_tools">
                    {images?.map((icon, i) => {
                        return (
                            <div className="group_icon" key={i}>
                                <img className="data_icon" src={icon} alt={`integration-icon-${i}`} />
                            </div>
                        );
                    })}
                </div>
            </Container>
        </div>
    );
};

export default IntegrationBlock;
