import React from 'react';
import './index.scss';
import Container from '../../components/common/Container';

const Herosect = ({ title, body, subtitle, imgSrc, paragraphText, paragraphText1, link1, link2 }) => {
    return (
        <div className="hero_sect_wrapper">
            <Container>
                <div className="hero_sect">
                    <div className="hero_sect_row1">
                        <div className="hero_sect_columnL">
                            {title && (
                                <h3 className="hero_sect_title">{title}</h3>
                            )}
                            <div>
                                {body && <h5 className="hero_sect_body">{body}</h5>}
                                <div className="link_style">
                                    <a href="https://www.nexyom.com/" target="blank" rel="noopener noreferrer">
                                        <span>{link1}</span>
                                    </a>
                                    {' and '}
                                    <a href="https://www.docscience.ai/" target="blank" rel="noopener noreferrer" >
                                        <span>{link2}</span>
                                    </a>
                                </div>
                                {subtitle && <h6 className="hero_sect_subtitle">{subtitle}</h6>}
                            </div>
                        </div>

                        <div className="hero_sect_columnR">
                            {imgSrc && <img src={imgSrc} alt="Section Image" className="hero_imagee" />}
                        </div>
                    </div>
                    <div className="hero_sect_row2">
                        <p className="hero_paragraphh">{paragraphText}</p>
                        <p className="hero_paragraphh">{paragraphText1}</p>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Herosect;
