import React from 'react';
import Container from '../common/Container';

const Cards = ({ icon, title, subTitle, body }) => {
    return (
        <div className="card_container">
            <div className="card_item">
                <img src={icon} alt="" />
                <h3 className="card_title">{title}</h3>
                <h4 className="card_text">{subTitle}</h4>
                <p className="card_text">{body}</p>
            </div>
        </div>
    );
};

const CardGroup3X = ({ title, list }) => {
    return (
        <div className="card_group_wrapper">
            <Container className="card_group_container">
                {title && <h3 className="card_group_heading">{title}</h3>}
                <div className="card_group card_group3X">
                    {list?.map((_, i) => {
                        return (
                            <div key={i} className="card_group_item3X">
                                <Cards icon={_.icon} title={_.title} subTitle={_.subTitle} body={_.body} />
                            </div>
                        );
                    })}
                </div>
            </Container>
        </div>
    );
};

export default CardGroup3X;
