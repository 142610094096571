import React from 'react';
import { HomeData } from '../../config/home-data';
import HeroSection from '../../components/Landing/HeroSection';
import ExploreMore from '../../components/Landing/ExploreMore';
import CardGroup3X from '../../components/Landing/CardGroup3X';
import IntegrationBlock from '../../components/Landing/IntegrationBlock';
import DataSecurityLogo from '../../components/Landing/DataSecurityLogo';
import ContactForm from '../../components/contact-form';
import Card4xV from '../../components/Landing/card4xV';
import ScheduleDemoModal from '../../components/ScheduleDemoModal';

const Home = () => {
    const [showDemoModel, toggleDemoModel] = React.useState(false);

    const contactUsHandler = () => {
        toggleDemoModel(true);
    };
    const closeModal = () => {
        toggleDemoModel(false);
    };

    const getSection = d => {
        switch (d.type) {
            case 'heroSection':
                return <HeroSection {...d} action={contactUsHandler} />;
            case 'card4xV':
                return <Card4xV {...d} />;
            case 'exploreMore':
                return <ExploreMore {...d} />;
            case 'cardGroup3X':
                return <CardGroup3X {...d} />;
            case 'integrationBlock':
                return <IntegrationBlock {...d} />;
            case 'dataSecurityLogo':
                return <DataSecurityLogo {...d} />;
            case 'contactForm':
                return <ContactForm {...d} />;
        }
    };

    return (
        <>
            <div>
                {HomeData?.map((d, i) => {
                    return <div key={i}>{getSection(d)}</div>;
                })}
            </div>
            {showDemoModel && <ScheduleDemoModal onClose={closeModal} />}
        </>
    );
};

export default Home;
