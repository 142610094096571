import React from 'react';
import { Button } from '@mui/material';
import { SITE_TEXT } from '../../constants';
import Container from '../common/Container';
import './style.scss';
import mainVideo from '../../assets/main.mp4';
import mainVideoWebm from '../../assets/main.webm';
import ThreeBackground from './ThreeBackground';

const HeroSection = ({ title, body, buttonText, action = () => {} }) => {
    return (
        <div style={{ position: 'relative' }}>
            <ThreeBackground />
            <div className="hero_section_content_bg">
                <div className="hero_section_content_bg_1"></div>
                <div className="hero_section_content_bg_2"></div>
            </div>
            <Container>
                <div className="hero_section_content">
                    {title && <h3>{title}</h3>}
                    {body && <h5>{body}</h5>}
                    <Button variant="contained" onClick={action}>
                        {buttonText}
                    </Button>
                    <video autoPlay muted loop>
                        <source src={mainVideo} type="video/mp4" />
                        <source src={mainVideoWebm} type="video/webm" />
                        {SITE_TEXT.noVideoSupport}
                    </video>
                </div>
            </Container>
        </div>
    );
};

export default HeroSection;
