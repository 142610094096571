import React from 'react';
import './style.scss';

const FeatureCard = ({ imgSrc, subTitle, body }) => {
    return (
        <div className="feature-card">
            <img className="feature-icon" src={imgSrc} alt='' />
            <p className="feature-subTitle">{subTitle}</p>
            <p className="feature-body">{body}</p>
        </div>
    );
};

export default FeatureCard;
