import React from 'react';
import './index.scss';
import Container from '../../components/common/Container';

const HeroSection = ({ title, body, subtitle, imgSrc, paragraphText, paragraphText2, paragraphText3 }) => {
    return (
        <div className="hero_sec_wrapper">
            <Container>
                <div className="hero_sec">
                    <div className="hero_sec_row1">
                        <div className="hero_sec_columnL">
                            {title && (
                                <h3 className="hero_sec_title">{title}</h3>)}
                            <div>
                                {body && <h5 className="hero_sec_body">{body}</h5>}
                                {subtitle && <h6 className="hero_sec_subtitle">{subtitle}</h6>}
                            </div>
                        </div>
                        <div className="hero_sec_columnR">
                            {imgSrc && <img src={imgSrc} alt="Section Image" className="hero_image" />}
                        </div>
                    </div>
                    <div className="hero_sec_row2">
                        <p className="hero_paragraph">{paragraphText}</p>
                        <p className="hero_paragraph">{paragraphText2}</p>
                        <p className="hero_paragraph">{paragraphText3}</p>
                    </div>
                </div>
            </Container>
        </div>
    );
};


export default HeroSection;
