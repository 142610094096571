import React from 'react';
import './index.scss';
import Container from '../common/Container';

const TermsSection = ({ section }) => {
    return (
        <div className="section-block">
            <h2>{section.heading}</h2>
            <p>{section.paragraph}</p>
            {(section.secondparagraph || section.thirdparagraph || section.fourparagraph) && (
                <>
                    {section.secondparagraph && <p>{section.secondparagraph}</p>}
                    {section.thirdparagraph && <p>{section.thirdparagraph}</p>}
                    {section.fourparagraph && <p>{section.fourparagraph}</p>}
                </>
            )}
            {section.unorderedList && (
                <ul className="unordered-list">
                    {section.unorderedList.items.map((item, index) => (
                        <li key={index} className="list-item">{item}</li>
                    ))}
                </ul>
            )}
            {section.additionalParagraph && (
                <p>{section.additionalParagraph}</p>
            )}
        </div>
    );
};

const Termsdatasec = ({ data }) => {
    return (
        <Container>
            <div className='datasect_wrapper'>
                <div className="data_sect">
                    <h1 className="terms-title">{data.title}</h1>
                    {data.orderlist &&
                        data.orderlist.map((section, index) => (
                            <TermsSection key={index} section={section} />
                        ))
                    }
                </div>
            </div>
        </Container>
    );
};

export default Termsdatasec;
