import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ContactUs from './containers/ContactUs';
import ErrorHandler from './containers/ErrorHandler';
import IndustriesPage from './pages/industries';
import Home from './pages/home';
import Header from './components/Header';
import UsecasePage from './pages/usecase';
import Footer from './components/Footer';
import DocumentPage from './pages/documents';
import PricingPage from './pages/pricing';
import ProductPage from './pages/products';
import ScrollToTop from './scrollToTop';
import './index.scss';
import PrivacyPage from './pages/privacy';
import TermsPage from './pages/terms';

const App = () => {
    const message = '404 - Page not found';

    return (
        <div>
            <BrowserRouter>
                <ScrollToTop />
                <div className="mainAppWrapper">
                    <div className="header">
                        <Header />
                    </div>
                    <Routes>
                        <Route path="/" element={<Home />} exact />
                        <Route path="/industries" element={<IndustriesPage />} exact />
                        <Route path="/documents" element={<DocumentPage />} exact />
                        <Route path="/usecase" element={<UsecasePage />} exact />
                        <Route path="/contactUs" element={<ContactUs />} exact />
                        <Route path="/pricing" element={<PricingPage />} exact />
                        <Route path="/product" element={<ProductPage />} exact />
                        <Route path="/terms-conditions" element={<TermsPage />} exact />
                        <Route path="/privacy-policy" element={<PrivacyPage />} exact />
                        <Route path="*" element={<ErrorHandler errorData={{ message }} />} />
                    </Routes>
                    <Footer />
                </div>
            </BrowserRouter>
        </div>
    );
};

export default App;
