import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IndustriesData } from '../../config/industries-data';
import HeroBanner from '../../components/industries/HeroBanner';
import FeaturesSection from '../../components/industries/IndustryFeatures';
import TextCarousel from '../../components/industries/TextCarousel';
import CardGroup3xV from '../../components/industries/CardGroup3xV';
import ContactForm from '../../components/contact-form';
import ScheduleDemoModal from '../../components/ScheduleDemoModal';

const IndustriesPage = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const [menu, setMenu] = useState('');
    const [data, setData] = useState([]);
    const [showDemoModel, toggleDemoModel] = React.useState(false);

    const contactUsHandler = () => {
        toggleDemoModel(true);
    };
    const closeModal = () => {
        toggleDemoModel(false);
    };

    const getSection = d => {
        switch (d.type) {
            case 'heroBanner':
                return <HeroBanner {...d} action={contactUsHandler} />;
            case 'featureSection':
                return <FeaturesSection {...d} />;
            case 'textCarousel':
                return <TextCarousel {...d} />;
            case 'cardGroup3xV':
                return <CardGroup3xV {...d} />;
            case 'contactForm':
                return <ContactForm {...d} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        const sbm = searchParams.get('sbm');
        setMenu(sbm);
    }, [location.search]);

    useEffect(() => {
        if (menu && IndustriesData[menu]) {
            setData(IndustriesData[menu]);
        } else {
            setData([]);
        }
    }, [menu]);

    return (
        <>
            <div>
                {data.map((d, i) => (
                    <div key={i} style={{ backgroundColor: '#121212' }}>
                        {getSection(d)}
                    </div>
                ))}
            </div>
            {showDemoModel && <ScheduleDemoModal onClose={closeModal} />}
        </>
    );
};

export default IndustriesPage;
