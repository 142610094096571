import React from 'react';
import './index.scss';
import Container from '../common/Container';

const TabularSection = ({ title, body, column1, column2, column3, column4, tableData }) => {
    return (
        <div className="tabular_sec_wrapper">
            <Container className="tabular-sec-container">
                <div className="tabular_sec">
                    {title && <h3 className="tabular_sec_title">{title}</h3>}
                    {body && <p>{body}</p>}
                    <table className="tabular_sec_table desktop-table">
                        <thead>
                            <tr>
                                {column1 && <th className="header-col1">{column1}</th>}
                                {column2 && <th className="header-col2">{column2}</th>}
                                {column3 && <th className="header-col1">{column3}</th>}
                                {column4 && <th className="header-col2">{column4}</th>}

                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, index) => (
                                <tr key={index}>
                                    {row.list1 && <td className="main">{row.list1}</td>}
                                    {row.list2 && <td>{row.list2}</td>}
                                    {row.list3 && <td>{row.list3}</td>}
                                    {row.list4 && <td>{row.list4}</td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="mobile-view">
                        {tableData.map((row, index) => (
                            <div key={index} className="mobile-row">
                                {row.list1 && <h4 className="mobile-title">{row.list1}</h4>}
                                {row.list2 && <p>{row.list2}</p>}
                                {row.list3 && <p>{row.list3}</p>}
                                {row.list4 && <p>{row.list4}</p>}
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default TabularSection;

