import images from '../assets/images';
export const PrivacyData = [
    {
        type: 'heroSection',
        title: 'Privacy Policy',
        body: 'Our code of conduct and your pledge to be an upstanding \nmember of the product ',
        subtitle: 'Effective Date/ Last Updated Sep19th, 2024',
        imgSrc: images.policy.Policyimg,
        paragraphText: 'Nexyom respects your privacy and is committed to protecting your Personal Information. This “Privacy Policy” describes how Nexyom Consultancy Services Private Limited, and our subsidiary (Nexyom Inc) and affiliates (collectively, “Nexyom”, “we”, “us”, or “our”) collect and use your Personal Information and the privacy rights and choices you have with respect to your information collected in the www.Nexyom.com website, and any other website that we own or control and which posts this Privacy Policy (a “Site”). “Personal Information” means information that uniquely identifies, relates to, describes, or is reasonably capable of being associated with or linked to you. Nexyom may provide you with additional or supplemental privacy notices at the time we collect your data, which will govern how we may process the information provided at that time',
        paragraphText2: 'The Site and the services we provide are primarily designed for individuals acting in their business capacity, and prospective clients and their representatives.',
        paragraphText3: 'This Privacy Policy does not apply to Nexyom’s processing of current or past employee or contractor data or your offline dealings with Nexyom. We provide important information for individuals located in California, the USA or India or Across Globe.'
    },
    {
        type: 'tabularSection',
        title: 'Personal Information We Collect',
        column1: 'Category of Personal Information collected',
        column2: 'What this means',
        tableData: [
            {
                list1: 'Identity Data',
                list2: 'First name, last name.',
            },
            {
                list1: 'Contact Data',
                list2: 'Your home address, work address, email address, and telephone number.',
            },
            {
                list1: 'Marketing and Communications Data',
                list2: 'Your preferences in receiving marketing from us and our third parties and your communication preferences.',
            },
            {
                list1: 'Usage Data',
                list2: 'Inferred or assumed information relating to how you use the Site and interact with us, including any information that you submit to us through the Site. This is most often collated and grouped into “segments” (e.g., there may be a segment for men, living in London and aged under 25, who like sports).',
            },
            {
                list1: 'Technical Data',
                list2: 'Computer or mobile device operating system type and version number, manufacturer and model, device identifier, browser type, screen resolution, IP address, the website you visited before browsing to our Site, general location information such as city, state or geographic area; and information about your use of and actions on our Site, such as pages you viewed, how long you spent on a page, navigation paths between pages, information about your activity on a page, access times, and length of access. This information is collected using cookies and similar technologies. Please refer to the Cookies and Similar Technologies section for more details.',
            },
            {
                list1: 'Candidate Data',
                list2: 'Information that you may submit in a job application, such as professional credentials, educational and professional history, and other information of the type included on a resume or curriculum vitae',
            },
            {
                list1: 'Other Data',
                list2: 'Other Personal Information you submit to us through the Site or our pages on social networks, chat functionality such as information you may provide when interacting with us on Facebook, LinkedIn, Twitter, YouTube and Instragram.',
            },
        ]
    },
    {
        type: 'datasection',
        title: "Sensitive Personal Information",
        body: "We ask that you not send us, and you not disclose, any sensitive personal information (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or trade union membership) on the Site, or otherwise to us.",

    },
    {
        type: 'datasection',
        title: "Aggregated Data",
        body: "We also collect, use and share “Aggregated Data” such as statistical or demographic data for any purpose. Aggregated Data may be derived from your Personal Data, but once in aggregated form it will not constitute Personal Data for the purposes of the Privacy Policy ” as this data does not directly or indirectly reveal your identity.",

    },
    {
        type: 'datasection',
        title: "How We Collect Your Personal Information",
        subtitle: "We May Collect Your Personal Information Directly From You",
        subBody: "If you request information from us."
    },
    {
        type: 'datasection',
        title: " ",
        subtitle: "We May Also Collect Personal Information From Third Parties",
        subBody: "we may work with business partners, subcontractors, advertising networks, analytics providers, and search information providers, who may provide us with Personal Information about you."
    },
    {
        type: 'datasection',
        subtitle: "Through Online Tracking Technologies",
        subBody: "We and our service providers may use cookies, device identifiers, and similar technologies such as pixels, web beacons, and local storage to collect usage and browser information about how you use the Site. We process the information collected through such technologies, which may include or be combined with Personal Information, to help operate certain features of the Site, to enhance your experience through personalization, and to help us better understand the features of the Site that you and other users are most interested in."
    },
    {
        type: 'listsection',
        title: "Cookies and Similar Technologies",
        para: `Cookies are small data files stored on your computer or mobile device by a website. Our Site may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them) to provide you with a more personal and interactive experience on our Site.`,
        body: "We use two broad categories of cookies:",
        list: [
            'first party cookies, served directly by us to your computer or mobile device, which we use  to recognize your computer or mobile device when it revisits our Site',
            'third party cookies, which are served by service providers or business partners on our Site, and can be used by such service providers or business partners to recognize your computer or mobile device when it visits other websites.',
        ]
    },
    {

        type: 'tabularSection',
        title: 'Cookies we use ',
        body: `Our Site uses the following types of cookies for the purposes set out below:`,
        column1: 'Type of Cookie',
        column2: 'Purpose',
        tableData: [
            {
                list1: 'Essential Cookies',
                list2: 'These cookies are essential to provide you with services available through our Site and to enable you to use some of its features. Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide you with those services.',
            },
            {
                list1: 'Functionality Cookies',
                list2: 'These cookies allow our Site to remember choices you make when you use our Site. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-select your preferences every time you visit our Site.',
            },
            {
                list1: 'Analytics and Performance Cookies',
                list2: `These cookies collect information about traffic to our Site and about how individuals use our Site. The information gathered may include the types of information described above in the section titled “Information automatically collected”. We use this information to help operate our Site more efficiently, to gather broad demographic information, monitor the level of activity on our Site, and improve the Site.
                We use Google Analytics, Marketo, Bizible, and VWO for this purpose. Google Analytics, Marketo, Bizible, and VWO use their own cookies. You can find out more information about Google Analytics cookies here and about how Google protects your data here. You can prevent the use of Google Analytics relating to your use of our Site by downloading and installing the browser plugin available here. You can find out more about Marketo’s privacy practices. You can find out more about Bizible’s privacy practices here. You can find out more about VWO’s privacy practices here`,
            },
            {
                list1: 'Social Media Cookies',
                list2: 'These cookies are used when you share information using a social media sharing button or “like” (or similar) button on our Site or you link your account or engage with our content on or through a social networking website such as Facebook, LinkedIn, or Twitter. The social network will record that you have done this. Social networks use their own cookies',
            },
            {
                list1: 'Targeting and Advertising Cookies',
                list2: `These cookies track your browsing habits to enable us and third-party advertising networks to deliver ads that may be of interest to you. These cookies use information about your browsing history to group you with other users who have similar interests or browsing behavior. Based on the cookies that the third-party advertising network sets on our Site and other sites, advertisers can display advertisements that may be relevant to your interests on our Site and while you are on third party websites.
                You can choose to disable cookies, as described below, or to opt out of the use of your browsing behaviour for purposes of targeted advertising. For opt out instructions, please review the “Targeted online advertising” portion of the “Your Choices” section of this Privacy Policy.`,
            },
        ]
    },
    {
        type: 'datasection',
        title: "Disabling cookies",
        body: `When you first visit the Site, you will be presented with the option to disable nonessential cookies by clicking on the “Update My Cookie Settings” link. You can typically remove or reject cookies via your browser settings. In order to do this, follow the instructions provided by your browser (usually located within the “settings,” “help” “tools” or “edit” menus). Many browsers are set to accept cookies until you change your settings.
        For further information about cookies, including how to see what cookies have been set on your computer or mobile device and how to manage and delete them, visit www.allaboutcookies.org. If you do not accept our cookies, you may experience some inconvenience in your use of our Site.`,
        body1: `For further information about cookies, including how to see what cookies have been set on your computer or mobile device and how to manage and delete them, visit www.allaboutcookies.org.
        If you do not accept our cookies, you may experience some inconvenience in your use of our Site.`,
    },
    {
        type: 'datasection',
        title: "Other technologies",
        body: `In addition to cookies, our Site may use other technologies, such as Flash technology to pixel tags to collect information automatically.`,
    },
    {
        type: 'datasection',
        title: "Flash Technology",
        body: `We may use Flash cookies (which are also known as Flash Local Shared Object (“LSOs”)) on our Site to collect and store information about your use of our Site. Unlike other cookies, Flash cookies cannot be removed or rejected via your browser settings. If you do not want Flash cookies stored on your computer or mobile device, you can adjust the settings of your Flash player to block Flash LSO storage using the tools contained in the Website Storage Settings Panel at this website. You can also control Flash LSOs by going to the Global Storage Settings Panel at this website and following the instructions. Please note that setting the Flash Player to restrict or limit acceptance of Flash LSOs may reduce or impede the functionality of some Flash applications, including, potentially, Flash applications used in connection with our Site.`,
    },
    {
        type: 'datasection',
        title: "PixelTags",
        body: `We may also use pixel tags (which are also known as web beacons and clear GIFs) on our Site and in our HTML formatted emails to track the actions of users on our Site and interactions with our emails. Unlike cookies, which are stored on the hard drive of your computer or mobile device by a website, pixel tags are embedded invisibly on webpages or within HTML formatted emails. Pixel tags are used to demonstrate that a webpage was accessed or that certain content was viewed, typically to measure the success of our marketing campaigns or engagement with our emails and to compile statistics about usage of the Site, so that we can manage our content more effectively.`,
    },
    {
        type: 'datasection',
        title: "Do Not Track Signals",
        body: `Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We do not currently respond to do not track signals. To find out more about “Do Not Track,” please visit https://www.allaboutdnt.com.`,
    },
    {
        type: 'listsection',
        title: 'How We Use Your Personal Information',
        subtitle: "To provide our Site",
        list: [
            'operate, maintain, administer and improve the Site',
            'manage and communicate with you regarding your account with us, if you have one, including by sending you service announcements, technical notices, updates, security alerts, and support and administrative messages',
            'process and manage registrations you make through the Site, including for courses or events that we organize or host and to administer contests and other  promotions',
            'better understand your needs and interests, and personalize your experience with the Site',
            'provide support and maintenance for the Site',
            'respond to your service-related requests, questions and feedback',
        ]
    },
    {
        type: 'listsection',
        subtitle: "To manage our recruiting and process employment applications",
        list: [
            'We process Personal Information, such as information submitted to us in a job application, to facilitate our recruitment activities and process employment applications, such as by evaluating a job candidate for an employment activity, and monitoring recruitment statistics.',
        ]
    },
    {
        type: 'listsection',
        subtitle: "To communicate with you",
        list: [
            'If you request information from us (such as signing up for our newsletter), register on the Site, register for a course or event that we organize or host, or participate in our contests or promotions, we may send you Nexyom marketing and/or sales communications as permitted by law. You will have the ability to opt out of such communications.',
        ]
    },
    {
        type: 'listsection',
        subtitle: "To comply with law",
        list: [
            'We use your Personal Information as we believe necessary or appropriate to comply with applicable laws, lawful requests and legal process, such as to respond to subpoenas or requests from government authorities.',
        ]
    },
    {
        type: 'listsection',
        subtitle: "With your consent",
        list: [
            'We will request your consent to use your Personal Information where required by law. If we request your consent to use your Personal Information, you may have the right to withdraw your consent any time in the manner indicated when we requested the consent or by contacting us. If you have consented to receive marketing communications from our third party partners, you may withdraw your consent by contacting those partners directly.',
        ]
    },
    {
        type: 'listsection',
        subtitle: "To create anonymous data for analytics",
        list: [
            'We may create anonymous data from your Personal Information and other individuals whose Personal Information we collect. We make Personal Information into anonymous data by excluding information that makes the data personally identifiable to you, and use that anonymous data for our lawful business purposes.',
        ]
    },
    {
        type: 'listsection',
        subtitle: "For compliance, fraud prevention and safety",
        list: [
            'We use your Personal Information as we believe necessary or appropriate to (a) enforce the terms and conditions that govern the Site; (b) protect our rights, privacy, safety or property, and/or that of you or others; and (c) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.',
        ]
    },
    {
        type: 'datasection',
        title: 'How We Disclose your Personal Information',
    },
    {
        type: 'datasection',
        title: 'Affiliates',
        body: "We may disclose your Personal Information to our subsidiaries and corporate affiliates for purposes consistent with this Privacy Policy. ",
    },
    {
        type: 'datasection',
        title: 'Visitors to the Site',
        body: "Content you submit to the Site for public posting will be viewable by other users of the Site. ",
    },
    {
        type: 'datasection',
        title: 'Service providers',
        body: "We may employ third party companies and individuals to administer and provide services on our behalf (such as customer support, hosting, email delivery, recruiting and applicant management, and database management services). These third parties may use your information only as directed by Nexyom and in a manner consistent with this Privacy Policy, and are prohibited from using or disclosing your information for any other purpose. ",
    },
    {
        type: 'datasection',
        title: 'Professional advisors',
        body: "We may disclose your Personal Information to professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us. ",
    },
    {
        type: 'datasection',
        title: 'Compliance with Laws and Law Enforcement; Protection and Safety',
        body: "Nexyom may disclose information about you to government or law enforcement officials or private parties as required by law, and disclose and use such information as we believe necessary or appropriate to (a) comply with applicable laws and lawful requests and legal process, such as to respond to subpoenas or requests from government authorities; (b) enforce the terms and conditions that govern the Site; (c) protect our rights, privacy, safety or property, and/or that of you or others; and (d) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.",
    },
    {
        type: 'datasection',
        title: 'Business Transfers',
        body: "Nexyom may sell, transfer or otherwise share some or all of its business or assets, including your Personal Information, in connection with a business transaction (or potential business transaction) such as a merger, consolidation, acquisition, reorganization or sale of assets or in the event of bankruptcy, in which case we will make reasonable efforts to require the recipient to honor this Privacy Policy.",
    },
    {
        type: 'listsection',
        title: 'Your Choices',
        para: `In this section, we describe the rights and choices that may be available to users. Users who are located within the USA or India or Across Globe”) may read additional information about their rights below. California residents have additional Personal Information rights and choices. Please see Notice for California Residents Section below for more information.`,
        body: "Depending on where you live, you may have the following rights with respect to your Personal Information:",
        list: [
            'Access: You may have the right to know what Personal Information we have collected about you and to access such data.',
            'Data Portability: You may have the right to receive a copy of your information in a portable and readily usable format.',
            'Deletion: You may have the right to delete your Personal Information that we have obtained, subject to certain exceptions.',
            'Correction: You may have the right to correct inaccuracies in your Personal Information.',
            'Opt out of certain processing: You may have the right to opt out of the processing of your Personal Information for purposes of (i) targeted advertising, (ii), the sale of your Personal Information; or (iii) profiling in furtherance of decisions that produce legal or similarly significant effect concerning you.',
        ],
        paragraph: `To exercise any of these rights available to you, please submit your request through via the options described in the “Exercising Your Privacy Rights” section below. If you are not satisfied with the resolution of your request and you are afforded a right to appeal such decision, you will be notified of our appeal process in our response to your request.
        To opt out of marketing emails, you can use the unsubscribe link found in the email communication you receive from us. You may also use the “Contact Us” option within the relevant service to opt out and elect not to receive further emails from us. Please note that, even if you unsubscribe from certain correspondence, we may still need to contact you with important transactional or administrative information, as permitted by law.
        Additionally, if you withdraw your consent or object to processing, or if you choose not to provide certain Personal Information, we may be unable to provide some or all of our services to you.`,
    },
    {
        type: 'datasection',
        title: 'Security',
        body: "The security of your Personal Information is important to us. We take a number of organizational, technical and physical measures designed to protect the Personal Information we collect, both during transmission and once we receive it. However, no security safeguards are 100% secure and we cannot guarantee the security of your information.",
    },
    {
        type: 'datasection',
        title: "Children",
        body: "Our Site is not directed to children under 16. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us. We will delete such information from our files as soon as reasonably practicable.",
    },
    {
        type: 'datasection',
        title: "International Transfer",
        body: "Our Site is not directed to children under 16. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us. We will delete such information from our files as soon as reasonably practicable.",
    },
    {
        type: 'datasection',
        title: "Other Site and Services",
        body: "Our Site is not directed to children under 16. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us. We will delete such information from our files as soon as reasonably practicable.",
    },
    {
        type: 'datasection',
        title: "How Long Do We Keep Your Personal Information",
        body: "Our Site is not directed to children under 16. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us. We will delete such information from our files as soon as reasonably practicable.",
    },
    {
        type: 'datasection',
        title: "Changes to this Privacy Policy",
        body: "Our Site is not directed to children under 16. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us. We will delete such information from our files as soon as reasonably practicable.",
    },
    {
        type: 'datasection',
        title: "Additional Information for Users in the USA or India or Across Globe",
        body: "",
    },
    {
        type: 'datasection',
        title: "Personal information",
        body: "References to “personal information” in this Privacy Policy are equivalent to “Personal Data” governed by the General Data Protection Regulation 2016/679 and the UK General Data Protection Regulation (collectively, “GDPR”). Essentially, it boils down to: information about an individual, from which that individual is either directly identified or can be identified. It does not include ‘anonymous data’ (i.e., information where the identity of individual has been permanently removed). However, it does include ‘indirect identifiers’ or ‘pseudonymous data’ (i.e., information which alone doesn’t identify an individual but, when combined with certain additional and reasonably accessible information, could be attributed to a particular person).",
    },
    {
        type: 'datasection',
        title: "Controller",
        body: "Nexyom Consultancy Services Private Limited & Nexyom Inc. is the controller of your personal information for purposes of GDPR. See the Contact Us section above for contact details.",
    },
    {
        type: 'listsection',
        title: "Processing principles",
        body: "When Nexyom processes personal information of individuals in the USA or India or Across Globe, Nexyom adheres to certain data processing principles. Under these principles, personal data is:",
        list: [
            `Processed lawfully, fairly and in a transparent manner in relation to the data subject`,
            `Collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes`,
            `Adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed`,
            `Accurate and, where necessary, kept up to date`,
            `Kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed in a manner that ensures appropriate security of the personal data, including protection against unauthorized or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organizational measures`,
            `Nexyom implements appropriate technical and organizational measures that are designed to give effect to these data protection principles.`,
        ]
    },
    {
        type: 'listsection',
        title: "Legal bases for processing",
        para: "We will only use your Personal Data for the purposes for which we collected it as listed below, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your Personal Data for an unrelated purpose, we will update this Privacy Policy and we will explain the legal basis which allows us to do so.",
        body: "In respect of each of the purposes for which we use your Personal Data, the GDPR requires us to ensure that we have a “legal basis” for that use. Most commonly, we will rely on one of the following legal bases:",
        list: [
            `Where we need to perform a contract we are about to enter into or have entered into with you (“Contractual Necessity”).`,
            `Where it is necessary for our legitimate interests and your interests and fundamental rights do not override those interests (“Legitimate Interests”). More detail about the specific legitimate interests pursued in respect of each Purpose we use your Personal Data for is set out in the table below.`,
            `Where we need to comply with a legal or regulatory obligation (“Compliance with Law”).`,
            `Where we have your specific consent to carry out the processing for the Purpose in question (“Consent”). Generally, we do not rely on your Consent as a legal basis for using your Personal Data other than in the context of direct marketing communications.`,
        ]
    },
    {

        type: 'tabularSection',
        title: ' ',
        body: "We have set out below, in a table format, the legal bases we rely on in respect of the relevant Purposes for which we use your Personal Data.",
        column1: 'Purpose',
        column2: 'Categories of Personal Data involved',
        column3: 'Why do we do this',
        column4: 'Our legal basis for this use of data',
        tableData: [
            {
                list1: 'Account Creation',
                list2: `Identity Data; Contact Data`,
                list3: 'To process and manage registrations you make through the Site, including for courses or events that we organize or host and to administer contests and other promotions',
                list4: `Contractual Necessity`,
            },
            {
                list1: 'Operate the Site',
                list2: `Identity Data; Contact Data`,
                list3: 'To operate, maintain, administer and improve the Site to better understand your needs and interests, and personalize your experience with the Site to respond to your service-related requests, questions and feedback',
                list4: `Contractual Necessity Legitimate Interests. We have a legitimate interest in providing you a good service, with updates on our Sites and related offers where you have purchased or shown interest in similar services from us.`,
            },
            {
                list1: 'Compliance, Fraud Prevention and safety',
                list2: `Identity Data; Contact Data`,
                list3: 'To keep our website, our services and associated systems operational and secure. To enforce the terms and conditions that govern the Site',
                list4: `Legitimate Interests. We have a legitimate interest in ensuring the ongoing security and proper operation of our services, website and associated IT services and networks.`,
            },
            {
                list1: 'Troubleshooting',
                list2: `Identity Data; Contact Data; Technical Data`,
                list3: 'To track issues that might be occurring on our systems, provide support and maintenance for the Site. To manage and communicate with you regarding your account with us, if you have one, including by sending you service announcements, technical notices, updates, security alerts, and support and administrative messages',
                list4: `Legitimate Interests. It is in our legitimate interests that we are able to monitor and ensure the proper operation of our Sites and associated systems and services.`,
            },
            {
                list1: 'Marketing',
                list2: `Identity Data; Contact Data; Marketing and Communications Data`,
                list3: 'To form a view on what we think you may want or need, or what may be of interest to you. You will have the ability to opt out of such communications. If you have consented to receive marketing communications from our third-party partners, you may withdraw your consent by contacting those partners directly',
                list4: `Consent`,
            },
            {
                list1: 'Job application',
                list2: `Identity Data; Contact Data Candidate Data`,
                list3: 'To manage our recruiting and process employment applications. To facilitate our recruitment activities and process employment applications, such as by evaluating a job candidate for an employment activity, and monitoring recruitment statistics',
                list4: `Legitimate Interests. We have a legitimate interest in considering your job application.`,
            },
        ]
    },
    {
        type: 'datasection',
        title: "",
        body: "If you have questions about the legal basis of how we process your personal information, contact us at privacy@Nexyom.com.",
    },
    {
        type: 'datasection',
        title: "Use for new purposes",
        body: "We may use your Personal Data for reasons not described in this Privacy Policy where permitted by law and the reason is compatible with the purpose for which we collected it. If we need to use your personal information for an unrelated purpose, we will notify you and explain the applicable legal basis.",
    },
    {
        type: 'datasection',
        title: "Retention",
        body: "We will only retain your Personal Data for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements.",
        body1: "To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.",
        body2: "When we no longer require the personal information we have otherwise collected about you, we will dissociate such information from the information attached to your content. In some circumstances we may anonymize your personal information (so that it can no longer be associated with you), in which case we may use this information indefinitely without further notice to you."
    },
    {
        type: 'listsection',
        title: "Your rights relating to your Personal Data",
        body: "Users from the USA or India  or Across Globe have certain rights regarding their personal information. If you are located within the USA or India or Across Globe you may ask us to take the following actions in relation to your personal information that we hold:",
        list: [
            `Opt-out. Stop sending you direct marketing communications. You may continue to receive service-related and other non-marketing emails.`,
            `Withdraw consent. This right only exists where we are relying on consent to process your Personal Data. If you withdraw your consent, we may not be able to provide you with access to the certain specific functionalities of our Site. We will advise you if this is the case at the time you withdraw your consent.`,
            `Access. Provide you with information about our processing of your personal information and give you access to your personal information.`,
            `Correct. Update or correct inaccuracies in your personal information.`,
            `Delete. Delete your personal information.`,
            `Transfer. Transfer a machine-readable copy of your personal information to you or a third party of your choice.`,
            `Restrict. Restrict the processing of your personal information.`,
            `Object. Object to our reliance on our legitimate interests as the basis of our processing of your personal information.`,
        ],
        paragraph: "If you want to exercise any of the rights described above, please see the “Exercising Your Privacy Rights” section below. Applicable law may require or permit us to decline your request. If we decline your request, we will tell you why, subject to legal restrictions. If you would like to submit a complaint about our use of your personal information or response to your requests regarding your personal information, you may contact us or submit a complaint to the data protection regulator in your jurisdiction. You can find your data protection regulator here."
    },
    {
        type: 'listsection',
        title: "Cross-Border Data Transfer",
        para: "As you can see from the above, we share your Personal Data both with our affiliated companies and certain external third parties who are based outside the USA, or India. Any processing of your Personal Data by these parties will involve an export of your Personal Data outside the USA or India or Across Globe.",
        body: "Whenever we transfer your personal information out of the USA or India or Across Globe to countries not deemed by a regulatory authority to provide an adequate level of personal information protection, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:",
        list: [
            `Pursuant to the recipient’s compliance with Standard Contractual Clauses, or Binding Corporate Rules`,
            `Pursuant to the consent of the individual to whom the personal information pertains`,
            `As otherwise permitted by applicable requirements.`,
        ],
        paragraph: "Please contact us if you want further information on the specific mechanism used by us when transferring your personal information out of the USA or India or Across Globe."
    },
    {
        type: 'datasection',
        title: "Notice for California Residents",
        body: "This Section applies to our collection and use of Personal Information if you are a resident of California, as required by the California Consumer Privacy Act of 2018 and its implementing regulations, as amended by the California Privacy Rights Act (“CCPA”), where “Personal Information” has the definition set forth in the CCPA. This Section describes (1) the categories of Personal Information collected and disclosed by us, (2) your privacy rights under the CCPA, and (3) how to exercise your rights.",
        body1: "Nexyom makes the following disclosures regarding the Personal Information Nexyom has collected within the 12-month period preceding the Effective Date of this Privacy Policy:",
    },
    {
        type: 'datasection',
        title: "Sources of Personal Information",
        body: "We collect Personal Information from the categories of sources detailed above, (i) directly from you; (ii) data collected automatically through tracking technologies; and (iii) third parties.",
    },
    {
        type: 'datasection',
        title: "Use of Personal Information",
        body: "We collect Personal Information for the business and commercial purposes detailed in the “How We Use Your Personal Information” section above.",
    },
    {
        type: 'datasection',
        title: "Disclosure of Personal Information",
        body: "The categories of third parties to which we disclose Personal Information for a business or commercial purpose or to whom we sell or share Personal Information are summarized in the chart below.",
    },
    {

        type: 'tabularSection',
        title: ' ',
        column1: 'Categories of CA Personal Information We Collect',
        column2: 'Categories of Third Parties to Which We Disclose Personal Information for a Business or Commercial Purpose',
        column3: 'Categories of Third Parties to Which We May Sell or Share Personal Information',
        tableData: [
            {
                list1: 'Identifiers',
                list2: `Service Providers, Advertising Networks`,
                list3: `None`,
            },
            {
                list1: 'Personal Information, as defined in the California customer records law',
                list2: `Analytics Providers, Subcontractors`,
                list3: `Analytics Providers`,
            },
            {
                list1: 'Internet or other similar network activity',
                list2: `Analytics Providers, Subcontractors`,
                list3: `None`,
            },
            {
                list1: 'Geolocation data',
                list2: `Analytics Providers, Subcontractors`,
                list3: `Analytics Providers`,
            },
            {
                list1: 'Inferences drawn from other Personal Information',
                list2: `Analytics Providers, Subcontractors`,
                list3: `None`,
            },
            {
                list1: 'Professional or employment-related information',
                list2: `Service Providers`,
                list3: `None`,
            },
            {
                list1: 'Sensitive Personal Information',
                list2: `None`,
                list3: `None`,
            },
        ]
    },
    {
        type: 'datasection',
        title: "Retention",
        body: `We will retain each category of your Personal Information for as long as necessary to fulfill the purposes described in the “How We Use Your Personal Information” section above, unless otherwise required by applicable laws. The criteria we will use to determine how long we will retain your Personal Information includes whether: we need your Personal Information to provide you with products or services you have requested; we continue to have a relationship with you; you have requested information, products, or services from us; we have a legal right or obligation to continue to retain your Personal Information; we have an obligation to a third party that involves your Personal Information; our retention or recordkeeping policies and obligations dictate that we retain your Personal Information; we have an interest in providing you with information about our products or services; and we have another business purpose for retaining your Personal Information.`,
    },
    {

        type: 'tabularSection',
        title: ' ',
        body: "If you are a resident of California, you may have the following rights, subject to certain exceptions:",
        column1: 'Privacy Right',
        column2: 'Description',
        tableData: [
            {
                list1: 'Notice',
                list2: `You may have the right have the right to be notified of what categories of Personal Information will be collected at or before the point of collection and the purposes for which they will be used and shared`,
            },
            {
                list1: 'Access',
                list2: `You may have the right to know what Personal Information we have collected about you, including (a) the categories of Personal Information; (b) the categories of sources from which the Personal Information was collected; (c) the business or commercial purpose for collecting, selling, or sharing Personal Information; (d) the categories of third parties to whom the business discloses Personal Information; and (e) the specific pieces of Personal Information we have collected about you.`,
            },
            {
                list1: 'Data Portability',
                list2: `The right to receive a copy of your information in a portable and readily usable format.`,
            },
            {
                list1: 'Deletion',
                list2: `The right to have your Personal Information deleted. Note, however, that we may not always be able to comply with your request to delete for specific legal reasons which will be notified to you, if applicable, at the time of your request.`,
            },
            {
                list1: 'Correction',
                list2: `You have the right to request that we correct any incorrect personal information that we collect or retain about you, subject to certain exceptions.`,
            },
            {
                list1: 'Automated Decision Making',
                list2: `You may have the right to request information about the logic involved in automated decision-making and a description of the likely outcome of processes, and the right to opt out. We do not currently engage in any automated decision making practices.`,
            },
            {
                list1: 'To Opt Out of Sales or Sharing of Personal Information',
                list2: `To the extent we sell or share Personal Information, you may have the right to opt out of the selling or sharing of Personal Information; you can do so here.`,
            },
            {
                list1: 'Limit Use of Sensitive Personal Information',
                list2: `We do not use or disclose Sensitive Personal Information other than to provide our service. However, if we used or disclosed Sensitive Personal Information for other purposes, you would have the right to limit certain uses of Sensitive Personal Information.`,
            },
        ]
    },
    {
        type: 'linesection',
        title: '',
        body: `Only you, or an authorized agent may make a request related to your Personal Information. You may also make a request on behalf of your minor child.
        You may only make a request for access or data portability twice within a 12-month period. The request must:`,
        list: [
            'Provide sufficient information that allows us to reasonably verify you are the person about whom we collected Personal Information or an authorized representative.',
            'Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.',
        ]
    },
    {
        type: 'datasection',
        title: "Exercising Your Privacy Rights",
        body: "To exercise any of the privacy rights afforded to you under applicable data protection law, please submit a request to us by emailing us at privacy@nexyom.com.",
    },
    {
        type: 'datasection',
        title: "Verification",
        body: "We must verify your identity before fulfilling your requests. If we cannot initially verify your identity, we may request additional information to complete the verification process. We will only use Personal Information provided in a request to verify the requestor’s identity. If you are an authorized agent making a request on behalf of a consumer, we will also need to verify your identity, which may require proof of your written authorization or evidence of a power of attorney.",
        body1: `We endeavor to respond to requests within the time period required by applicable law. If we require more time, we will inform you of the reason and extension period in writing. If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option.`,
        body2: `We do not charge a fee to process or respond to your request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.`,
        body3: `We cannot respond to your request or provide you with Personal Information if we cannot verify your identity and confirm the Personal Information relates to you. Making a verifiable consumer request does not require you to create an account with us.`,
        body4: `We may deny certain requests, or only fulfill some in part, as permitted or required by law. For example, if you request to delete Personal Information, we may retain Personal Information that we need to retain for legal purposes.`,
        body5: `This Privacy Policy is available to consumers with disabilities. To access this Privacy Policy in an alternative downloadable format, please click here.`,
    },
    {
        type: 'datasection',
        title: "California Shine the Light Law",
        body: "Under California Civil Code Section 1798.83, individual customers who reside in California and who have an existing business relationship with us may request information about our disclosure of certain categories of Personal Information to third parties for the third parties’ direct marketing purposes, if any. To make such a request, please contact us using the information in the Contact Us section below. Please be aware that not all information sharing is covered by these California privacy rights requirements and only information on covered sharing will be included in our response. This request may be made no more than once per calendar year.",
    },
    {
        type: 'datasection',
        title: "Contact Us",
        body: "If you have any questions or concerns at all about our Privacy Policy, please feel free to email us at contact.us@nexyom.com or write to us at:",
        body1: "Nexyom Consultancy Services Private Limited",
        body2: "Attn: Legal – Privacy",
        body3: "H-86, Sector 63,",
        body5: "Noida, Uttar Pradesh 201307",

    },
    {
        type: 'datasection',
        title: " ",
        body: "In addition, individuals who are located in the US, UK, Canada or European Economic Area may contact us by mail at contact.us@nexyom.com",
        body1: "Nexyom, Inc.",
        body2: "Attn: Legal – Privacy",
        body3: "14873, Auburn Summit",
        body4: "Dr Folsom,",
        body5: "California,- 95630",

    },
];